/**
 * Capitalizes a string so that "hello" and "HELLO" become "Hello".
 */
export function capitalize(str: string) {
    if (!str) {
        return "";
    }
    const arr = [...str];

    return arr[0].toUpperCase() + arr.slice(1).join("").toLowerCase();
}

/**
 * Truncates the given string to the given length.
 */
export function truncate(s: string, toLength: number): string {
    if (!s || typeof s !== "string") {
        return "";
    }

    if (s.length <= toLength) {
        return s;
    }

    return `${s.substring(0, toLength)}...`;
}

/**
 * Splits new lines in a string into an array, with one line per entry. Handles Unix new lines (\n) and Windows new lines (\r\n).
 */
export function splitLines(s: string): string[] {
    if (!s || s.length === 0) return [];

    // Source: https://stackoverflow.com/a/12111820
    const regex = /[\r\n]/;

    return s
        .split(regex)
        .filter((line) => line !== "")
        .map((line) => line.trim());
}
