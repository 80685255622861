import * as React from "react";
import { MdImage as ImageIcon } from "react-icons/md";
import { CONSTANTS } from "client/constants";

export interface Props extends React.Props<any> {
    onOpenFilePicker: () => void;
}

/**
 * Shows an empty image editor, where cliking inside the form will open the filepicker.
 */
export function EmptyImageForm(this: unknown, { onOpenFilePicker }: Props) {
    const openFilePicker = (e: React.MouseEvent<any>) => {
        e.preventDefault();
        onOpenFilePicker();
    };

    return (
        <form id="uploadForm" className="dz dz-clickable uploadForm pointer" onClick={openFilePicker}>
            <div>
                <ImageIcon color={CONSTANTS.BLUE_GREY} size={100} />
                <p className="color strong">{"Click to upload your first image."}</p>
            </div>
        </form>
    );
}
