import { FrameSize, MessengerPackageType } from "kmmp/frontend";

/**
 * Returns the correct package type based on the size of the frame.
 */
export function getPackageFromFrameSize(size: FrameSize): MessengerPackageType {
    switch (size) {
        case "16x20":
            return "211";

        case "11x14":
            return "212";

        case "8x10":
            return "213";

        default:
            const s: never = size;
            throw new Error(`Unhandled frame size ${s}`);
    }
}

/**
 * Returns the correct frame size based on the package type.
 */
export function getFrameSizeFromPackage(pkg: MessengerPackageType): FrameSize {
    switch (pkg) {
        case "211":
            return "16x20";

        case "212":
            return "11x14";

        case "213":
            return "8x10";

        default:
            const s: never = pkg;
            throw new Error(`Unhandled package type ${s}`);
    }
}

export function isMessengerPackage(pkg: string): pkg is MessengerPackageType {
    switch (pkg as MessengerPackageType) {
        case "211":
        case "212":
        case "213":
            return true;

        default:
            return false;
    }
}
