import * as React from "react";
import { WhitelabelSettings } from "kmmp";
import { SignatureJerseyPackageType, SignatureJerseyFrame } from "kmmp/frontend";
import { getSignatureJerseyImage } from "../../shared/images";

interface IProps extends React.Props<any> {
    packageType: SignatureJerseyPackageType;
    frame: SignatureJerseyFrame;
    whitelabelSettings: WhitelabelSettings;
    displayAsPackage: boolean;
}

export function SignatureJerseyPreview(props: IProps): JSX.Element {
    const src = getSignatureJerseyImage(props.packageType, props.frame);

    if (props.displayAsPackage) {
        //const src = Images.findFancyFrame(companyName, subjectType, frame, background, orientation);
        return <img className="img-responsive" style={{ objectFit: "contain" }} src={src} alt={"Package preview"} />;
    }

    return <img className="img-responsive" style={{ objectFit: "contain" }} src={src} alt={"Package preview"} />;
}
