import * as React from "react";
import { NewsAnnouncement } from "kmmp";

interface Props extends React.Props<any> {
    details: NewsAnnouncement;
}

/**
 * Maps a @see NewsAnnouncement object to HTML.
 */
export function NewsMessage({ details }: Props): JSX.Element {
    const lines = typeof details.message === "string" ? [details.message] : details.message;

    return (
        <div key={details._id}>
            <h1 style={{ color: "red" }}>{`${details.title}`}</h1>
            {lines.map((l, i) => (
                <p key={i}>{l}</p>
            ))}
        </div>
    );
}
