import * as React from "react";
import { useAuth } from "../../hooks";

interface Props {
    children: React.ReactNode | (() => React.ReactNode);
}

export function RequireAdmin(props: Props): JSX.Element {
    const auth = useAuth();

    React.useEffect(() => {
        if (!auth.isAdmin) {
            auth.redirectToLogin();
        }
    }, [auth.isAdmin]);

    if (!auth.isAdmin) {
        // User isn't authenticated, the effect will redirect them
        return <React.Fragment />;
    }

    return <React.Fragment>{typeof props.children === "function" ? props.children() : props.children}</React.Fragment>;
}
