import * as React from "react";
import Paths, { getPathRegex } from "shared/paths";
import { Cart, Dev } from "../../stores";
import { useLocation } from "wouter";
import { Option } from "@nozzlegear/railway";

type Props = React.PropsWithChildren<{ [key: string]: any }>;

function tryMustRedirect(location: string): Option<string> {
    if (Dev.allow_cart === false) {
        // /cart path is disabled
        // User must start checkout from /cart/name
        // User must have at least one line item
        // CartStore must have Cart.startedCheckout eval to true
        if (getPathRegex(Paths.cart.index).test(location)) {
            return Option.ofSome(Paths.cart.name);
        } else if (Cart.items.length === 0) {
            return Option.ofSome(Paths.quick.index);
        } else if (!Cart.startedCheckout && !getPathRegex(Paths.cart.name).test(location)) {
            return Option.ofSome(Paths.cart.name);
        }

        return Option.ofNone();
    }

    // User must start checkout from /cart
    // CartStore.startedCheckout gets set when they press the startCheckout button.
    if (!Cart.startedCheckout && !getPathRegex(Paths.cart.index).test(location)) {
        return Option.ofSome(Paths.cart.index);
    }

    return Option.ofNone();
}

export function CartMain(props: Props): JSX.Element {
    const [location, setLocation] = useLocation();
    const redirection = tryMustRedirect(location);

    React.useEffect(() => {
        if (redirection.isSome()) {
            console.log("cart main is redirecting user to", redirection.get());
            setLocation(redirection.get(), { replace: true });
        } else {
            Cart.startCheckout();
        }
    }, [Cart.startedCheckout]);

    // # # #
    // TODO: Show time warning
    // # # #

    if (redirection.isSome()) {
        return <React.Fragment />;
    }

    return (
        <section className="padder">
            <section id="cart" className="pure-g center-children">
                <div className="pure-u-1-1 pure-u-lg-20-24">{props.children}</div>
            </section>
        </section>
    );
}
