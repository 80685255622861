// Due to some bundling and compilation issues in IE11, I wasn't able to use the gearworks-http package in the browser.
// Instead I had to copy it to the project itself where webpack could compile it directly for IE.
import GearworksHttpClient, { ApiError } from "./gearworks-http";
import { CONSTANTS } from "client/constants";
import { AxiosResponse } from "axios";

export { ApiError };

export class ApiErrorWithRawBody extends ApiError {
    constructor(baseError: ApiError, rawBody) {
        super(baseError.status, baseError.status_text, baseError.message);

        this.details = baseError.details;
        this.name = baseError.name;
        this.rawBody = rawBody;
        this.stack = baseError.stack;
        this.unauthorized = baseError.unauthorized;
    }

    /**
     * The response's raw body. May be a string, object, array, null or undefined.
     */
    rawBody: string | Object | Array<any> | null | undefined;
}

export class BaseClass extends GearworksHttpClient {
    constructor(private basePath: string, private authToken?: string) {
        super(basePath, {
            [CONSTANTS.AUTH_HEADER_NAME]: authToken || "",
        });
    }

    /**
     * A function that parses the error response of an Axios request and returns a new ApiError.
     * Only called when a response does not indicate success. This function can be overriden to allow
     * custom error parsing.
     * @param body The response body returned by Axios. May be a string or a JS object.
     * @param axiosResponse The Axios response object.
     */
    public parseErrorResponse(body?: string | Object, axiosResponse?: AxiosResponse): ApiErrorWithRawBody {
        // Let the super parser method parse the response, then overwrite the default 'Something went wrong' error to be more informative.
        const baseError = super.parseErrorResponse(body, axiosResponse as any);

        if (/something went wrong/i.test(baseError.message)) {
            const responseDescription = `${baseError.status} ${baseError.status_text}.`;
            let message: string;

            if (axiosResponse && axiosResponse.config) {
                message = `${axiosResponse.config.method} request to ${axiosResponse.config.url} failed`;
            } else {
                message = `The request failed`;
            }

            baseError.message = `${message} with ${baseError.status} ${baseError.status_text}`;
        }

        return new ApiErrorWithRawBody(baseError, (axiosResponse && axiosResponse.data) || {});
    }
}
