import * as Frontend from "kmmp/frontend";
import * as V2 from "kmmp/orders/v2";

type PackageType = Frontend.SignatureJerseyPackageType | V2.SignatureJerseyPackageType;

/**
 * Returns the correct package type based on the frame size.
 */
export function getPackageFromFrameSize(size: Frontend.SignatureJerseyFrameSize): PackageType {
    switch (size) {
        case "24x36":
            return "SJ1";

        default:
            const neverSize: never = size;
            console.error("Unhandled size", neverSize);
            throw new Error("Unhandled size.");
    }
}

/**
 * Returns the correct frame size based on the package type.
 */
export function getFrameSizeFromPackage(packageType: PackageType): Frontend.SignatureJerseyFrameSize {
    switch (packageType) {
        case "SJ1":
            return "24x36";

        default:
            const neverType: never = packageType;
            console.error("Unhandled package type", neverType);
            throw new Error("Unhandled package type.");
    }
}

export function isSignatureJerseyPackage(pkg: string): pkg is PackageType {
    switch (pkg as PackageType) {
        case "SJ1":
            return true;

        default:
            return false;
    }
}
