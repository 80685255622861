import * as React from "react";
import * as Frontend from "kmmp/frontend";
import Box from "../../components/box";
import Paths from "shared/paths";
import Progress from "react-svg-progress/dist";
import { CartMain as Container } from "./main";
import { useLocation } from "wouter";
import { useState, useUnauthorizedPrompt } from "client/hooks";
import { ApiError } from "client/http";
import { Auth, Cart } from "../../stores";
import { buildParcel, calculateRates, verifyAddress, showSaturdayRates } from "client/modules/shipping";
import { Link } from "wouter";
import { Sentry } from "client/modules/sentry";
import { MdArrowRight as RightArrow } from "react-icons/md";
import { Option, compute, Result } from "@nozzlegear/railway";
import { isAmbiguousShippingError } from "shared/is-ambiguous-shipping";
import { tryNormalizeUnicode, tryNormalizeUnicodeInAddress } from "shared/try-normalize-unicode";

type Props = {};

interface State {
    error: Option<string>;
    showAmbiguousCheckbox: boolean;
    useAmbiguousAddress: boolean;
    calculating: boolean;
    name: string;
    street1: string;
    street2: string;
    zip: string;
    country: string;
    state: string;
    city: string;
    ambiguous: boolean;
}

export function CartShippingPage(_: Props): JSX.Element {
    const user = Auth.session;
    const handleUnauthorized = useUnauthorizedPrompt();
    const [location, setLocation] = useLocation();
    const [state, setState] = useState<State>({
        error: Option.ofNone(),
        calculating: false,
        ambiguous: false,
        showAmbiguousCheckbox: false,
        useAmbiguousAddress: false,
        name: user?.location_name || "",
        street1: user?.address_1 || "",
        street2: user?.address_2 || "",
        zip: user?.zip || "",
        city: "",
        state: "",
        country: "",
    });

    async function handleContinue(e: React.SyntheticEvent) {
        e.preventDefault();

        if (state.calculating) {
            return;
        }

        const s = state;
        // Attempt to normalize unicode in the user's address, which may have come from data entered from SCI's system
        const { normalizedAddress } = tryNormalizeUnicodeInAddress({
            company: s.name,
            name: s.name,
            city: s.city,
            address_line_1: s.street1,
            address_line_2: s.street2,
            address_line_3: "",
            postal_code: s.zip,
            state_code: s.state,
            country_code: "US",
        });
        const ambigAddress: Frontend.ShippingAddress = {
            name: normalizedAddress.name,
            city: normalizedAddress.city ?? "",
            street1: normalizedAddress.address_line_1,
            street2: normalizedAddress.address_line_2 ?? "",
            street3: normalizedAddress.address_line_3 ?? "",
            zip: normalizedAddress.postal_code,
            country: normalizedAddress.country_code,
            state: normalizedAddress.state_code ?? "",
        };

        if (s.showAmbiguousCheckbox && s.useAmbiguousAddress) {
            if (!s.city || !s.city.trim()) {
                return setState({ error: Option.ofSome("You must enter a City.") });
            }

            if (!s.state || !s.state.trim()) {
                return setState({ error: Option.ofSome("You must enter a State.") });
            }

            // Skip validation/rate lookup and go straight to the next page
            Cart.setAmbiguousAddress(ambigAddress);
            setLocation(Paths.cart.details);

            return;
        }

        setState({
            error: Option.ofNone(),
            calculating: true,
            showAmbiguousCheckbox: false,
            useAmbiguousAddress: false,
        });

        const verifiedAddressResult = await Result.ofPromise(verifyAddress(Auth.token.value, ambigAddress));

        if (verifiedAddressResult.isError()) {
            const e: ApiError = verifiedAddressResult.getError() as ApiError;
            const sourceId = "575dcf38-24aa-4f27-85bf-9e58ae07be98";

            if (e.unauthorized && handleUnauthorized(Paths.cart.shipping)) {
                setState({
                    calculating: false,
                    error: Option.ofSome(e.message),
                });
                return;
            }

            // Check if this is a known ambiguous address error. If not, log the error.
            const isAmbiguous = isAmbiguousShippingError(e);

            if (!isAmbiguous) {
                Sentry.captureException(e, {
                    extra: {
                        sourceId: sourceId,
                    },
                });
            }

            // Always allow the user to continue past shipping errors, as the rates displayed are just an estimate and not a commitment.
            Cart.setAmbiguousAddress(ambigAddress);
            setState({
                calculating: false,
                error: Option.ofSome(e.message),
                ambiguous: true,
                showAmbiguousCheckbox: true,
                useAmbiguousAddress: false,
            });

            return;
        }

        const calculationResult = await Result.ofPromise(
            calculateRates(
                Auth.token.value,
                verifiedAddressResult.getValue(),
                [buildParcel(1, "CP15")],
                showSaturdayRates()
            )
        );

        if (calculationResult.isError()) {
            const e: ApiError = calculationResult.getError() as ApiError;
            const sourceId = "3b871b63-c9f7-4be7-84b3-e6a2cd316a82";

            if (e.unauthorized && handleUnauthorized(Paths.cart.shipping)) {
                return;
            }

            Sentry.captureException(e, {
                extra: {
                    sourceId: sourceId,
                },
            });
            setState({ error: Option.ofSome(`${e.message} (id: ${sourceId})`), calculating: false });

            return;
        }

        const verifiedAddress = verifiedAddressResult
            .map<Frontend.ShippingAddress>((verified) => ({
                city: verified.city || "",
                name: verified.name || "",
                state: verified.state || "",
                country: verified.country || "",
                street1: ambigAddress.street1,
                street2: ambigAddress.street2 || "",
                zip: ambigAddress.zip,
                street3: "",
            }))
            .getValue();
        const calculation = calculationResult.getValue();

        setState({
            calculating: false,
            ambiguous: false,
            city: verifiedAddress.city,
            name: verifiedAddress.name,
            state: verifiedAddress.state,
            country: verifiedAddress.country,
            street1: verifiedAddress.street1,
            street2: verifiedAddress.street2 || "",
            zip: verifiedAddress.zip,
        });
        Cart.setVerifiedShippingAddressAndRates(verifiedAddress, calculation);
        setLocation(Paths.cart.details);
    }

    const setCity = (e: React.ChangeEvent<HTMLInputElement>) => setState({ city: e.currentTarget.value });

    const setStateName = (e: React.ChangeEvent<HTMLInputElement>) => setState({ state: e.currentTarget.value });

    const setName = (e: React.ChangeEvent<HTMLInputElement>) => setState({ name: e.currentTarget.value });

    const setStreet1 = (e: React.ChangeEvent<HTMLInputElement>) => setState({ street1: e.currentTarget.value });

    const setStreet2 = (e: React.ChangeEvent<HTMLInputElement>) => setState({ street2: e.currentTarget.value });

    const setZip = (e: React.ChangeEvent<HTMLInputElement>) => setState({ zip: e.currentTarget.value });

    const { error, calculating, name, street1, street2, zip, city, state: stateName } = state;
    const description = (
        <p>
            {'Specify the shipping information for order below, then click "Continue" at the bottom. '}
            {Auth.companySettings.companyName === "sci" ? (
                <span>
                    <strong>{"Note: "}</strong>
                    {
                        "Ground and 2nd Day Air shipping are covered by your SCI corporate discount, but the cost of any shipping method beyond those should be "
                    }
                    <strong>{"passed on to your customer."}</strong>
                </span>
            ) : null}
        </p>
    );
    const rightArrow = <RightArrow size={18} color={`#fff`} className={`right`} />;
    let buttonText: JSX.Element;

    if (calculating) {
        buttonText = (
            <span>
                <Progress color={`#fff`} margin={`0 5px 0 0`} />
                {"Calculating Rates"}
            </span>
        );
    } else if (state.showAmbiguousCheckbox && state.useAmbiguousAddress) {
        buttonText = (
            <span>
                {"Skip Address Verification"}
                {rightArrow}
            </span>
        );
    } else if (state.showAmbiguousCheckbox) {
        buttonText = (
            <span>
                {"Recalculate Rates"}
                {rightArrow}
            </span>
        );
    } else {
        buttonText = (
            <span>
                {"Calculate Rates"}
                {rightArrow}
            </span>
        );
    }

    const link = calculating ? (
        <span />
    ) : (
        <Link to={Paths.cart.name} className="btn btn-default">
            Back
        </Link>
    );
    const footer = (
        <div className="align-children justify-space-between">
            {link}
            <button type="button" className="btn blue" onClick={(e) => handleContinue(e)}>
                {buttonText}
            </button>
        </div>
    );
    const ambiguousCheckbox: Option<JSX.Element> = compute<Option<JSX.Element>>(() => {
        if (state.showAmbiguousCheckbox) {
            return Option.ofSome(
                <label className="cursor" style={{ marginTop: "1.5rem" }}>
                    <input
                        type={`checkbox`}
                        style={{ marginRight: ".5rem" }}
                        checked={state.useAmbiguousAddress}
                        onChange={(e) => setState({ useAmbiguousAddress: !state.useAmbiguousAddress })}
                    />
                    {`I understand that my address could not be verified. Please attempt to ship to the address anyway. (This may result in a callback and an inability to ship your package in a timely manner.)`}
                </label>
            );
        }

        return Option.ofNone();
    });

    const cityAndStateControls = compute(() => {
        if (state.useAmbiguousAddress) {
            return Option.ofSome(
                <div>
                    <div className="control-group">
                        <label htmlFor="city">{"City"}</label>
                        <input
                            name="city"
                            type="text"
                            // address-level2 is the City according to the HTML5 spec
                            // https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill
                            autoComplete="shipping address-level2"
                            value={city || ""}
                            onChange={setCity}
                        />
                    </div>
                    <div className="control-group">
                        <label htmlFor="state">{"State"}</label>
                        <input
                            type="text"
                            name="state"
                            // address-level1 is the State according to the HTML5 spec
                            // https://html.spec.whatwg.org/multipage/form-control-infrastructure.html#autofill
                            autoComplete="shipping address-level1"
                            value={stateName || ""}
                            onChange={setStateName}
                        />
                    </div>
                </div>
            );
        }

        return Option.ofNone();
    });

    return (
        <Container>
            <Box
                title={`Shipping Information.`}
                description={description}
                error={error.defaultValue("")}
                footer={footer}
            >
                <form id="shippingForm" onSubmit={(e) => handleContinue(e)}>
                    <div className="control-group">
                        <label htmlFor="company">{"Ship To Name/Company"}</label>
                        <input
                            type="text"
                            name="company"
                            autoComplete="shipping organization"
                            value={name || ""}
                            onChange={setName}
                        />
                    </div>
                    <div className="control-group">
                        <label htmlFor="address-line1">{"Street Address 1"}</label>
                        <input
                            type="text"
                            name="address-line1"
                            autoComplete="shipping address-line1"
                            value={street1 || ""}
                            onChange={setStreet1}
                        />
                    </div>
                    <div className="control-group">
                        <label htmlFor="address-line2">{"Street Address 2"}</label>
                        <input
                            type="text"
                            name="address-line2"
                            autoComplete="shipping address-line2"
                            value={street2 || ""}
                            onChange={setStreet2}
                        />
                    </div>
                    <div className="control-group">
                        <label htmlFor="postal-code" style={{ display: "block" }}>
                            {"ZIP/Postal Code"}
                            {state.showAmbiguousCheckbox && !state.useAmbiguousAddress ? (
                                <small className="float-right">(City and State are not needed.)</small>
                            ) : undefined}
                        </label>
                        <input
                            type="text"
                            name="postal-code"
                            autoComplete="shipping postal-code"
                            value={zip || ""}
                            onChange={setZip}
                        />
                    </div>
                    {cityAndStateControls.defaultValue(null as any)}
                    {ambiguousCheckbox.defaultValue(null as any)}
                </form>
            </Box>
        </Container>
    );
}
