import { FrameName } from "kmmp/frontend";

/**
 * Maps a string to a @see FrameName defaulting to Mahogany.
 */
export function mapStringToFrame(input: string): FrameName {
    switch (input.toLowerCase()) {
        default:
        case "mahogany":
            return "Mahogany";

        case "black":
            return "Black";

        case "honey":
            return "Honey";

        case "vintage":
            return "Vintage";
    }
}
