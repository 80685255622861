import { WhitelabelSettings } from "kmmp";
import { getBackgrounds, getFrames } from "shared/images";

const humanBackgrounds = getBackgrounds("sci", "human");
const frames = getFrames("sci");

export const SCI: WhitelabelSettings = {
    companyName: "sci",
    siteName: "KMMP",
    subjectType: "human",
    domain: "km-mp.com",
    siteType: "corporate",
    locationIdPrefix: "sci",
    products: [
        {
            _id: "CP15",
            _rev: "v1",
            name: "CP15 Portrait Package with Cards",
            description: "Each package includes 1 framed print and 5 portrait cards",
            imageUrl: "/public/fancy-frames/package-black-asis.png",
            // New orders are $67.00 for the first package, then $62.00 for the next packages.
            price: 67,
            priceBreaks: [
                {
                    // The first package must remain 67.00
                    applyBelowMinQuantity: false,
                    // Only apply the discount when there's two or more of the product
                    minQuantity: 2,
                    // Apply the discount to each quantity
                    applyTo: "individual",
                    // $5.00 discount
                    modifier: -5.0,
                    modifierType: "absolute",
                },
            ],
            backgroundInfo: {
                hasBackgrounds: true,
                backgrounds: humanBackgrounds,
            },
            frameInfo: {
                hasFrames: true,
                frames: frames,
            },
            cardInfo: {
                hasCards: true,
                amountPerItem: 5,
            },
        },
        {
            _id: "CP1",
            _rev: "v1",
            name: "CP1 Portrait Package",
            description: "Framed prints without cards",
            imageUrl: "/public/images/ecommerce/16x20ro.png",
            price: 43.0,
            priceBreaks: [],
            backgroundInfo: {
                hasBackgrounds: true,
                backgrounds: humanBackgrounds,
            },
            frameInfo: {
                hasFrames: true,
                frames: frames,
            },
            cardInfo: {
                hasCards: false,
            },
        },
        {
            _id: "CP4",
            _rev: "v1",
            name: "CP4 Portrait Cards",
            description: "",
            imageUrl: "/public/images/ecommerce/cards-on-blue.png",
            price: 5.0,
            priceBreaks: [],
            backgroundInfo: {
                hasBackgrounds: true,
                backgrounds: humanBackgrounds,
            },
            frameInfo: {
                hasFrames: true,
                frames: frames,
            },
            cardInfo: {
                hasCards: false,
            },
        },
        {
            _id: "CP25",
            _rev: "v1",
            name: "CP25 Portrait Package with Cards",
            description: "Each package includes one 11x14 framed print and five portrait cards",
            imageUrl: "/public/fancy-frames/package-black-asis.png",
            // New orders are $64.00 for the first package, then $62.00 for the next packages.
            price: 64,
            priceBreaks: [
                {
                    // The first package must remain 67.00
                    applyBelowMinQuantity: false,
                    // Only apply the discount when there's two or more of the product
                    minQuantity: 2,
                    // Apply the discount to each quantity
                    applyTo: "individual",
                    // $5.00 discount
                    modifier: -5.0,
                    modifierType: "absolute",
                },
            ],
            backgroundInfo: {
                hasBackgrounds: true,
                backgrounds: humanBackgrounds,
            },
            frameInfo: {
                hasFrames: true,
                frames: frames,
            },
            cardInfo: {
                hasCards: true,
                amountPerItem: 5,
            },
        },
        {
            _id: "CP2",
            _rev: "v1",
            name: "CP2 Portrait Package",
            description: "Framed 11x14 prints without cards",
            imageUrl: "/public/images/ecommerce/11x14ro.png",
            price: 40.0,
            priceBreaks: [],
            backgroundInfo: {
                hasBackgrounds: true,
                backgrounds: humanBackgrounds,
            },
            frameInfo: {
                hasFrames: true,
                frames: frames,
            },
            cardInfo: {
                hasCards: false,
            },
        },
        {
            _id: "CP35",
            _rev: "v1",
            name: "CP35 Portrait Package with Cards",
            description: "Each package includes one 8x10 framed print and five portrait cards",
            imageUrl: "/public/fancy-frames/package-black-asis.png",
            // New orders are $64.00 for the first package, then $62.00 for the next packages.
            price: 64,
            priceBreaks: [
                {
                    // The first package must remain 67.00
                    applyBelowMinQuantity: false,
                    // Only apply the discount when there's two or more of the product
                    minQuantity: 2,
                    // Apply the discount to each quantity
                    applyTo: "individual",
                    // $5.00 discount
                    modifier: -5.0,
                    modifierType: "absolute",
                },
            ],
            backgroundInfo: {
                hasBackgrounds: true,
                backgrounds: humanBackgrounds,
            },
            frameInfo: {
                hasFrames: true,
                frames: frames,
            },
            cardInfo: {
                hasCards: true,
                amountPerItem: 5,
            },
        },
        {
            _id: "CP3",
            _rev: "v1",
            name: "CP3 Portrait Package",
            description: "Framed 8x10 prints without cards",
            imageUrl: "/public/images/ecommerce/8x10ro.png",
            price: 40.0,
            priceBreaks: [],
            backgroundInfo: {
                hasBackgrounds: true,
                backgrounds: humanBackgrounds,
            },
            frameInfo: {
                hasFrames: true,
                frames: frames,
            },
            cardInfo: {
                hasCards: false,
            },
        },
    ],
};
