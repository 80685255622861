import * as React from "react";
import { CONSTANTS } from "client/constants";
import { WhitelabelSettings } from "kmmp";
import { Navbar } from "client/components/nav";
import { useWhitelabel } from "client/hooks";
import { If } from "shared/components/if";

type Props = React.PropsWithChildren<{
    whitelabelSettings: WhitelabelSettings;
}>;

export function MainLayout(props: Props) {
    const env = CONSTANTS.IS_LIVE ? "PROD" : "DEV";
    const year = React.useMemo(() => {
        return new Date().getUTCFullYear();
    }, []);
    const whitelabel = useWhitelabel();

    return (
        <main id="app">
            <Navbar whitelabelSettings={props.whitelabelSettings} />
            {props.children}
            <footer id="footer">
                <div>
                    <p>
                        <If condition={whitelabel.companyName === "sci"}>
                            {`© Keith M. Merrick Company, Inc. ${year}. All rights reserved.`}
                            <br />
                        </If>
                        {`Client version ${env}.${CONSTANTS.VERSION}.${CONSTANTS.BUILD}.`}
                    </p>
                </div>
            </footer>
        </main>
    );
}
