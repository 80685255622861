import * as React from "react";
import Paths from "shared/paths";
import { Link } from "wouter";
import { Auth } from "../../stores";

export interface Props extends React.ClassAttributes<{ [key: string]: string }> {
    statusCode: string;
}

export default function Error(props: Props) {
    const description = props.statusCode === "404" ? "Not found" : "Application Error";

    return (
        <main id="error">
            <h1 className="logo">
                <Link to={Paths.home.index}>{Auth.companySettings.siteName}</Link>
            </h1>
            <div className="error">
                <h2>{"Oops!"}</h2>
                <h3>{`${description}.`}</h3>
                <Link className="back" to="/" style={{ color: "#fff", textDecoration: "underline" }}>
                    {"Click here to go back to the home page."}
                </Link>
            </div>
        </main>
    );
}
