import * as React from "react";

type LoadingState = { loading: true; error: null } | { loading: false; error: string | null };

type SetLoadingStateFn = (loadingOrError: boolean | string) => void;

type LoadingStateReturnType = [state: LoadingState, setLoadingFn: SetLoadingStateFn];

export function useLoadingState(defaultIsLoading: boolean): LoadingStateReturnType {
    const [state, setState] = React.useState<LoadingState>({
        loading: defaultIsLoading,
        error: null,
    });

    const setLoadingState: SetLoadingStateFn = (value) => {
        if (value === true) {
            setState({
                loading: true,
                error: null,
            });
        } else {
            setState({
                loading: false,
                error: typeof value === "string" ? value : null,
            });
        }
    };

    return [state, setLoadingState];
}
