import * as V1 from "kmmp/orders/v1";
import * as V2 from "kmmp/orders/v2";

type ExclusiveV1BackgroundKeys = Exclude<keyof V1.Background, keyof V2.Background>;
type ExclusiveV1FrameKeys = Exclude<keyof V1.Frame, keyof V2.Frame>;

const exclusiveBgKeys: ExclusiveV1BackgroundKeys[] = ["id", "image"];
const exclusiveFrameKeys: ExclusiveV1FrameKeys[] = ["id", "landscapeImage", "portraitImage"];

/**
 * Checks if the customization object is version 1 by looking for exclusive keys.
 */
export function isPortraitCustomizationVersion1(
    input: V1.Customization | V2.PortraitCustomization
): input is V1.Customization {
    const bgKeys = Object.keys(input.background);
    const frameKeys = Object.keys(input.frame);

    return (
        exclusiveBgKeys.some((key) => bgKeys.indexOf(key) > -1) &&
        exclusiveFrameKeys.some((key) => frameKeys.indexOf(key) > -1)
    );
}

/**
 * Checks if the customization object is version 2 by looking for exclusive keys belonging to v1.
 */
export function isPortraitCustomizationVersion2(
    input: V1.Customization | V2.PortraitCustomization
): input is V2.PortraitCustomization {
    const bgKeys = Object.keys(input.background);
    const frameKeys = Object.keys(input.frame);

    return (
        exclusiveBgKeys.every((key) => bgKeys.indexOf(key) === -1) &&
        exclusiveFrameKeys.every((key) => frameKeys.indexOf(key) === -1)
    );
}
