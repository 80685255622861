import * as React from "react";
import { Route, DefaultParams, Path, ExtractRouteParams } from "wouter";
import { RequireAdmin, RequireAuthentication } from "client/components/auth";

type WrappedRouteProps<T extends DefaultParams | undefined = undefined, RoutePath extends Path = Path> = {
    path?: RoutePath;
    anonymous?: boolean;
    requireAdmin?: boolean;
    children:
        | ((params: T extends DefaultParams ? T : ExtractRouteParams<RoutePath>) => React.ReactNode)
        | React.ReactNode;
};

export function wrapRoute(wrapper: (children: React.ReactNode) => JSX.Element) {
    return function WrappableRoute<T extends DefaultParams | undefined = undefined, RoutePath extends Path = Path>(
        props: WrappedRouteProps<T, RoutePath>
    ): JSX.Element {
        const renderRoute = (routeParams: any) => {
            const children =
                typeof props.children === "function" ? wrapper(props.children(routeParams)) : wrapper(props.children);

            if (props.requireAdmin === true) {
                return <RequireAdmin>{children}</RequireAdmin>;
            }

            if (props.anonymous !== true) {
                return <RequireAuthentication>{children}</RequireAuthentication>;
            }

            return children;
        };

        return <Route {...props}>{renderRoute}</Route>;
    };
}
