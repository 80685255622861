import { observable } from "mobx";

class DevStoreFactory {
    constructor() {}

    @observable allow_multiple_images = false;

    @observable allow_cart = false;

    @observable email_confirmations_enabled = true;
}

export const Dev = new DevStoreFactory();

// Make the dev store available to the window
window["DevStore"] = Dev;
