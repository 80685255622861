import { BaseClass } from "client/http/base";
import { Order } from "kmmp";
import { PostPutCopyResponse } from "davenport";
import { AsyncResult } from "@nozzlegear/railway";
import { CreateOrderRequest, ResubmitImagesRequest, IListResult } from "api";

class EndpointV1 extends BaseClass {
    constructor(authToken: string) {
        super("/api/v1/orders", authToken);
    }

    public list = (options?: { limit?: number; skip?: number; descending?: boolean }) =>
        this.sendRequest<IListResult<Order>>("", "GET", { qs: options });

    public getByDisplayId = (id: number) => this.sendRequest<Order>(`${id}`, "GET");
}

class EndpointV2 extends BaseClass {
    constructor(authToken: string) {
        super("/api/v2/orders", authToken);
    }

    public create = (data: CreateOrderRequest) =>
        AsyncResult.wrap(
            this.sendRequest<PostPutCopyResponse & { display_id: number; email: string | boolean }>("", "POST", {
                body: data,
            })
        );

    public resubmitImages = (orderId: number, resubmittedImages: ResubmitImagesRequest.LineItemImage[]) => {
        const data: ResubmitImagesRequest = resubmittedImages;

        return this.sendRequest<Order>(`${orderId}/resubmit-images`, "PUT", { body: data });
    };
}

export class Orders {
    constructor(protected authToken: string) {}
    public V1: EndpointV1 = new EndpointV1(this.authToken);
    public V2: EndpointV2 = new EndpointV2(this.authToken);
}
