import { WhitelabelSettings } from "kmmp";
import { ashesToAshes } from "./ashes-to-ashes";
import { matthewsPets } from "./matthews-pets";
import { messenger } from "./messenger";
import { petEternal } from "./pet-eternal";
import { campbell } from "./campbell";
import { SCI } from "./sci";
import { churchAndChapel } from "./church-and-chapel";
import { CompanyName } from "kmmp";
import { kmPortraits } from "./kmportraits";

export { ashesToAshes, campbell, matthewsPets, messenger, SCI, petEternal, churchAndChapel };

export function getWhitelabelForHost(hostname: string): WhitelabelSettings {
    switch (hostname.toLowerCase()) {
        case "localhost":
        case "sci.ngrok.io":
        case "sci.km-mp.com":
        case "www.km-mp.com":
        case "km-mp.com":
        default:
            return SCI;

        case "pomeroy.km-mp.com":
        case "ashestoashesportraits.com":
            return ashesToAshes;

        case "m-a-pets.com":
        case "www.m-a-pets.com":
            return matthewsPets;

        case "messenger.keithmerrick.dev":
        case "messenger.ngrok.io":
            return messenger;

        case "www.peteternal.com":
        case "peteternal.com":
        case "peteternal.ngrok.io":
            return petEternal;

        case "cc-mp.com":
            return churchAndChapel;

        case "campbell.keithmerrick.dev":
            return campbell;

        case "kmmp.ngrok.io":
            return kmPortraits;
    }
}

export function getHumanFriendlyNameForCompany(company: CompanyName): string {
    switch (company) {
        case "messenger":
            return "Messenger";

        case "pomeroy":
            return "Pomeroy";

        case "sci":
            return "SCI";

        case "church-and-chapel":
            return "Church & Chapel";

        case "matthews pets":
            return "Matthews Pets";

        case "pet-eternal":
            return "Pet Eternal";

        case "campbell":
            return "Frank E. Campbell - The Funeral Chapel";

        case "km-portraits":
            return "Keith Merrick Portraits";

        default:
            const unknownName: never = company;

            console.error("Unhandled company name", unknownName);

            return "";
    }
}
