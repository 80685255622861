import * as React from "react";
import { useAuth } from "../../hooks";

type Props = React.PropsWithChildren<{}>;

export function RequirePilotProductLocation(props: Props): JSX.Element {
    const auth = useAuth();

    React.useEffect(() => {
        if (!auth.canPurchasePilotProducts) {
            auth.redirectToLogin();
        }
    }, [auth.authenticated]);

    if (!auth.canPurchasePilotProducts) {
        // User isn't authenticated, the effect will redirect them
        return <React.Fragment />;
    }

    return <>{props.children}</>;
}
