import * as Frontend from "kmmp/frontend";

/**
 * Returns the correct signature portrait package type based on the size of the frame.
 */
export function getPackageFromFrameSize(
    size: Frontend.SignaturePortraitFrameSize
): Frontend.SignaturePortraitPackageType {
    switch (size) {
        case "16x20":
            return "SP1";

        case "11x14":
            return "SP2";

        case "8x10":
            return "SP3";

        default:
            const s: never = size;
            throw new Error(`Unhandled frame size ${s}`);
    }
}

/**
 * Returns the correct signature portrait frame size based on the package type.
 */
export function getFrameSizeFromPackage(
    pkg: Frontend.SignaturePortraitPackageType
): Frontend.SignaturePortraitFrameSize {
    switch (pkg) {
        case "SP1":
            return "16x20";

        case "SP2":
            return "11x14";

        case "SP3":
            return "8x10";

        default:
            const s: never = pkg;
            throw new Error(`Unhandled package type ${s}`);
    }
}

export function isSignaturePortraitPackage(pkg: string): pkg is Frontend.SignaturePortraitPackageType {
    switch (pkg as Frontend.SignaturePortraitPackageType) {
        case "SP1":
        case "SP2":
        case "SP3":
            return true;

        default:
            return false;
    }
}
