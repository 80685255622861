import * as React from "react";

type ReturnType<T> = [T, (newState: Partial<T>) => void, (newState: T) => void];

/**
 * A react hook that wraps React.useState and returns a function allowing partial updates to the state object, merging the new state with the current state.
 */
export function useState<T>(initialState: T): ReturnType<T> {
    const [state, setFullState] = React.useState(initialState);
    const setState = (newState: Partial<T>) => setFullState({ ...state, ...newState });

    return [state, setState, setFullState];
}
