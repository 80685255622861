import { action, observable, isObservable, isObservableArray, IObservable } from "mobx";
import { Announcement } from "kmmp";
import { CONSTANTS } from "client/constants";

type AnnouncementsChangedEvent = Event & {
    data: string;
};

function isAnnouncementsChangedEvent(event: Event): event is AnnouncementsChangedEvent {
    const e = event as any;

    return "data" in event && typeof e.data === "string";
}

class AnnouncementStoreFactory {
    constructor() {
        // Function to connect to the announcement events endpoint and update the observable each time the announcements are changed
        const connect = (reconnectTimeout = 5000) => {
            const eventSource = new EventSource("/api/v1/announcements/stream", {
                withCredentials: true,
            });

            eventSource.onopen = () => {
                console.info("Connected to announcements stream");
                // Reset the connection timeout
                reconnectTimeout = 5000;
            };

            eventSource.addEventListener(CONSTANTS.ANNOUNCEMENTS_CHANGED_EVENT, (event) => {
                if (isAnnouncementsChangedEvent(event)) {
                    this.updateAnnouncements(JSON.parse(event.data));
                }
            });

            eventSource.onerror = (errEvent) => {
                console.error(
                    `Encountered error with announcement events stream, attempting to reconnect in ${
                        reconnectTimeout / 1000
                    } seconds`,
                    errEvent
                );

                // Close the current event source, then try to reconnect after the timeout
                eventSource.close();

                setTimeout(() => {
                    // Set the maximum timeout to five minutes
                    const fiveMins = 60000 * 5;
                    const newTimeout = reconnectTimeout >= fiveMins ? fiveMins : reconnectTimeout + 5000;

                    connect(newTimeout);
                }, reconnectTimeout);
            };
        };

        connect();
    }

    announcements = observable.box<ReadonlyArray<Announcement>>([]);

    @action updateAnnouncements = (announcements: ReadonlyArray<Announcement>) => {
        this.announcements.set(announcements);
    };
}

export const Announcements = new AnnouncementStoreFactory();

// Make the store available to the window for test purposes
window["AnnouncementStore"] = Announcements;
