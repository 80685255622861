import * as React from "react";
import * as dates from "shared/dates";
import * as utils from "shared/utils";
import { Announcement } from "kmmp";
import { AnnouncementsClient } from "client/http/announcements";
import { CreateOrModifyAnnouncement } from "api";
import { Dialog } from "@nozzlegear/react-win-dialog";
import { useLoadingState } from "client/hooks/use-loading-state";
import { useUnauthorizedPrompt } from "client/hooks/use-unauthorized-prompt";
import { MaybeError } from "shared/components/maybe-error";
import { HolidayControls } from "./holiday-controls";
import { NewsControls } from "./news-controls";
import { If } from "shared/components";
import { Auth } from "client/stores";
import { Result } from "@nozzlegear/railway";
import { ApiError } from "client/http";

type AnnouncementType = Announcement["type"];

interface Props {
    open: boolean;
    announcement: Announcement | null;
    onAnnouncementSaved: (data: CreateOrModifyAnnouncement.Response) => void;
    onClose: () => void;
}

interface State {
    type: AnnouncementType;
    title: string;
    start: Date | null;
    displayDate: string | null;
    expiration: Date | null;
    partingPhrase: string;
    message: string;
}

function getDefaultState(announcement: Announcement | null): State {
    if (announcement === null || announcement?.type === "breaking") {
        const message = announcement?.message ?? "";

        const value: State = {
            type: "breaking",
            title: announcement?.title ?? "",
            expiration: dates.toDateOrNull(announcement?.expiration),
            message: Array.isArray(message) ? message.join("\n\n") : message,
            partingPhrase: "",
            displayDate: null,
            start: dates.toDateOrNull(announcement?.timestamp),
        };

        console.log("getting default state", {
            announcement,
            state: value,
        });

        return value;
    }

    const message = announcement?.customMessage ?? "";

    return {
        type: "holiday",
        title: announcement?.name ?? "",
        expiration: dates.toDateOrNull(announcement?.expiration),
        partingPhrase: announcement.salutation ?? null,
        message: Array.isArray(message) ? message.join("\n\n") : message,
        displayDate: announcement.displayDate ?? null,
        start: dates.toDateOrNull(announcement.timestamp),
    };
}

function mapStateToRequest(state: State): Result<CreateOrModifyAnnouncement.Request> {
    const expiration = state.expiration !== null ? dates.toTimestamp(state.expiration) : false;
    let output: CreateOrModifyAnnouncement.Request;

    if (state.type === "holiday") {
        if (state.start === null) {
            return Result.ofError("Start Date cannot be empty.");
        }

        output = {
            type: "holiday",
            timestamp: dates.toTimestamp(state.start),
            expiration: expiration,
            displayDate: state.displayDate ?? undefined,
            customMessage: state.message,
            name: state.title,
            salutation: state.partingPhrase,
        };
    } else {
        output = {
            type: "breaking",
            timestamp: state.start !== null ? dates.toTimestamp(state.start) : Date.now(),
            expiration: expiration,
            message: utils.splitLines(state.message),
            title: state.title,
        };
    }

    return Result.ofValue(output);
}

export function EditDialog(props: Props): JSX.Element {
    const handleUnauthorized = useUnauthorizedPrompt();
    const client = React.useMemo(() => new AnnouncementsClient(Auth.token.value), []);
    const [state, setState] = React.useState(getDefaultState(props.announcement));
    const [{ loading, error }, setLoadingState] = useLoadingState(false);

    React.useEffect(() => {
        // Update the state when the announcement prop has changed
        setState(getDefaultState(props.announcement));
    }, [props.announcement]);

    async function saveChanges() {
        if (loading) return;

        const validation = mapStateToRequest(state);

        if (validation.isError()) {
            const error = validation.getError() as string;

            return setLoadingState(error);
        }

        setLoadingState(true);

        try {
            const request = validation.getValue();
            const result = props.announcement
                ? await client.modifyAnnouncement(props.announcement._id!, props.announcement._rev!, request)
                : await client.createAnnouncement(request);

            setLoadingState(false);
            props.onAnnouncementSaved(result);
        } catch (e: any) {
            console.error("Failed to save announcement changes:", e);

            if (e instanceof ApiError && e.unauthorized) {
                handleUnauthorized();
            }

            setLoadingState(
                e instanceof Error ? e.message : "Something went wrong and the announcement could not be saved."
            );
        }
    }

    function setType(e: React.FormEvent<HTMLSelectElement>) {
        const type = e.currentTarget.value === "holiday" ? "holiday" : "breaking";

        setState({
            ...state,
            type: type,
        });
    }

    function setExpiration(newValue: Date | null) {
        setState({
            ...state,
            expiration: newValue,
        });
    }

    function setMessage(newValue: string) {
        setState({
            ...state,
            message: newValue,
        });
    }

    function setTitle(newValue: string) {
        setState({
            ...state,
            title: newValue,
        });
    }

    function onStartChange(newValue: Date | null) {
        setState({
            ...state,
            start: newValue,
        });
    }

    function onDisplayDateChange(newValue: string | null) {
        setState({
            ...state,
            displayDate: newValue,
        });
    }

    function onPartingPhraseChange(newValue: string) {
        setState({
            ...state,
            partingPhrase: newValue,
        });
    }

    return (
        <Dialog
            open={props.open}
            title={props.announcement !== null ? "Edit Announcement" : "Create Announcement"}
            primaryText="Save Announcement"
            secondaryText="Close"
            loadingHidesButtons={true}
            onPrimaryClick={saveChanges}
            onSecondaryClick={props.onClose}
            loading={loading}
            className="edit-announcement-dialog"
        >
            <form onSubmit={saveChanges}>
                <div className="form-group">
                    <label>{"Announcement Type"}</label>
                    <select className="form-control" value={state.type} onChange={setType}>
                        <option value={"breaking"}>{"News Announcement"}</option>
                        <option value={"holiday"}>{"Holiday"}</option>
                    </select>
                </div>
                <If condition={state.type === "breaking"}>
                    <NewsControls
                        key="news-controls"
                        expiration={state.expiration}
                        message={state.message}
                        title={state.title}
                        onExpirationChange={setExpiration}
                        onMessageChange={setMessage}
                        onTitleChange={setTitle}
                    />
                </If>
                <If condition={state.type === "holiday"}>
                    <HolidayControls
                        key="holiday-controls"
                        expiration={state.expiration}
                        message={state.message}
                        name={state.title}
                        displayDate={state.displayDate}
                        partingPhrase={state.partingPhrase}
                        start={state.start}
                        onExpirationChange={setExpiration}
                        onMessageChange={setMessage}
                        onNameChange={setTitle}
                        onStartChange={onStartChange}
                        onDisplayDateChange={onDisplayDateChange}
                        onPartingPhraseChange={onPartingPhraseChange}
                    />
                </If>
                <MaybeError error={error} />
            </form>
        </Dialog>
    );
}
