import * as UpsApi from "shipping-ups";
import * as Frontend from "kmmp/frontend";
import { ApiErrorWithRawBody } from "client/http/base";
import { Shipping } from "client/http/shipping";

interface UserAddress {
    street1: string;
    street2?: string | null;
    zip: string;
    name: string;
}

interface ValidationErrorType1 {
    statusCode: number;
    error: string;
    message: string;
}

interface ValidationErrorType2 extends ValidationErrorType1 {
    errors: string[];
}

export class ShippingError extends Error {
    constructor(message: string, public statusCode: number, public humanReadable = true) {
        super(message);
    }
}

/**
 * Checks whether it's currently a Thursday or a Friday, when Saturday shipping rates should be shown.
 */
export function showSaturdayRates(): boolean {
    const day = new Date().getDay();

    return day === 4 || day === 5;
}

/**
 * Verifies the given user address. Use .catch to catch any errors returned by the UPS API.
 */
export async function verifyAddress(authToken: string, userAddress: UserAddress): Promise<Frontend.ShippingAddress> {
    const reqData: UpsApi.Address = {
        address_line_1: userAddress.street1 || "",
        address_line_2: userAddress.street2 || undefined,
        postal_code: userAddress.zip || "",
        name: userAddress.name || "",
        country_code: "US",
    };

    const api = new Shipping(authToken);

    try {
        const response = await api.validateAddress(reqData);
        const address = response.AddressKeyFormat;
        const output: Frontend.ShippingAddress = {
            name: userAddress.name,
            city: address.PoliticalDivision2 || "",
            state: address.PoliticalDivision1 || "",
            country: address.CountryCode || "",
            zip: address.PostcodePrimaryLow || "",
            street1: userAddress.street1 || "",
            street2: userAddress.street2 || "",
            street3: "",
        };

        return output;
    } catch (_e) {
        console.log("Error validating address.", _e);

        throw parseErrorMessage(_e, "address");
    }
}

export async function calculateRates(
    authToken: string,
    address: Frontend.ShippingAddress,
    packages: UpsApi.Package[],
    withSaturdayRates: boolean
): Promise<Frontend.ShippingRateData> {
    const upsAddress: UpsApi.Address = {
        address_line_1: address.street1 || "",
        address_line_2: address.street2 || undefined,
        state_code: address.state || undefined,
        country_code: address.country || "",
        name: address.name || "",
        postal_code: address.zip || "",
    };
    const reqData = {
        destination: {
            name: address.name || "",
            address: upsAddress,
        },
        packages: packages,
        withSaturdayRates: withSaturdayRates,
    };
    const api = new Shipping(authToken);

    try {
        const result = await api.calculate(reqData);
        const allowed = ["Ground", "Second Day Air", "Next Day Air", "Next Day Air Early A.M."];

        console.log("Finished calculating rates", result);

        if (withSaturdayRates) {
            allowed.push("Next Day Air (Saturday Delivery)");
            allowed.push("Next Day Air Early A.M. (Saturday Delivery)");
            allowed.push("Second Day Air (Saturday Delivery)");
        }

        //Use only those rates that are allowed
        const rates = result.RatedShipment.filter((rate) =>
            allowed.some((a) => a.toLowerCase() === rate.Service.Name.toLowerCase())
        ).sort((last, current) => {
            const currentRate = getRateValue(current);
            const lastRate = getRateValue(last);

            return currentRate - lastRate;
        });

        return {
            saturdayDeliveryAvailable: result.SaturdayDeliveryAvailable,
            rates: rates,
        };
    } catch (_e) {
        console.log("Error calculating shipping rates", _e);

        throw parseErrorMessage(_e, "rates");
    }
}

export function parseErrorMessage(request: ApiErrorWithRawBody, type: "address" | "rates") {
    if (request.status !== 422) {
        //Rates passed validation but UPS did not mark the request as valid. Error message is already set.
        return request;
    }

    const error = new ShippingError(
        `Something went wrong and we could not ${
            type === "address" ? "verify your shipping address" : "calculate your shipping rates"
        }.`,
        request.status
    );

    function isValidationErrorType2(error: ValidationErrorType1 | ValidationErrorType2): error is ValidationErrorType2 {
        // Address failed validation. Error looks like one of the following:
        // {"statusCode": 422, "error": "Unprocessable Entity", "message": "ValidationError", "errors": ["address_line_2: \"address_line_2\" must be a string"]}
        // {"statusCode": 422, "error": "Unprocessable Entity", "message": "child \"something_fake\" fails because [\"something_fake\" is required]"}

        return Array.isArray((error as ValidationErrorType2).errors);
    }

    let errorBody: ValidationErrorType1 | ValidationErrorType2;
    let errorDescription: string;

    try {
        errorBody = typeof request.rawBody === "string" ? JSON.parse(request.rawBody) : request.rawBody;
    } catch (jsonError) {
        console.error("Error parsing error response's body:", jsonError);

        return error;
    }

    if (isValidationErrorType2(errorBody)) {
        error.message =
            errorBody.message === "ValidationError"
                ? "The address you entered appears to be invalid."
                : errorBody.message;

        if (errorBody.errors.length === 0) {
            // No errors describe this and we can't parse the message's body
            return error;
        }

        errorDescription = errorBody.errors[0];
    } else {
        error.message = errorBody.message;
        errorDescription = errorBody.message;
    }

    const propertyChunks = errorDescription.split(":");

    if (propertyChunks.length < 2) {
        // Unable to parse the description of the error
        return error;
    }

    switch (propertyChunks[1]) {
        case "destination.name":
        case "destination.address.name":
        case "destination.address.company":
            error.message = "Ship To Name/Company is not valid.";
            break;

        case "address_line_1":
        case "destination.address.address_line_1":
            error.message = "Street Address 1 is not valid.";
            break;

        case "address_line_2":
        case "destination.address.address_line_2":
            error.message = "Street Address 2 is not valid.";
            break;

        case "destination.address.postal_code":
            error.message = "ZIP/Postal Code is not valid.";
            break;
    }

    return error;
}

export function buildParcel(
    total: number,
    type:
        | Frontend.PackageType
        | "earlyamtube"
        | "cards"
        | "charcoalpaper"
        | "charcoalframe"
        | "charcoal"
        | "boards"
        | "fourframes"
) {
    const quantity = total;
    let weightInPounds: number;
    let dimensions: { height: number; length: number; width: number } | undefined = undefined;
    let packagingType: string | undefined = undefined;

    //Switch type
    switch (type) {
        default:
        case "CP1":
        case "CP15":
            weightInPounds = quantity;
            dimensions = {
                height: 2 * quantity,
                length: 31,
                width: 22,
            };
            break;

        case "earlyamtube":
            weightInPounds = 1;
            dimensions = {
                height: 2,
                length: 22,
                width: 2,
            };
            break;

        case "cards":
            weightInPounds = 1;
            packagingType = "UPSLetter";
            break;

        case "charcoalpaper":
            weightInPounds = 1;
            dimensions = {
                width: 12.5,
                length: 9.5,
                height: 0.5,
            };
            break;

        case "charcoalframe":
        case "charcoal":
            weightInPounds = 2 * quantity;
            dimensions = {
                height: 4, //One charcoal box can hold 2 charcoal frames. Box height is 4 inches.
                length: 13,
                width: 11,
            };
            break;

        case "boards":
            weightInPounds = 5 * quantity;
            dimensions = {
                height: 2 * quantity,
                length: 24,
                width: 17,
            };
            break;

        case "fourframes":
            weightInPounds = 7 * 4; // 4 frames in 1 shipment
            dimensions = {
                height: 2 * 4,
                length: 31,
                width: 22,
            };
            break;
    }

    return {
        weight: weightInPounds,
        dimensions: dimensions,
        packaging_type: packagingType,
    } as UpsApi.Package;
}

export function getRateValue(rate: UpsApi.Rate) {
    let value = rate.TotalCharges.MonetaryValue;

    if (rate.NegotiatedRates) {
        value = rate.NegotiatedRates.NetSummaryCharges.GrandTotal.MonetaryValue;
    }

    return parseFloat(value);
}
