﻿import * as React from "react";
import { CompanyName } from "kmmp";
import { FrameSize } from "kmmp/frontend";

interface Props extends React.Props<any> {
    company: CompanyName;
    showForCompanies: CompanyName[];
    selectedSize: FrameSize;
    validSizes: FrameSize[];
    onChange: (size: FrameSize) => void;
}

export function FrameSelector(props: Props): JSX.Element {
    if (!props.showForCompanies.some((c) => c === props.company)) {
        return <div />;
    }

    return (
        <div className="selector-container">
            <p>{`Frame Size:`}</p>
            <select
                autoComplete="off"
                className="form-control selector"
                value={props.selectedSize}
                onChange={(evt) => props.onChange(evt.currentTarget.value as FrameSize)}
            >
                {props.validSizes.map((o) => (
                    <option key={o} value={o}>
                        {o}
                    </option>
                ))}
            </select>
        </div>
    );
}
