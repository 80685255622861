import { BaseClass, ApiErrorWithRawBody } from "client/http/base";
import { AxiosResponse } from "axios";
import * as UpsApi from "shipping-ups";

export class Shipping extends BaseClass {
    constructor(authToken: string) {
        super("/api/v1/shipping", authToken);
    }

    /**
     * A function that parses the error response of an Axios request and returns a new ApiError.
     * Only called when a response does not indicate success. This function can be overriden to allow
     * custom error parsing.
     * @param body The response body returned by Axios. May be a string or a JS object.
     * @param axiosResponse The Axios response object.
     */
    parseErrorResponse(body?: string | Object, axiosResponse?: AxiosResponse): ApiErrorWithRawBody {
        // Let the super parser method parse the response, then add the raw body.
        const baseError = super.parseErrorResponse(body, axiosResponse as any);

        return new ApiErrorWithRawBody(baseError, axiosResponse ? axiosResponse.data : "");
    }

    public validateAddress = (data: UpsApi.Address) =>
        this.sendRequest<UpsApi.AddressValidationResponse>("address/validate", "POST", {
            body: data,
        });

    public calculate = (data: {
        destination: { name: string; address: UpsApi.Address };
        packages: UpsApi.Package[];
        withSaturdayRates: boolean;
    }) =>
        this.sendRequest<UpsApi.RatesResult & { SaturdayDeliveryAvailable: boolean }>("rates/calculate", "POST", {
            body: data,
        });
}
