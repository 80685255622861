import * as React from "react";

interface Props<T> extends React.Props<any> {
    source: T[];
    children: (item: T, index: number) => JSX.Element | JSX.Element[];
}

/**
 * A component that takes a source list and calls the mapping child function for each item.
 */
export function Repeater<T>(props: Props<T>): JSX.Element {
    const children = props.source.reduce<JSX.Element[]>((state, item, index) => {
        const newChildren = props.children(item, index);

        if (Array.isArray(newChildren)) {
            // Flatten the array
            return [...state, ...newChildren];
        }

        return [...state, newChildren];
    }, []);

    return <React.Fragment>{children}</React.Fragment>;
}
