import * as React from "react";
import { range } from "shared/range";
import { Option } from "@nozzlegear/railway";

interface IProps extends React.Props<any> {
    quantity: number;
    onChange: (newValue: number) => void;
    leftLabel: string;
    rightLabel: Option<string>;
    max: number;
    min: number;
}

export function QuantitySelector(this: void, props: IProps): JSX.Element {
    const onChange = (e: React.FormEvent<HTMLSelectElement>) => {
        const value = e.currentTarget.value;

        props.onChange(parseInt(value));
    };

    return (
        <div className="selector-container">
            <p>
                {props.leftLabel}

                {props.rightLabel
                    .map((str) => (
                        <small className="pull-right" style={{ float: "right" }}>
                            {str}
                        </small>
                    ))
                    .defaultValue(null as any)}
            </p>
            <select className="form-control selector" value={props.quantity.toString()} onChange={onChange}>
                {range(props.min, props.max + 1).map((i) => (
                    <option key={i} value={i}>
                        {i}
                    </option>
                ))}
            </select>
        </div>
    );
}
