import { WhitelabelSettings } from "kmmp";
import { getFrames, getBackgrounds } from "shared/images";

const petBackgrounds = getBackgrounds("matthews pets", "pet");
const frames = getFrames("matthews pets");

export const matthewsPets: WhitelabelSettings = {
    companyName: "matthews pets",
    siteName: "Matthews International",
    subjectType: "pet",
    domain: "m-a-pets.com",
    siteType: "ecommerce",
    products: [
        {
            _id: "POM1",
            _rev: "v1",
            name: "Framed Canvas Pet Portrait",
            urlSlug: "framed-canvas-pet-portrait",
            description:
                "Pick out your favorite picture of your pet and easily upload it to our professionally trained graphic artists. We'll touch up your image and then transfer it to an art-grade canvas print, framed in a high-quality wood frame of your choice.",
            imageUrl: "/public/images/ecommerce/pet-eternal-honey-horse-sample.png",
            price: 89.0,
            priceBreaks: [],
            backgroundInfo: {
                hasBackgrounds: true,
                backgrounds: petBackgrounds,
            },
            cardInfo: {
                hasCards: false,
            },
            frameInfo: {
                hasFrames: true,
                frames: frames,
            },
        },
        {
            _id: "POMGAL1",
            _rev: "v1",
            name: "Gallery-wrapped Canvas Pet Portrait",
            urlSlug: "gallery-wrapped-canvas-pet-portrait",
            description:
                "The gallery-wrapped Canvas Pet Portrait is a high-quality way to remember your precious companion. This canvas print is wrapped around an underlying wood frame, which can then be hung on your wall where it gives a three-dimensional view of the portrait.",
            imageUrl: "https://placekitten.com/g/350/453",
            price: 69.0,
            priceBreaks: [],
            backgroundInfo: {
                hasBackgrounds: true,
                backgrounds: petBackgrounds,
            },
            cardInfo: {
                hasCards: false,
            },
            frameInfo: {
                hasFrames: false,
            },
        },
    ],
};
