import * as React from "react";
import * as Classes from "classnames";
import * as Frontend from "kmmp/frontend";
import { WhitelabelSettings } from "kmmp";
import { SignatureJerseyFrame, SignatureJerseyPackageType } from "kmmp/frontend";
import { SignatureJerseyPreview } from "client/components/signature_jersey_preview";
import { getSignatureJerseyImage } from "shared/images";

interface IProps extends React.Props<any> {
    frame: SignatureJerseyFrame;
    onSelectFrame: (newSelection: SignatureJerseyFrame) => void;
    whitelabelSettings: WhitelabelSettings;
    displayAsPackage: boolean;
}

/**
 * Component for selecting a signature jersey by clicking on an image.
 */
export function VisualSignatureJerseySelector(this: void, props: IProps) {
    // Use the SJ1 package for all preview images
    const packageType: SignatureJerseyPackageType = "SJ1";
    const previewImage = (frame: SignatureJerseyFrame) => (e: React.MouseEvent<any>) => {
        e.preventDefault();
        props.onSelectFrame(frame);
    };
    const frames: Array<{ name: SignatureJerseyFrame; src: string }> = [
        {
            name: "Black",
            src: getSignatureJerseyImage(packageType, "Black"),
        },
    ];

    const images = frames.map((frame) => (
        <div key={frame.name} className="pure-u-sm-12-24 pure-padded">
            <a href="#" key={frame.name} onClick={previewImage(frame.name)}>
                <img style={{ paddingTop: "10px", paddingBottom: "10px" }} src={frame.src} className="img-responsive" />
            </a>
        </div>
    ));

    return (
        <div className="pure-g justify-space-between">
            <div className="pure-u-sm-9-24">
                <div className="thumbnails">{images}</div>
            </div>
            <div className="pure-u-sum-1-24" />
            <div className="pure-u-sm-14-24">
                <SignatureJerseyPreview
                    packageType={packageType}
                    frame={props.frame}
                    whitelabelSettings={props.whitelabelSettings}
                    displayAsPackage={props.displayAsPackage}
                />
            </div>
        </div>
    );
}
