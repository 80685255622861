import * as React from "react";
import * as Frontend from "kmmp/frontend";
import ReactCropper from "react-cropper";
import { Orientation } from "kmmp";
import { BlobUtility } from "client/modules/blobs";
import { ToggleCropperControls } from "./toggle-cropper-controls";
import { DeleteSelectedImageButton } from "./delete-selected-image-button";

export interface Props extends React.Props<any> {
    image: Frontend.PreviewImage;
    orientation: Orientation;
    onDeleteImage: () => void;
    onRevertCrop: () => void;
    onCrop: (blob: Blob) => void;
    onSetNotes: (notes: string) => void;
    onDisableCrop: () => void;
}

interface State {}

export class CropImageForm extends React.Component<Props, State> {
    get aspectRatio(): number {
        return /^(portrait|vertical)$/i.test(this.props.orientation) ? 0.8 : 1.25;
    }

    private cropper: any;

    private readonly blobUtility: BlobUtility = new BlobUtility();

    /**
     * Crops the Stores.Images.selected_image.
     */
    private cropImage: (e: React.MouseEvent<any>) => Promise<void> = async (e) => {
        e.preventDefault();

        const canvas: HTMLCanvasElement = this.cropper.getCroppedCanvas();
        const blob = await this.blobUtility.getBlobFromCanvas(canvas);

        this.props.onCrop(blob);
    };

    render: () => JSX.Element = () => {
        const { image, onDeleteImage, onRevertCrop, onSetNotes, onDisableCrop } = this.props;
        const revertCrop = (e: React.MouseEvent<any>) => {
            e.preventDefault();
            onRevertCrop();
        };
        const setNotes = (e: React.FormEvent<HTMLInputElement>) => {
            onSetNotes(e.currentTarget.value);
        };

        return (
            <div className="cropper">
                <DeleteSelectedImageButton onDelete={onDeleteImage} />
                <div className="selected-image">
                    <div>
                        {image.croppedImage ? (
                            <img src={image.croppedImage.dataUrl} />
                        ) : (
                            <ReactCropper
                                ref={(r) => (this.cropper = r)}
                                src={image.scaledImage.dataUrl}
                                aspectRatio={this.aspectRatio}
                            />
                        )}
                    </div>
                </div>
                <div className="controls">
                    <input
                        key={"selected-image-notes"}
                        type="text"
                        autoComplete="off"
                        className="form-control"
                        value={image.instructions || ""}
                        onChange={setNotes}
                        placeholder="Enter notes or instructions for this image."
                    />
                    <button
                        className="btn btn-default"
                        type="button"
                        onClick={image.croppedImage ? revertCrop : this.cropImage}
                    >
                        {image.croppedImage ? "Undo Crop" : "Crop Image"}
                    </button>
                </div>
                <ToggleCropperControls enabled={true} onDisable={onDisableCrop} onEnable={() => {}} />
            </div>
        );
    };
}
