/**
 * Returns true if a string has any unicode (non-ASCII) characters.
 * Source: https://stackoverflow.com/a/21718743
 */
export function hasUnicode(str: string): boolean {
    for (let i = 0; i < str.length; i++) {
        if (str.charCodeAt(i) > 127) return true;
    }
    return false;
}
