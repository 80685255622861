import * as React from "react";
import { Auth } from "../../stores";
import { useAuth } from "../../hooks";

type Props = { [key: string]: any };

/**
 * A component that logs the user out and redirects them to the login page when rendered.
 */
export function Logout(_: Props): JSX.Element {
    const auth = useAuth();

    React.useEffect(() => {
        auth.redirectToLogin({ replace: false, returnToCurrentPath: false });
        console.log("calling logout action");
        Auth.logout();
    });

    return <React.Fragment />;
}
