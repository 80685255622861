import { action, computed, observable } from "mobx";
import * as Frontend from "kmmp/frontend";

class ImageStoreFactory {
    constructor() {}

    @observable is_uploading = false;

    @observable all_images: ReadonlyArray<Frontend.PreviewImage> = [];

    @observable selected_image_id: string | null = null;

    @computed
    get selected_image() {
        return this.all_images.find((i) => i.uuid === this.selected_image_id);
    }

    /**
     * Resets all images, presumably after they've been uploaded.
     */
    @action
    reset() {
        this.is_uploading = false;
        this.all_images = [];
        this.selected_image_id = null;
    }

    @action
    addImage(image: Frontend.PreviewImage, selectImage: boolean) {
        this.all_images = [...this.all_images, image];

        if (selectImage) {
            this.selected_image_id = image.uuid;
        }
    }

    @action
    selectImage(uuid: string) {
        this.selected_image_id = uuid;
    }

    @action
    deleteImage(uuid: string) {
        const images = [...this.all_images];
        const index = images.findIndex((i) => i.uuid === uuid);

        images.splice(index, 1);

        this.all_images = images;
    }

    @action
    setCroppedData(uuid: string, blob: Blob) {
        const images = [...this.all_images];
        const imageIndex = images.findIndex((i) => i.uuid === uuid);
        const original = images[imageIndex];
        const image: Frontend.PreviewImage = {
            ...original,
            croppedImage: {
                blob: blob,
                dataUrl: URL.createObjectURL(blob),
                dimensions: null,
            },
        };

        images.splice(imageIndex, 1, image);

        this.all_images = images;

        // Release the old object url for performance
        if (original && original.croppedImage) {
            URL.revokeObjectURL(original.croppedImage.dataUrl);
        }
    }

    @action
    revertCrop(uuid: string) {
        const images = [...this.all_images];
        const imageIndex = images.findIndex((i) => i.uuid === uuid);
        const image = images[imageIndex];

        images.splice(imageIndex, 1, { ...image, croppedImage: null });

        this.all_images = images;
    }

    @action
    setNotes(uuid: string, notes: string) {
        const images = [...this.all_images];
        const imageIndex = images.findIndex((i) => i.uuid === uuid);
        const image = images[imageIndex];

        images.splice(imageIndex, 1, { ...image, instructions: notes });

        this.all_images = images;
    }

    @action
    setImageUploadStatus(uuid: string, uploaded: boolean) {
        const images = [...this.all_images];
        const imageIndex = images.findIndex((i) => i.uuid === uuid);
        const image = images[imageIndex];

        images.splice(imageIndex, 1, { ...image, uploaded });

        this.all_images = images;
    }

    parseBase64(base64: string) {
        const split = base64.split(/;base64,/, 2);
        const contentType = split[0].replace(/^data:/, "");

        return {
            contentType: contentType,
            strippedBase64: split[1],
        };
    }

    @action
    beginUpload() {
        this.is_uploading = true;
    }

    @action
    endUpload() {
        this.is_uploading = false;
    }

    @action
    acceptImageSizeWarning(uuid: string) {
        const images = [...this.all_images];
        const imageIndex = images.findIndex((i) => i.uuid === uuid);
        const image = images[imageIndex];

        images.splice(imageIndex, 1, {
            ...image,
            sizeDetails: { ...image.sizeDetails!, acceptedImageSizeWarning: true },
        });

        this.all_images = images;
    }
}

//export const Images = new ImageStoreFactory();
