import * as React from "react";
import * as Frontend from "kmmp/frontend";
import Classes = require("classnames");
import { WhitelabelSettings } from "kmmp";
import { getBackgroundUrl } from "shared/images";

interface Props extends React.Props<any> {
    background: Frontend.BackgroundImage;
    onSelectBackground: (newSelection: Frontend.BackgroundImage) => void;
    whitelabelSettings: WhitelabelSettings;
    allBackgrounds: Frontend.BackgroundImage[];
}

/**
 * Component for selecting a background by clicking on an image. Also displays the selected background.
 */
export function VisualBackgroundSelector(this: void, props: Props) {
    const {
        background,
        onSelectBackground,
        allBackgrounds,
        whitelabelSettings: { companyName, subjectType },
    } = props;
    const select = (bg: Frontend.BackgroundImage) => (e: React.MouseEvent<any>) => {
        e.preventDefault();
        onSelectBackground(bg);
    };

    const backgrounds = allBackgrounds.map((bg) => (
        <div key={bg.name} className={"pure-u-6-24 pure-padded"}>
            <div className={"background-img-container"}>
                {/* The images in this selector will always be shown as 16x20 Portrait. There's no reason to change them, they're just previews. */}
                <img
                    src={getBackgroundUrl(companyName, subjectType, bg, "portrait", "16x20")}
                    alt={bg.name}
                    className={Classes("img-responsive cursor", {
                        active: background.name === bg.name,
                    })}
                    onClick={select(bg)}
                />
                <p>{bg.name}</p>
            </div>
        </div>
    ));

    return (
        <div>
            <h4>
                {"Portrait Backgrounds"}
                <small style={{ color: "#777" }}>{" (click a background to select it.)"}</small>
            </h4>
            <div className="thumbnails backgrounds pure-g">{backgrounds}</div>
        </div>
    );
}
