// This module contains constants and functions for working with preview images for frames, backgrounds and packages.

import { compute } from "@nozzlegear/railway";
import { SubjectType, Orientation, CompanyName } from "kmmp";
import {
    FrameImage,
    BackgroundImage,
    ShadowBoxColor,
    SignatureJerseyPackageType,
    SignatureJerseyFrame,
} from "kmmp/frontend";
import * as Version1 from "kmmp/orders/v1";
import * as Version2 from "kmmp/orders/v2";
import { CreateOrderRequest } from "api";
import { isLineItemVersion1, isLineItemVersion2 } from "./is-line-item";

const getOriFolder = (orientation: Orientation) => (orientation === "landscape" ? "horizontal" : "vertical");

/**
 * Gets a URL pointing to the frame image determined by the subject type, frame, orientation and size.
 */
export function getFrameUrl(
    companyName: CompanyName,
    subjectType: SubjectType,
    frame: FrameImage,
    orientation: Orientation,
    size: Version1.Size | Version2.Size
) {
    return `/public/images/composed/${subjectType}/${getOriFolder(orientation)}/${size}/frames/${frame.fileName}`;
}

/**
 * Gets a URL pointing to the background image determined by the subject type, background, orientation and size.
 */
export function getBackgroundUrl(
    companyName: CompanyName,
    subjectType: SubjectType,
    bg: BackgroundImage,
    orientation: Orientation,
    size: Version1.Size | Version2.Size
) {
    // Eventually all companies will move into their own folder, but for now it's just messenger
    const postfix = companyName === "messenger" ? `messenger/${bg.fileName}` : bg.fileName;

    return `/public/images/composed/${subjectType}/${getOriFolder(orientation)}/${size}/backgrounds/${postfix}`;
}

/**
 * Finds the fancy frame URL for the given frame.
 */
export function findFancyFrame(
    companyName: CompanyName,
    subjectType: SubjectType,
    frame: FrameImage | string,
    background: BackgroundImage,
    orientation: Orientation
): string {
    const folder = subjectType === "human" ? "fancy-frames" : "pet-fancy-frames";
    const framePrefix = compute(() => {
        const frameSlug = typeof frame === "string" ? frame.toLowerCase() : frame.name.toLowerCase();

        switch (companyName) {
            case "sci":
                return "package-" + frameSlug;

            case "messenger":
                return "messenger/16x20/" + frameSlug;

            default:
                return frameSlug;
        }
    });
    const bgFile = background.fileName.replace(/\..*$/, "");
    const orientationSuffix = orientation === "landscape" ? "-h" : "";

    return `/public/${folder}/${framePrefix}-${bgFile}${orientationSuffix}.png`;
}

export function findSignaturePortraitFancyFrame(
    frame: FrameImage,
    background: BackgroundImage,
    orientation: Orientation
): string {
    const folder = "/public/fancy-frames/signature-portraits";
    const frameSlug = frame.name.toLowerCase();
    const bgSlug = background.fileName.replace(/\..*$/, "");
    const orientationSlug = orientation === "landscape" ? "-h" : "";

    return `${folder}/${frameSlug}-${bgSlug}${orientationSlug}.png`;
}

export const getFrames: (CompanyName: CompanyName) => FrameImage[] = (companyName) => {
    if (companyName === "messenger") {
        return [
            {
                name: "Black",
                fileName: "messenger-black.png",
            },
            {
                name: "Chestnut",
                fileName: "messenger-chestnut.png",
            },
            {
                name: "GoldLine",
                fileName: "messenger-goldline.png",
            },
            {
                name: "Wine",
                fileName: "messenger-wine.png",
            },
        ];
    }

    return [
        {
            name: "Black",
            fileName: "black.png",
        },
        {
            name: "Honey",
            fileName: "honey.png",
        },
        {
            name: "Mahogany",
            fileName: "mahogany.png",
        },
        {
            name: "Vintage",
            fileName: "vintage.png",
        },
    ];
};

export const getSignaturePortraitBackgrounds: () => BackgroundImage[] = () => {
    return [
        {
            name: "As Is",
            fileName: "signed.png",
        },
        {
            name: "Gray",
            fileName: "gray.png",
            notes: "Standard in Black",
        },
        {
            name: "Brown",
            fileName: "brown.png",
            notes: "Standard in Honey/Mahogany",
        },
        {
            name: "Warm Gray",
            fileName: "warmgray.png",
            notes: "Standard in Vintage",
        },
        {
            name: "Dots",
            fileName: "dots.png",
        },
        {
            name: "Happy",
            fileName: "happy.png",
        },
        {
            name: "Slate",
            fileName: "slate.png",
        },
        {
            name: "Blue",
            fileName: "blue.png",
        },
        {
            name: "Flag",
            fileName: "flag.png",
        },
        {
            name: "Faded Flag",
            fileName: "fadedflag.png",
        },
        {
            name: "Tan",
            fileName: "tan.png",
        },
        {
            name: "Pink",
            fileName: "pink.png",
        },
    ];
};

export const getBackgrounds: (companyName: CompanyName, subjectType: SubjectType) => BackgroundImage[] = (
    companyName,
    subjectType
) => {
    if (companyName === "messenger") {
        return [
            {
                name: "As Is",
                fileName: "asis.png",
            },
            {
                name: "Flag",
                fileName: "flag.png",
            },
            {
                name: "Gray Parchment",
                fileName: "grayparch.png",
            },
            {
                name: "Marble",
                fileName: "marble.png",
            },
            {
                name: "Tan Parchment",
                fileName: "tanparch.png",
            },
        ];
    }

    const shared: BackgroundImage[] = [
        {
            name: "As Is",
            fileName: "asis.png",
        },
        {
            name: "Gray",
            fileName: "gray.png",
            notes: "Standard in Black",
        },
        {
            name: "Brown",
            fileName: "brown.png",
            notes: "Standard in Honey/Mahogany",
        },
        {
            name: "Warm Gray",
            fileName: "warmgray.png",
            notes: "Standard in Vintage",
        },
        {
            name: "Dots",
            fileName: "dots.png",
        },
        {
            name: "Happy",
            fileName: "happy.png",
        },
        {
            name: "Slate",
            fileName: "slate.png",
        },
    ];

    if (subjectType === "human") {
        return [
            ...shared,
            {
                name: "Blue",
                fileName: "blue.png",
            },
            {
                name: "Flag",
                fileName: "flag.png",
            },
            {
                name: "Faded Flag",
                fileName: "fadedflag.png",
            },
            {
                name: "Tan",
                fileName: "tan.png",
            },
            {
                name: "Pink",
                fileName: "pink.png",
            },
        ];
    }

    return [
        ...shared,
        {
            name: "Barnwood",
            fileName: "barnwood.png",
        },
        {
            name: "Blurry Leaves",
            fileName: "blurryleaves.png",
        },
        {
            name: "Fence Line",
            fileName: "fenceline.png",
        },
        {
            name: "Grass",
            fileName: "grass.png",
        },
        {
            name: "Grass Pines",
            fileName: "grass-pines.png",
        },
    ];
};

/**
 * Returns an array of filepaths to all shadow box preview images for the given color.
 */
export function getShadowBoxImages(color: ShadowBoxColor): string[] {
    switch (color) {
        case "Mahogany":
            return [
                "/public/images/shadow-boxes/mahogany-photo.png",
                "/public/images/shadow-boxes/mahogany-beach.png",
                "/public/images/shadow-boxes/mahogany-plain-open.png",
                "/public/images/shadow-boxes/mahogany-plain.png",
            ];

        case "White":
            return [
                "/public/images/shadow-boxes/white-photo.png",
                "/public/images/shadow-boxes/white-flower-garden.png",
                "/public/images/shadow-boxes/white-flower-garden-butterflies.png",
                "/public/images/shadow-boxes/white-plain.png",
                "/public/images/shadow-boxes/white-plain-open.png",
            ];

        default:
            const unrecognized: never = color;
            console.error("Unhandled shadow box color", unrecognized);
            throw new Error(`Unhandled shadow box color ${unrecognized}.`);
    }
}

/**
 * Returns the default shadow box preview image for the given color
 */
export function getShadowBoxImage(color: ShadowBoxColor): string {
    return getShadowBoxImages(color)[0];
}

export function getSignatureJerseyImage(packageType: SignatureJerseyPackageType, frame: SignatureJerseyFrame): string {
    let folder: string;
    let filename: string;

    switch (packageType) {
        case "SJ1":
            folder = "24x36";
            break;

        default:
            const unrecognized: never = packageType;
            console.error("Unhandled signature jersey package type", unrecognized);
            throw new Error(`Unhandled signature jersey package type ${unrecognized}.`);
    }

    switch (frame) {
        case "Black":
            filename = "black.png";
            break;

        default:
            const unrecognized: never = frame;
            console.error("Unhandled signature jersey frame", unrecognized);
            throw new Error(`Unhandled signature jersey frame ${unrecognized}.`);
    }

    return `/public/images/signature-jerseys/${folder}/${filename}`;
}

/**
 * Counts the number of images in a line item.
 */
export function countImages(item: Version1.LineItem | Version2.LineItem | CreateOrderRequest.LineItem): number {
    if (isLineItemVersion1(item)) {
        return item.images.length;
    }

    switch (item.type) {
        case undefined:
        case "portrait":
        case "signature-portrait":
        case "shadow-box":
            return item.images.length;

        case "signature-jersey":
            return 0;

        default:
            const neverItem: never = item;
            console.error("Unhandled line item type", neverItem);
            throw new Error("Unhandled line item type.");
    }
}
