import * as React from "react";
import { Holiday } from "kmmp";

interface Props extends React.Props<any> {
    details: Holiday;
}

/**
 * Maps a @see Holiday object to a message detailing the UPS shipping schedule for that holiday.
 */
export function HolidayMessage({ details, key }: Props): JSX.Element {
    let message: JSX.Element;

    if (Array.isArray(details.customMessage)) {
        message = (
            <div>
                {details.customMessage.map((m, i) => (
                    <p key={`${details.name}-${i}`}>{m}</p>
                ))}
            </div>
        );
    } else if (details.customMessage) {
        message = <p>{details.customMessage}</p>;
    } else {
        const displayDate = details.displayDate || new Date(details.timestamp).toLocaleDateString();

        message = (
            <div>
                <p>
                    {`Due to the ${details.name} holiday, UPS operations will be unavailable on ${displayDate}. Because there is no UPS service on ${displayDate}, the Keith Merrick Company will be closed that day.`}
                </p>
                <p>
                    {`We will be back and processing orders on the first business day after ${displayDate}, but please remember that `}
                    <strong>{`Saturday orders with properly scanned photos must be received by 9:30 AM Central.`}</strong>
                </p>
            </div>
        );
    }

    return (
        <div key={key}>
            <h1 style={{ color: "red" }}>{`${details.name} Holiday Announcement:`}</h1>
            {message}
            <p>{details.salutation}</p>
        </div>
    );
}
