import { CONSTANTS } from "client/constants";
import * as _sentry from "@sentry/browser";

export const Sentry = _sentry;

Sentry.init({
    dsn: "https://4fd6403e26854d85967b3e1f441245a4@sentry.io/50514",
    release: CONSTANTS.VERSION,
    environment: CONSTANTS.IS_LIVE ? "production" : "development",
    beforeSend: (event) => {
        if (!CONSTANTS.IS_LIVE) {
            return null;
        }

        return event;
    },
});

window.onerror = window["onunhandledrejection"] = function (evt: unknown) {
    if (typeof evt === "string") {
        if (evt.indexOf("No such interface supported") > -1) {
            // This is just a warning message that appears in IE when turning a file into a canvas image. Everything still functions correctly.
            console.warn(evt);

            return;
        }

        Sentry.captureMessage(`Caught unhandled rejection with message: ${evt}`);
    } else {
        Sentry.captureMessage(`Caught unhandled rejection, see extra details`, {
            extra: {
                rawValue: evt,
            },
        });
    }
};
