import * as React from "react";
import { Cart } from "../../stores";
import { useLocation } from "wouter";
import { Paths } from "shared/paths";

type Props = React.PropsWithChildren<{ [key: string]: any }>;

/**
 * A component that redirects the user to the home page if their cart is empty.
 */
export function RequireFilledCart(props: Props): JSX.Element {
    const [_, setLocation] = useLocation();

    React.useEffect(() => {
        if (Cart.items.length === 0) {
            setLocation(Paths.home.index);
        }
    }, [Cart.items]);

    return <>{props.children}</>;
}
