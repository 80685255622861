"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    }
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : new P(function (resolve) { resolve(result.value); }).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
function __export(m) {
    for (var p in m) if (!exports.hasOwnProperty(p)) exports[p] = m[p];
}
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var classes = require("classnames");
var creditcard = require("creditcardutils");
var FontAwesome = require("react-fontawesome");
var cc_expiry_1 = require("cc-expiry");
var countries_1 = require("./data/countries");
var cart_summary_1 = require("./cart-summary");
var address_line_1 = require("./address-line");
var railway_1 = require("@nozzlegear/railway");
var page;
(function (page) {
    page[page["customerInformation"] = 0] = "customerInformation";
    page[page["shippingMethod"] = 1] = "shippingMethod";
    page[page["paymentMethod"] = 2] = "paymentMethod";
})(page || (page = {}));
// Turn this module into a barrel by exporting all of the packages types
__export(require("./address-line"));
__export(require("./cart-summary"));
var CheckoutPage = /** @class */ (function (_super) {
    __extends(CheckoutPage, _super);
    function CheckoutPage(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.updateStateFromEvent = function (callback) {
            return function (event) {
                var value = event.currentTarget.value;
                var clonedState = __assign({}, _this.state);
                callback(clonedState, value);
                _this.setState(clonedState);
            };
        };
        var usa = countries_1.Countries.filter(function (c) { return c.iso === "US"; })[0];
        var defaultAddress = {
            city: "",
            countryCode: usa.iso,
            line1: "",
            line2: "",
            name: "",
            stateCode: railway_1.Option.ofSome(usa.states[0].iso),
            zip: railway_1.Option.ofSome("")
        };
        _this.state = {
            page: page.customerInformation,
            email: "",
            shippingAddress: defaultAddress,
            billingAddress: defaultAddress,
            selectedRate: railway_1.Option.ofNone(),
            card: {
                number: "",
                cvv: "",
                expiry: "",
                name: ""
            },
            coupons: [],
            discountCode: railway_1.Option.ofNone(),
            error: railway_1.Option.ofNone(),
            loading: false,
            rates: [],
            sameBillingAddress: false
        };
        return _this;
    }
    /**
     * Validates an address, returning Option.Some with an error message when validation fails.
     */
    CheckoutPage.prototype.validateAddress = function (address) {
        var filteredCountries = countries_1.Countries.filter(function (c) { return c.iso === address.countryCode; });
        // Ensure customer has selected a valid country
        if (filteredCountries.length === 0) {
            return railway_1.Option.ofSome("You must select a valid country.");
        }
        var countryData = filteredCountries[0];
        if (countryData.states.length > 0) {
            // Ensure the selected state exists in the list of the country's states
            if (!countryData.states.some(function (s) { return s.iso === address.stateCode.defaultValue(""); })) {
                return railway_1.Option.ofSome("You must select a valid state.");
            }
        }
        if (!address.city) {
            return railway_1.Option.ofSome("You must enter a city.");
        }
        if (!address.line1) {
            return railway_1.Option.ofSome("You must enter a street address.");
        }
        if (!address.name) {
            return railway_1.Option.ofSome("You must enter a name or company name for this address.");
        }
        if (typeof countryData.zipRegex === "number" || countryData.hasPostalCodes === false) {
            return railway_1.Option.ofNone();
        }
        var regex = countryData.zipRegex;
        var passesRegex = address.zip.map(function (z) { return new RegExp(regex).test(z); }).defaultValue(false);
        return passesRegex ? railway_1.Option.ofNone() : railway_1.Option.ofSome("You must enter a valid ZIP or Postal code.");
    };
    CheckoutPage.prototype.generateHeader = function (forMobile) {
        var _this = this;
        if (forMobile === void 0) { forMobile = false; }
        var currentPage = this.state.page;
        var backToCart = this.props.backToCart;
        var navigate = function (to) { return function (event) {
            event.preventDefault();
            _this.setState({ page: to === "customer" ? page.customerInformation : page.shippingMethod });
        }; };
        var Container = function (props) {
            var output;
            if (forMobile) {
                output = (React.createElement("section", { className: "xs-col-24-24 show-xs hide-sm hide-m hide-l hide-xl", id: "checkout-header" }, props.children));
            }
            else {
                output = (React.createElement("div", { className: "hide-xs show-sm show-m show-l show-xl" },
                    React.createElement("div", { className: "ms-row" },
                        React.createElement("div", { className: "col-1-1" }, props.children))));
            }
            return output;
        };
        function handleBackToCart(e) {
            if (typeof backToCart.onClick === "function") {
                e.preventDefault();
                backToCart.onClick();
            }
        }
        return (React.createElement(Container, null,
            React.createElement("h1", { className: "page-title" }, this.props.siteName),
            React.createElement("ul", { id: "nav" },
                React.createElement("li", null,
                    React.createElement("a", { href: backToCart.url, onClick: function (e) { return handleBackToCart(e); } }, "Cart")),
                React.createElement("li", { className: "chevron" },
                    React.createElement(FontAwesome, { name: "chevron-right", className: "fa-one-rem" })),
                React.createElement("li", { className: classes({ active: currentPage === page.customerInformation }) }, currentPage <= page.customerInformation ? ("Customer Information") : (React.createElement("a", { href: "#", onClick: navigate("customer") }, "Customer Information"))),
                React.createElement("li", { className: "chevron" },
                    React.createElement(FontAwesome, { name: "chevron-right", className: "fa-one-rem" })),
                React.createElement("li", { className: classes({ active: currentPage === page.shippingMethod }) }, currentPage <= page.shippingMethod ? ("Shipping Information") : (React.createElement("a", { href: "#", onClick: navigate("shipping") }, "Shipping Information"))),
                React.createElement("li", { className: "chevron" },
                    React.createElement(FontAwesome, { name: "chevron-right", className: "fa-one-rem" })),
                React.createElement("li", { className: classes({ active: currentPage === page.paymentMethod }) }, "Payment method"))));
    };
    CheckoutPage.prototype.generateCartSummary = function () {
        var _this = this;
        var _a = this.state, loading = _a.loading, error = _a.error, coupons = _a.coupons, discountCode = _a.discountCode, countryCode = _a.shippingAddress.countryCode;
        var setSummaryCode = function (event) {
            var value = event.currentTarget.value;
            _this.setState({ discountCode: value });
        };
        var controls = this.props.allowCoupons === true
            ? railway_1.Option.ofSome(React.createElement("div", null,
                React.createElement("div", { className: "ms-row vc zero-margin discount-form" },
                    React.createElement("div", { className: "xs-col-18-24 form-group", style: { marginBottom: "0px" } },
                        React.createElement("input", { className: "win-textbox", placeholder: "Discount Code", value: discountCode.defaultValue(""), onChange: function (e) { return setSummaryCode(e); } })),
                    React.createElement("div", { className: "xs-col-6-24 text-center" },
                        React.createElement("button", { className: "win-button", onClick: function (e) { return _this.applyDiscount(e); } }, loading ? (React.createElement(FontAwesome, { key: "apply-discount-spinner", name: "spinner", spin: true })) : ("Apply")))),
                error ? React.createElement("p", { className: "error red" }, error) : null,
                React.createElement("hr", null)))
            : railway_1.Option.ofNone();
        return (React.createElement(cart_summary_1.CartSummary, { totals: this.props.totals, shippingTotal: this.state.selectedRate.bind(function (r) { return railway_1.Option.ofSome(r.value); }), coupons: coupons, lineItems: this.props.items, controls: controls, onRemoveDiscount: function (e, c) { return _this.removeDiscount(e, c); } }));
    };
    CheckoutPage.prototype.generateCustomerInformation = function () {
        var _this = this;
        var _a = this.state, email = _a.email, error = _a.error, shippingAddress = _a.shippingAddress;
        return (React.createElement("section", { id: "customer-information" },
            this.generateHeader(),
            React.createElement("form", null,
                React.createElement("div", { className: "form-group" },
                    React.createElement("label", { className: "control-label" }, "Customer information"),
                    React.createElement("div", { className: "form-container ms-row" },
                        React.createElement("div", { className: "xs-col-24-24" },
                            React.createElement("input", { className: "win-textbox", type: "text", placeholder: "Email address", value: email, onChange: this.updateStateFromEvent(function (s, v) { return (s.email = v); }) })))),
                React.createElement("div", { className: "form-group" },
                    React.createElement("label", { className: "control-label" }, "Shipping address"),
                    React.createElement(AddressForm, { type: "shipping", address: shippingAddress, onChange: function (a) { return _this.setState({ shippingAddress: a }); } }))),
            error.map(function (e) { return React.createElement("p", { className: "error red" }, e); }).defaultValue(React.createElement("span", null)),
            React.createElement("div", { className: "ms-row vc zero-margin" },
                React.createElement("div", { className: "xs-col-8-24" },
                    React.createElement("a", { href: "/cart" },
                        React.createElement(FontAwesome, { name: "chevron-left", className: "marRight5 fa-one-rem" }),
                        "Return to cart")),
                React.createElement("div", { className: "xs-col-16-24 text-right" },
                    React.createElement("button", { className: "win-button win-button-primary", onClick: function (e) { return _this.continueToShipping(e); } }, "Continue to shipping method")))));
    };
    CheckoutPage.prototype.generateShippingInformation = function () {
        var _this = this;
        var back = function (event) {
            event.preventDefault();
            _this.setState({ page: page.customerInformation });
        };
        var address = this.state.shippingAddress;
        var selectRate = function (e) {
            var rate = _this.state.rates.find(function (r) { return r.id === e.currentTarget.value; });
            _this.setState({
                selectedRate: rate ? railway_1.Option.ofSome(rate) : railway_1.Option.ofNone()
            });
        };
        return (React.createElement("section", { id: "shipping-information" },
            this.generateHeader(),
            React.createElement("form", null,
                React.createElement("div", { id: "shipping-address", className: "form-group" },
                    React.createElement("label", { className: "control-label" }, "Shipping address"),
                    React.createElement(address_line_1.AddressLine, { address: address },
                        React.createElement("a", { href: "#", onClick: back }, "Edit shipping address"))),
                React.createElement("div", { className: "form-group" },
                    React.createElement("label", { className: "control-label" }, "Shipping method"),
                    React.createElement("div", { id: "shipping-method", className: "ms-row vc zero-margin" }, this.state.rates.map(function (r) { return [
                        React.createElement("div", { key: r.id + "-radio", className: "xs-col-2-24" },
                            React.createElement("input", { type: "radio", className: "win-radio", name: "shipping-method", value: r.id, checked: _this.state.selectedRate.map(function (s) { return s.id; }).defaultValue("") === r.id, onChange: selectRate })),
                        React.createElement("div", { key: r.id + "-name", className: "xs-col-12-24" }, r.name),
                        React.createElement("div", { key: r.id + "-value", className: "xs-col-9-24 text-right" }, r.value === 0 ? "Free" : "$" + r.value.toFixed(2))
                    ]; })))),
            React.createElement("div", { className: "ms-row vc zero-margin" },
                React.createElement("div", { className: "xs-col-12-24" },
                    React.createElement("a", { href: "#", onClick: back },
                        React.createElement(FontAwesome, { name: "chevron-left", className: "marRight5 fa-one-rem" }),
                        "Return to customer information")),
                React.createElement("div", { className: "xs-col-12-24 text-right" },
                    React.createElement("button", { className: "win-button win-button-primary", onClick: function (e) { return _this.continueToPayment(e); } }, "Continue to payment method")))));
    };
    CheckoutPage.prototype.generatePaymentPage = function () {
        var _this = this;
        var _a = this.state, _b = _a.card, number = _b.number, cvv = _b.cvv, expiry = _b.expiry, name = _b.name, error = _a.error, sameBillingAddress = _a.sameBillingAddress, loading = _a.loading, billingAddress = _a.billingAddress;
        var back = function (event) {
            event.preventDefault();
            _this.setState({ page: page.shippingMethod });
        };
        return (React.createElement("section", { id: "payment-method" },
            this.generateHeader(),
            React.createElement("form", null,
                "All transactions are secure and encrypted. Credit card information is never stored.",
                React.createElement("div", { className: "form-group" },
                    React.createElement("label", { className: "control-label" },
                        "Credit card",
                        React.createElement("img", { src: "/resources/images/cards.png", className: "img-responsive pull-right" })),
                    React.createElement("div", { className: "form-container ms-row" },
                        React.createElement("div", { className: "xs-col-24-24" },
                            React.createElement("input", { type: "text", className: "win-textbox", placeholder: "Card number", value: number, onChange: this.updateStateFromEvent(function (s, v) { return (s.card.number = creditcard.formatCardNumber(v)); }) }),
                            React.createElement("div", { className: "ms-row vc" },
                                React.createElement("div", { className: "m-col-12-24" },
                                    React.createElement("input", { type: "text", className: "win-textbox", placeholder: "Name on card", value: name, onChange: this.updateStateFromEvent(function (s, v) { return (s.card.name = v); }) })),
                                React.createElement("div", { className: "m-col-6-24" },
                                    React.createElement("input", { type: "text", className: "win-textbox", placeholder: "MM / YY", value: expiry, onChange: this.updateStateFromEvent(function (s, v) { return (s.card.expiry = cc_expiry_1.format(v)); }) })),
                                React.createElement("div", { className: "m-col-6-24" },
                                    React.createElement("input", { type: "text", className: "win-textbox", placeholder: "CVV", maxLength: 4, value: cvv, onChange: this.updateStateFromEvent(function (s, v) { return (s.card.cvv = v); }) })))))),
                React.createElement("div", { className: "form-group", id: "billing-address" },
                    React.createElement("label", { className: "control-label" }, "Billing address"),
                    React.createElement("div", { className: "ms-row vc cursor-pointer", onClick: this.updateStateFromEvent(function (s) { return (s.sameBillingAddress = true); }) },
                        React.createElement("div", { className: "xs-col-2-24" },
                            React.createElement("input", { type: "radio", name: "sameBillingAddress", className: "win-radio", checked: sameBillingAddress, onChange: this.updateStateFromEvent(function (s) { return (s.sameBillingAddress = true); }) })),
                        React.createElement("div", { className: "xs-col-20-24" }, "Same as shipping address")),
                    React.createElement("div", { className: "ms-row vc cursor-pointer", onClick: this.updateStateFromEvent(function (s) { return (s.sameBillingAddress = false); }) },
                        React.createElement("div", { className: "xs-col-2-24" },
                            React.createElement("input", { type: "radio", name: "sameBillingAddress", className: "win-radio", checked: !sameBillingAddress, onChange: this.updateStateFromEvent(function (s) { return (s.sameBillingAddress = false); }) })),
                        React.createElement("div", { className: "xs-col-20-24" }, "Use a different billing address")),
                    !sameBillingAddress ? null : (React.createElement(AddressForm, { type: "billing", address: billingAddress, onChange: function (a) { return _this.setState({ billingAddress: a }); } })))),
            error.map(function (error) { return React.createElement("p", { className: "error red" }, error); }).defaultValue(React.createElement("span", null)),
            React.createElement("div", { className: "ms-row vc zero-margin" },
                React.createElement("div", { className: "xs-col-12-24" },
                    React.createElement("a", { href: "#", onClick: back },
                        React.createElement(FontAwesome, { name: "chevron-left", className: "marRight5 fa-one-rem" }),
                        "Return to shipping method")),
                React.createElement("div", { className: "xs-col-12-24 text-right" },
                    React.createElement("button", { className: "win-button win-button-primary", onClick: function (e) { return _this.completeOrder(e); } }, loading
                        ? [
                            React.createElement(FontAwesome, { key: "placing-order-spinner", name: "spinner", className: "marRight5", spin: true }),
                            "Placing order"
                        ]
                        : "Complete order")))));
    };
    //#endregion
    //#region Event handlers
    CheckoutPage.prototype.applyDiscount = function (event) {
        event.preventDefault();
        // TODO: Call props.applyDiscount.then((coupon))
    };
    CheckoutPage.prototype.removeDiscount = function (event, coupon) {
        event.preventDefault();
        // TODO: Call props.removeDiscount.then((shouldRemove))
    };
    CheckoutPage.prototype.continueToShipping = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, email, shippingAddress, loading, addressValidation;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        event.preventDefault();
                        _a = this.state, email = _a.email, shippingAddress = _a.shippingAddress, loading = _a.loading;
                        if (loading) {
                            return [2 /*return*/];
                        }
                        if (!email || email.indexOf("@") === -1 || email.indexOf(".") === -1) {
                            this.setState({ error: railway_1.Option.ofSome("You must enter a valid email address.") });
                            return [2 /*return*/];
                        }
                        addressValidation = this.validateAddress(shippingAddress);
                        if (addressValidation.isSome()) {
                            this.setState({ error: addressValidation });
                            return [2 /*return*/];
                        }
                        this.setState({ error: railway_1.Option.ofNone(), loading: true });
                        return [4 /*yield*/, this.props
                                .onCalculateShipping(shippingAddress)
                                .iter(function (rates) {
                                _this.setState({
                                    rates: rates,
                                    selectedRate: rates.length > 0 ? railway_1.Option.ofSome(rates[0]) : railway_1.Option.ofNone(),
                                    page: page.shippingMethod,
                                    loading: false
                                });
                            })
                                .iterError(function (error) {
                                var message = error instanceof Error
                                    ? error.message
                                    : typeof error === "string"
                                        ? error
                                        : "Encountered unknown error: " + error;
                                console.error("onCalculateShipping promise threw an error.", { message: message, error: error });
                                _this.setState({ error: railway_1.Option.ofSome(message), loading: false });
                            })
                                .get()];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    CheckoutPage.prototype.continueToPayment = function (event) {
        event.preventDefault();
        this.setState({ page: page.paymentMethod, loading: false });
    };
    CheckoutPage.prototype.completeOrder = function (event) {
        return __awaiter(this, void 0, void 0, function () {
            var _a, card, loading, sameBillingAddress, billingAddress, shippingAddress, selectedRate, rates, validation, expiry;
            var _this = this;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        event.preventDefault();
                        _a = this.state, card = _a.card, loading = _a.loading, sameBillingAddress = _a.sameBillingAddress, billingAddress = _a.billingAddress, shippingAddress = _a.shippingAddress, selectedRate = _a.selectedRate, rates = _a.rates;
                        if (loading) {
                            return [2 /*return*/];
                        }
                        if (rates.length > 0 && selectedRate.isNone()) {
                            this.setState({ error: railway_1.Option.ofSome("You must select a shipping rate.") });
                            return [2 /*return*/];
                        }
                        if (sameBillingAddress) {
                            validation = this.validateAddress(billingAddress);
                            if (validation.isSome()) {
                                this.setState({ error: validation });
                                return [2 /*return*/];
                            }
                        }
                        expiry = railway_1.compute(function () {
                            var parsed = creditcard.parseCardExpiry(card.expiry || "");
                            return {
                                month: parsed && typeof parsed.month === "number" ? parsed.month : 0,
                                year: parsed && typeof parsed.year === "number" ? parsed.year : 0
                            };
                        });
                        this.setState({
                            error: railway_1.Option.ofNone(),
                            loading: true
                        });
                        return [4 /*yield*/, this.props
                                .onConfirmPayment(card, selectedRate, this.state.coupons, sameBillingAddress ? railway_1.Option.ofSome(billingAddress) : railway_1.Option.ofNone())
                                .iter(function (result) { return window.location.assign(result.url); })
                                .iterError(function (error) {
                                var message = error instanceof Error
                                    ? error.message
                                    : typeof error === "string"
                                        ? error
                                        : "Encountered unknown error: " + error;
                                console.error("onCofirmPayment promise threw an error.", { message: message, error: error });
                                _this.setState({ error: railway_1.Option.ofSome(message), loading: false });
                            })
                                .get()];
                    case 1:
                        _b.sent();
                        return [2 /*return*/];
                }
            });
        });
    };
    //#endregion
    CheckoutPage.prototype.render = function () {
        var currentPage = this.state.page;
        var renderedPage;
        switch (currentPage) {
            default:
            case page.customerInformation:
                renderedPage = this.generateCustomerInformation();
                break;
            case page.shippingMethod:
                renderedPage = this.generateShippingInformation();
                break;
            case page.paymentMethod:
                renderedPage = this.generatePaymentPage();
                break;
        }
        // FontAwesome must be included with the page.
        var fontAwesome = (React.createElement("link", { href: "https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css", rel: "stylesheet" }));
        return (React.createElement("main", { id: "checkout-page", className: "ms-grid" },
            fontAwesome,
            React.createElement("div", { className: "ms-row" },
                this.generateHeader(true),
                this.generateCartSummary(),
                React.createElement("section", { id: "panels", className: "m-col-14-24 m-col-24-pull-10" },
                    React.createElement("div", { className: "ms-row" },
                        React.createElement("div", { className: "m-col-22-24 m-col-24-offset-1" }, renderedPage)),
                    React.createElement("div", { id: "copyright" },
                        React.createElement("p", null, "\u00A9 " + this.props.siteName + ", " + new Date().getUTCFullYear(),
                            React.createElement("a", { href: "mailto:" + this.props.supportEmail, className: "pull-right" }, this.props.supportEmail)))))));
    };
    return CheckoutPage;
}(React.Component));
exports.CheckoutPage = CheckoutPage;
function AddressForm(_a) {
    var type = _a.type, address = _a.address, onChange = _a.onChange;
    /**
     * A function for selecting a new country and performing maintenance on its Zip and StateCode props.
     */
    function updateCountry(iso) {
        var countryData = countries_1.Countries.filter(function (c) { return c.iso === iso; })[0];
        var a = __assign({}, address);
        a.countryCode = iso;
        //If the country has states, select the first one. If not, delete the state
        if (countryData.states.length > 0) {
            a.stateCode = railway_1.Option.ofSome(countryData.states[0].iso);
        }
        else {
            a.stateCode = railway_1.Option.ofNone();
        }
        //If the country doesn't have postal codes, delete it.
        if (countryData.hasPostalCodes) {
            a.zip = a.zip.isSome() ? a.zip : railway_1.Option.ofSome("");
        }
        else {
            a.zip = railway_1.Option.ofNone();
        }
        onChange(a);
    }
    var countryData = countries_1.Countries.filter(function (c) { return c.iso === address.countryCode; })[0];
    var countries = countries_1.Countries.map(function (c) { return (React.createElement("option", { key: c.iso, value: c.iso }, c.name)); });
    var states = countryData.states.map(function (s) { return (React.createElement("option", { key: s.iso, value: s.iso }, s.name)); });
    return (React.createElement("div", { className: "address-form form-container ms-row" },
        React.createElement("div", { className: "xs-col-24-24" },
            React.createElement("input", { className: "win-textbox", type: "text", placeholder: "Name or company name", value: address.name, onChange: function (e) { return onChange(__assign({}, address, { name: e.currentTarget.value })); } }),
            React.createElement("div", { className: "ms-row vc" },
                React.createElement("div", { className: "m-col-16-24" },
                    React.createElement("input", { className: "win-textbox", type: "text", placeholder: "Address", value: address.line1, onChange: function (e) { return onChange(__assign({}, address, { line1: e.currentTarget.value })); } })),
                React.createElement("div", { className: "m-col-8-24" },
                    React.createElement("input", { className: "win-textbox", type: "text", placeholder: "Apt, suite, etc. (optional)", value: address.line2, onChange: function (e) { return onChange(__assign({}, address, { line2: e.currentTarget.value })); } }))),
            React.createElement("div", { className: "ms-row vc" },
                React.createElement("div", { className: "col-1-1" },
                    React.createElement("input", { className: "win-textbox", type: "text", placeholder: "City", value: address.city, onChange: function (e) { return onChange(__assign({}, address, { city: e.currentTarget.value })); } }))),
            React.createElement("div", { className: "ms-row vc" },
                React.createElement("div", { className: "m-col-" + (states && states.length > 0 ? "8" : countryData.hasPostalCodes ? "12" : "24") + "-24" },
                    React.createElement("select", { className: "win-select", value: address.countryCode, onChange: function (e) { return updateCountry(e.currentTarget.value); } }, countries)),
                !states || states.length === 0 ? null : (React.createElement("div", { className: "m-col-8-24" },
                    React.createElement("select", { className: "win-select", value: address.stateCode.defaultValue(countryData.states[0].iso), onChange: function (e) {
                            return onChange(__assign({}, address, { stateCode: railway_1.Option.ofSome(e.currentTarget.value) }));
                        } }, states))),
                !countryData.hasPostalCodes ? null : (React.createElement("div", { className: "m-col-" + (states && states.length > 0 ? "8" : "12") + "-24" },
                    React.createElement("input", { className: "win-textbox", type: "text", placeholder: "Postal code", value: address.zip.defaultValue(""), onChange: function (e) { return onChange(__assign({}, address, { zip: railway_1.Option.ofSome(e.currentTarget.value) })); } })))))));
}
