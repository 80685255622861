import * as React from "react";
import * as qs from "querystring";
import * as Stores from "../../stores";
import Box from "../../components/box";
import { CONSTANTS } from "client/constants";
import { MdCheck as Check } from "react-icons/md";

type Props = {};

export function ConfirmedPanel(_: Props): JSX.Element {
    const query = qs.parse((window.location.search || "").substring(1)) as {
        display_id: number;
        email: string;
        requestedEmail: string | boolean;
    };
    const requestedEmail =
        query.requestedEmail === true || query.requestedEmail === "true" || query.requestedEmail === "True";
    const emailMessage = !requestedEmail
        ? `You did not request a confirmation email.`
        : `You requested a confirmation email; it will be delivered to your inbox at ${query.email} in a few moments.`;

    return (
        <section className="padder">
            <section id="cart" className="pure-g center-children">
                <div className="pure-u-1-1 pure-u-lg-18-24">
                    <Box title={`Your order has been placed!`}>
                        <div className="text-center" style={{ padding: "5rem 0" }}>
                            <Check size={100} color={CONSTANTS.BLUE_GREY} />
                        </div>
                        <p className="text-center">{`For your reference, your Order ID is KMMP-${query.display_id}. ${emailMessage}`}</p>
                        {requestedEmail && !Stores.Dev.email_confirmations_enabled ? (
                            <p className="error">{`Warning: Email confirmations are temporarily disabled while the app is in preview mode.`}</p>
                        ) : null}
                        <p className="text-center">
                            {`Note that we do not make callbacks for most orders placed online. We will email you an order approval once your order is processed, or we will call if there is a problem. If you do not receive any contact from us within an hour (on a business day) please call to verify we received your order!`}
                        </p>
                    </Box>
                </div>
            </section>
        </section>
    );
}
