import * as React from "react";
import { If } from "shared/components";
import { useWhitelabel } from "client/hooks";
import PageHeader from "client/components/page-header";

export default function Guide(props: React.Props<any>) {
    const whitelabel = useWhitelabel();

    return (
        <div id="guide">
            <PageHeader title={`Information & Guide`} />
            <div className="section white">
                <div className="pure-g center-children long-form">
                    <div className="pure-u-20-24">
                        <h4>{"Deadlines"}</h4>
                        <img className="pull-right" src="/public/guide/clock.png" alt="Image of clock" />
                        <p>
                            {
                                "Orders received by 2 PM Central Standard Time will normally ship on the same day. If you request a proof, it must be approved before 3 PM Central Standard Time or your order will not be shipped on the same day. To approve your proof, you must reply to the email you receive. An approval by phone would also be appreciated, in case the email is slow or doesn't arrive."
                            }
                        </p>
                        <p>
                            {
                                "Saturday orders must be received by 9:30 AM Central Standard Time if you want it to ship on the same day. We do not do proofs for any orders that will ship on Saturday."
                            }
                        </p>
                        <p>
                            {
                                "We work on orders in the order we receive them and all of the necessary parts: order, purchase order, and an acceptable photo. A proof for a portrait can add delays and you may receive it late in the day, which could delay your order going out on the same day."
                            }
                        </p>
                    </div>

                    <div className="pure-u-20-24">
                        <h4>{"Portrait"}</h4>
                        <img
                            className="img img-responsive pull-right"
                            src="/public/guide/portrait.png"
                            alt="Image of clock"
                        />
                        <p>
                            {
                                "When we think of a portrait, it's usually from the bust up. We'll typically remove the background from the image and replace it with a portrait-style background. If you want to use more of the photo, such as down to the waist, or the entire photo, please put that information in the instructions when you upload the image."
                            }
                        </p>
                        <p>
                            <strong>{'Please, do not use the word "crop"!'}</strong>
                            {
                                ' Instead, you can say something like "use the man in the tan shirt" or "use the couple". Asking us to crop a photo can cause confusion as to which person is supposed to be in the portrait after it\'s cropped.'
                            }
                        </p>
                    </div>

                    <If condition={false}>
                        {/* This is temporarily hidden until we get approval to show it. */}
                        <div className="pure-u-20-24">
                            <h4>{"Notifications"}</h4>
                            <p>
                                {
                                    "All notifications will be by email, as phone calls and messages have historically been unreliable."
                                }
                            </p>
                            <p>
                                {
                                    "Once your order is received, we will verify the order, the purchase order (PO) number, and the photo. If everything is good you will receive an email stating when your order will be worked on, following the instructions on the order."
                                }
                            </p>
                            <p>
                                <strong>
                                    {
                                        "If we are missing a new active PO you will be contacted for it by email. If there is a problem with your photograph you will be contacted for a photo with a higher resolution or a new photo altogether."
                                    }
                                </strong>
                            </p>
                            <p>
                                <strong>{"Note: "}</strong>
                                {
                                    "If you do not get a notification within two hours after sending your order, don’t hesitate to call and make sure we have received your order!"
                                }
                            </p>
                        </div>
                    </If>

                    <div className="pure-u-20-24">
                        <h4>{"Definitions"}</h4>
                        <img
                            className="img img-responsive"
                            src="/public/guide/swatch-thumbnail-proof.jpg"
                            alt="Swatch, thumbnail and proof image"
                        />
                        <p>
                            <strong>{"Swatch: "}</strong>
                            {
                                "A small portion of a portrait size picture sent to you to show the quality of a photo at portrait size. The reason for sending a small section of the photo is for ease of sending it by email. Sending the entire photo at portrait size by email would cause problems."
                            }
                        </p>
                        <p>
                            <strong>{"Thumbnail: "}</strong>
                            {
                                "A smaller version of the portrait without the frame sent to be used on printed material at the funeral home."
                            }
                        </p>
                        <p>
                            <strong>{"Proof: "}</strong>
                            {
                                "A small version of the portrait used to show how a portrait looks with a frame. It can also show how an artist removed, repaired or changed the composition of a photo. A proof can also show color correction. "
                            }
                            <strong>{"Note: "}</strong>
                            {
                                "A proof does not work for portrait quality! It is best to ask for a swatch if you are concerned about the quality of a photo."
                            }
                        </p>
                    </div>
                    <div className="pure-u-20-24">
                        <h4>{"Shipping"}</h4>
                        <img
                            className="img img-responsive pull-right"
                            src="/public/guide/ups-truck.jpeg"
                            alt="UPS shipping vehicle image"
                        />
                        <p>
                            <strong>{"Tracking numbers "}</strong>
                            {"are sent out each night to the email address you entered on each order."}
                        </p>
                        <If condition={whitelabel.companyName === "sci"}>
                            <p>
                                <strong>{"UPS: "}</strong>
                                {
                                    "SCI Corporate has chosen UPS as the official carrier of Memory Portraits. Normally they are on time and very reliable. Weather and COVID-19 has made shipping more of challenge in recent months. Unfortunately they do not guarantee delivery times as they did in the past."
                                }
                            </p>
                            <p>
                                {
                                    "We do our best to get your portraits out the door and to you on time. It is extremely important to enter an accurate date and time for when you need your portrait so we can adjust shipping to get it to you on time. That being said we don’t have any control once it leaves our facility."
                                }
                            </p>
                        </If>
                        <p>
                            <strong>{"Calculating shipping times: "}</strong>
                            {
                                " To properly estimate shipping times, do not count the day your package is being shipped. For example: if a package is shipped on a Monday using 2-Day Air shipping, the Monday itself should not be counted as one of those two days. Your package would actually arrive on Wednesday."
                            }
                        </p>
                        <p>
                            {
                                "If a portrait gets shipped overnight on Friday, it should arrive on Monday. If you need a portrait delivered on Saturday, be sure to check the Saturday Delivery option when completing your order."
                            }
                        </p>
                        <p>
                            {
                                "We try to highlight any irregular shipping schedules such as holidays or weather events on the home page of this website."
                            }
                        </p>
                    </div>

                    <div className="pure-u-20-24">
                        <h4>{"Good portraits start with a good scan"}</h4>
                        <p>
                            {"All photos may be scanned at 100% "}
                            <span className="letter">{"[B]"}</span>
                            {". The resolution should be changed according to photo size listed below "}
                            <span className="letter">{"[C]"}</span>
                            {":"}
                        </p>
                        <ul>
                            <li>{'8" x 10" - Scan at 200 DPI.'}</li>
                            <li>{'5" x 7" - Scan at 400 DPI.'}</li>
                            <li>{'4" x 6" - Scan at 500 DPI.'}</li>
                            <li>{'2" x 3" - Scan at 800 DPI.'}</li>
                            <li>{'1" x 2" - Scan at 1200 DPI.'}</li>
                        </ul>
                        <p>
                            {
                                "Save your scanned photo as a jpeg (or jpg) file. When finished your scan should be between 750KB and 3MB in size. You can check this by right clicking the file and looking in the properties box."
                            }
                        </p>
                        <p>
                            {"For a 16x20 canvas portrait print, we recommend the following "}
                            <strong>{"minimum pixel dimensions:"}</strong>
                        </p>
                        <ul>
                            <li>{"1600 pixels by 2400 pixels - Vertical Print"}</li>
                            <li>{"2400 pixels by 1600 pixels - Horizontal (landscape) Print"}</li>
                        </ul>
                        <p>
                            {"In addition, we recommend the following pixel dimensions for the "}
                            <strong>{"highest-quality print"}</strong>:
                        </p>
                        <ul>
                            <li>{"2400 pixels by 3000 pixels - Vertical Print"}</li>
                            <li>{"3000 pixels by 2400 pixels - Horizontal (landscape) Print"}</li>
                        </ul>
                        <p>
                            <strong>{"Note:"}</strong>
                            {
                                "if you are using a photograph from a professional studio, you will need to obtain permission from the photographer to duplicate the photo. We are not responsible for this permission."
                            }
                        </p>
                    </div>

                    <div className="pure-u-1-1">
                        <hr />
                        <div className="full-image">
                            <img src="/public/scanning/5x7_sample.png" className="img-responsive" />
                        </div>
                    </div>

                    <div className="pure-u-20-24">
                        <p>
                            {
                                'The picture on the left is a 5" by 7" picture, and therefore should be scanned at 400 DPI.  The picture in the middle is a 5" by 7" picture, however we are only using part of the man, and therefore we need to scan at 500-600 DPI. Finally, the picture on the right is 5" by 7" picture, but we\'re using only part of the woman and therefore should be scanned at 800 DPI.'
                            }
                        </p>
                    </div>

                    <div className="pure-u-20-24">
                        <h4>{"Digital camera and cell phone pictures"}</h4>
                        <iframe
                            className="pull-right youtube-embed"
                            src="https://www.youtube.com/embed/6rP5j8yuvL0"
                            title="YouTube video player"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        ></iframe>
                        <p>
                            {
                                "Digital photographs will make great portraits. Unfortunately, many people leave their digital camera and the camera on their cell phone set for a smaller resolution because it is faster. Please request cell phone photos to be sent full resolution or actual size."
                            }
                        </p>
                        <p>
                            {
                                "When we receive a digital photo with a resolution that is too small, we can do nothing to improve the photo. We understand that being contacted by us for a new or higher-resolution picture can be frustrating, but we do it because we want the portrait to look the best it can when you present it to the family."
                            }
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
