import * as React from "react";

interface Props extends React.Props<any> {
    forSignaturePortraits: boolean;
    name: string;
    date1: string;
    date2: string;
    sentiment: string;
    onNameChanged: (newValue: string) => void;
    onDate1Changed: (newValue: string) => void;
    onDate2Changed: (newValue: string) => void;
    onSentimentChanged: (newValue: string) => void;
}

type CallOnChangeFn = (fn: (newValue: string) => void) => (event: React.FormEvent<HTMLInputElement>) => void;

export function EngravingControls(props: Props): JSX.Element {
    const callOnChange: CallOnChangeFn = (fn) => (event) => fn(event.currentTarget.value);
    // By request, the signature portraits should not show "Engraving" because it's technically not an engraving
    const labelPrefix = props.forSignaturePortraits ? "" : "Engraving";

    return (
        <div>
            <div className="selector-container">
                <p>
                    {labelPrefix + " Name"}
                    <small>{" (Optional)"}</small>
                </p>
                <input
                    className="form-control selector"
                    value={props.name}
                    onChange={callOnChange(props.onNameChanged)}
                />
            </div>
            <div className="selector-container">
                <p>
                    {labelPrefix + " Date 1"}
                    <small>{" (Optional)"}</small>
                </p>
                <input
                    className="form-control selector"
                    value={props.date1}
                    onChange={callOnChange(props.onDate1Changed)}
                />
            </div>
            <div className="selector-container">
                <p>
                    {labelPrefix + " Date 2"}
                    <small>{" (Optional)"}</small>
                </p>
                <input
                    className="form-control selector"
                    value={props.date2}
                    onChange={callOnChange(props.onDate2Changed)}
                />
            </div>
            <div className="selector-container">
                <p>
                    {labelPrefix + " Sentiment"}
                    <small>{" (Optional, limit 30 characters)"}</small>
                </p>
                <input
                    className="form-control selector"
                    value={props.sentiment}
                    onChange={callOnChange(props.onSentimentChanged)}
                    placeholder={"Showcasing the life of"}
                />
            </div>
        </div>
    );
}
