import * as React from "react";

interface Props extends React.Props<any> {
    onEnable: () => void;
    onDisable: () => void;
    enabled: boolean;
}

export function ToggleCropperControls({ onEnable, onDisable, enabled }: Props): JSX.Element {
    const toggle = (evt: React.FormEvent<HTMLInputElement>) => {
        if (evt.currentTarget.checked) {
            onEnable();
        } else {
            onDisable();
        }
    };

    return (
        <div className="toggle-cropper-controls">
            <label>
                <input type="checkbox" checked={enabled} onChange={toggle} />
                Crop this image to show the artists what you expect the portrait to look like?
            </label>
        </div>
    );
}
