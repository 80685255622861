import * as React from "react";
import { MdDelete as TrashIcon } from "react-icons/md";
import { CONSTANTS } from "client/constants";

export interface Props extends React.Props<any> {
    onDelete: () => void;
}

export function DeleteSelectedImageButton({ onDelete }: Props): JSX.Element {
    const onClick = (evt: React.MouseEvent<any>) => {
        evt.preventDefault();
        onDelete();
    };

    return (
        <div className="delete">
            <TrashIcon
                size={`1.5em`}
                color={CONSTANTS.BLUE_GREY}
                title={`Delete this image.`}
                className={`pointer`}
                onClick={onClick}
            />
        </div>
    );
}
