import * as React from "react";
import * as Frontend from "kmmp/frontend";
import classes = require("classnames");

export interface Props extends React.Props<any> {
    image: Frontend.PreviewImage;
    onSelected: () => void;
}

export function ImageSidebarPreview({ image, onSelected }: Props) {
    const selectImage = (e: React.MouseEvent<any>) => {
        e.preventDefault();
        onSelected();
    };

    return (
        <img
            onClick={selectImage}
            className={classes("img-responsive pointer", { tooSmall: image.tooSmall })}
            title={image.tooSmall ? "This image is too small." : undefined}
            src={(image.croppedImage && image.croppedImage.dataUrl) || image.scaledImage.dataUrl}
        />
    );
}
