import * as React from "react";
import classes = require("classnames");
import ga = require("ga");

interface Props extends React.Props<any> {
    height: number;
    width: number;
    requiredHeight: number;
    requiredWidth: number;
    onAcceptWarning: () => void;
    onDelete: () => void;
}

export class TooSmallWarning extends React.Component<Props, never> {
    private acceptImageSizeWarning: (e: React.MouseEvent<any>) => void = (e) => {
        e.preventDefault();
        this.props.onAcceptWarning();
    };

    private deleteImage: (e: React.MouseEvent<any>) => void = (e) => {
        e.preventDefault();
        this.props.onDelete();
    };

    componentDidMount() {
        // Record that the user has seen this warning
        ga("send", "event", {
            eventCategory: "Image",
            eventAction: "shown-too-small",
        });
    }

    render(): JSX.Element {
        const props = this.props;
        // Do some funky math to figure out how "bad" this image is
        // Formula: (actual height + actual width) / (expected height + expected width) * 100
        const totalExpected = props.requiredWidth + props.requiredHeight;
        const totalActual = props.width + props.height;
        const percentage = Math.floor((totalActual / totalExpected) * 100);
        const gridOffset = 100 - percentage;

        const activeBetween = (topRange: number, bottomRange: number) =>
            classes({
                active: percentage <= topRange && percentage > bottomRange,
            });

        return (
            <div className="too-small-warning">
                <div className="scale-container">
                    <div
                        className={"percentage grid-" + gridOffset}
                        title={"Your image is at " + percentage + "% of our recommended image size."}
                    >
                        <span className="percentage-text">{percentage + "%"}</span>
                        <span className="percentage-arrow">{"→"}</span>
                    </div>
                    <img className="scale" src="/public/images/image-scale/scale-gradient-bar.png" />
                    <img className={"finger grid-" + gridOffset} src="/public/images/image-scale/scale-finger.png" />
                    <div className="marks-container">
                        <div className="mark">
                            <ul className={activeBetween(100, 75)}>
                                {/* 100% - 75% */}
                                <li>{"Photo will probably work."}</li>
                                <li>{"Portrait would still be improved with higher resolution photo."}</li>
                                <li>{"Portrait may be slightly blurry."}</li>
                            </ul>
                        </div>
                        <div className="mark">
                            <ul className={activeBetween(75, 50)}>
                                {/* 75% - 50% */}
                                <li>{"Photo may work."}</li>
                                <li>{"Will have some blurriness and be pixelated."}</li>
                                <li>{"Portrait would be greatly improved with higher resolution file."}</li>
                                <li>{"You may receive a call asking for better photo."}</li>
                            </ul>
                        </div>
                        <div className="mark">
                            <ul className={activeBetween(50, 25)}>
                                {/* 50% - 25% */}
                                <li>{"Poor chance of photo working and creating a good portrait."}</li>
                                <li>{"Portrait will have a great deal of blurriness and be pixelated."}</li>
                                <li>{"You will probably receive a call asking for a better photo."}</li>
                            </ul>
                        </div>
                        <div className="mark">
                            <ul className={activeBetween(25, -1)}>
                                {/* 25% - 0% */}
                                <li>{"Photo will create a bad portrait."}</li>
                                <li>{"Portrait will have a great deal of blurriness and be pixelated."}</li>
                                <li>{"Portrait may be unrecognizable when done."}</li>
                                <li>{"You will receive a call asking for a better photo"}</li>
                            </ul>
                        </div>
                    </div>
                    <div className="description">
                        <h3>{"⚠ Warning: your image may be too small."}</h3>
                        <p>
                            {`It looks like the image you've selected is below our recommended image resolution. Its dimensions are ${props.width}x${props.height}, but we recommend ${props.requiredWidth}x${props.requiredHeight}, which puts your image's dimensions at ${percentage}% of the recommended image size.`}
                        </p>
                        <p>
                            {`You should rescan this image at a higher resolution, or you can upload a different photo. You may also continue with the image you've selected, but you may be contacted by our customer service department.`}
                        </p>
                    </div>
                </div>
                <div className="controls">
                    <button onClick={this.deleteImage} className="btn">
                        {"Replace Image"}
                    </button>
                    <button onClick={this.acceptImageSizeWarning} className="btn">
                        {"Use Image"}
                    </button>
                </div>
            </div>
        );
    }
}
