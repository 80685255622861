import * as React from "react";
import { useLoadingState } from "client/hooks/use-loading-state";
import { useUnauthorizedPrompt } from "client/hooks/use-unauthorized-prompt";
import { AnnouncementsClient } from "client/http/announcements";
import { Auth } from "client/stores";
import { Announcement } from "kmmp";
import { ApiError } from "client/http";
import { useLocation } from "wouter";
import { LoadableList } from "client/components/loadable-list";
import Paths from "shared/paths";
import PageHeader from "client/components/page-header";
import { formatDateString } from "shared/dates";
import { EditDialog } from "./edit-dialog";
import { CreateOrModifyAnnouncement } from "api";

interface Props {
    page: number;
}

interface DialogState {
    open: boolean;
    announcementBeingEdited: Announcement | null;
}

const LIMIT_PER_PAGE = 50;

export function AnnouncementsPage(props: Props): JSX.Element {
    const client = React.useMemo(() => new AnnouncementsClient(Auth.token.value), []);
    const handleUnauthorized = useUnauthorizedPrompt();
    const [_, redirect] = useLocation();
    const [totalPages, setTotalPages] = React.useState(1);
    const [announcements, setAnnouncements] = React.useState<Announcement[]>([]);
    const [dialogState, setDialogState] = React.useState<DialogState>({
        open: false,
        announcementBeingEdited: null,
    });
    const [{ loading, error }, setLoadingState] = useLoadingState(true);

    // Use an effect to load the orders for this page
    React.useEffect(() => {
        setLoadingState(true);

        const load = async () => {
            try {
                const result = await client.listAnnouncements({
                    includeExpired: true,
                    page: props.page,
                    limit: LIMIT_PER_PAGE,
                });

                setAnnouncements(result.announcements);
                setTotalPages(result.totalPages);
                setLoadingState(false);
            } catch (e) {
                console.error("Failed to load announcents:", e);

                if (e instanceof ApiError) {
                    if (e.unauthorized && handleUnauthorized(window.location.pathname)) {
                        return;
                    }

                    setLoadingState(e.message);
                } else if (e instanceof Error) {
                    setLoadingState(e.message);
                } else {
                    setLoadingState("Something went wrong and the orders could not be loaded.");
                }
            }
        };

        load();
    }, [props.page]);

    function navigateToPage(newPage: number) {
        redirect(Paths.admin.announcements + "?page=" + newPage);
    }

    function closeDialog() {
        setDialogState({
            open: false,
            announcementBeingEdited: null,
        });
    }

    function updateAnnouncement(update: CreateOrModifyAnnouncement.Response) {
        const updatedAnnouncements = [...announcements];
        const existingAnnouncementIndex = updatedAnnouncements.findIndex((a) => a._id === update.id);

        if (existingAnnouncementIndex >= 0) {
            updatedAnnouncements.splice(existingAnnouncementIndex, 1, update.announcement);
        } else {
            updatedAnnouncements.push(update.announcement);
        }

        setAnnouncements(updatedAnnouncements);
        setDialogState({
            open: false,
            announcementBeingEdited: null,
        });
    }

    function maybeFormatDate(date: string | number | Date | false): string {
        if (!date) {
            return "(none)";
        }

        if (typeof date === "string") {
            date = Date.parse(date);
        }

        return formatDateString(date);
    }

    function openEditDialog(announcement?: Announcement) {
        return function open(e: React.MouseEvent) {
            e.preventDefault();
            setDialogState({
                open: true,
                announcementBeingEdited: announcement ?? null,
            });
        };
    }

    function confirmDelete(announcement: Announcement) {
        return async function promptConfirmation(e: React.MouseEvent) {
            e.preventDefault();

            if (confirm("Are you sure you want to delete this announcement? This cannot be undone.")) {
                // Remove the announcement from the list
                const updatedAnnouncements = [...announcements].filter((a) => a._id !== announcement._id);

                setAnnouncements(updatedAnnouncements);

                // Delete the announcement
                try {
                    await client.deleteAnnouncement(announcement._id!, announcement._rev!);
                } catch (e) {
                    console.error("Failed to delete announcement:", e);
                }
            }
        };
    }

    function List() {
        const baseProps = {
            totalPages,
            page: props.page,
            onSelectPage: navigateToPage,
            columnLabels: ["Start Date", "Announcement Title", "Type", "Expiration", "Actions"],
        };

        if (loading) {
            return <LoadableList loading={true} {...baseProps} />;
        }

        if (!loading && error) {
            return <LoadableList loading={false} ok={false} message={error} {...baseProps} />;
        }

        return (
            <React.Fragment>
                <div className="text-right">
                    <button id="new-user-button" className="btn blue pull-right" onClick={openEditDialog()}>
                        {"Create Announcement"}
                    </button>
                </div>
                <LoadableList loading={false} ok={true} items={announcements} {...baseProps}>
                    {(announcement, index) => (
                        <tr key={announcement._id ?? index}>
                            <td>{maybeFormatDate(announcement.timestamp)}</td>
                            <td>{announcement.type === "holiday" ? announcement.name : announcement.title}</td>
                            <td>{announcement.type === "holiday" ? "Holiday" : "News Announcement"}</td>
                            <td>{maybeFormatDate(announcement.expiration)}</td>
                            <td>
                                <a href="#" onClick={openEditDialog(announcement)}>
                                    {"Edit"}
                                </a>
                                {" · "}
                                <a href="#" onClick={confirmDelete(announcement)}>
                                    {"Delete"}
                                </a>
                            </td>
                        </tr>
                    )}
                </LoadableList>
            </React.Fragment>
        );
    }

    return (
        <div id="announcements" className="admin">
            <PageHeader title={"Announcements and Holidays"} />
            <EditDialog
                open={dialogState.open}
                announcement={dialogState.announcementBeingEdited}
                onClose={closeDialog}
                onAnnouncementSaved={updateAnnouncement}
            />
            <section className="section white">
                <div className="pure-g center-children">
                    <div className="pure-u-1-1 pure-u-md-20-24">
                        <List />
                    </div>
                </div>
            </section>
        </div>
    );
}
