import * as React from "react";
import { WhitelabelSettings } from "kmmp";
import { ShadowBoxColor } from "kmmp/frontend";

interface IProps extends React.Props<any> {
    box: ShadowBoxColor;
    whitelabelSettings: WhitelabelSettings;
    displayAsPackage: boolean;
    src: string;
}

export function ShadowBoxPreview(props: IProps): JSX.Element {
    if (props.displayAsPackage) {
        //const src = Images.findFancyFrame(companyName, subjectType, frame, background, orientation);
        return (
            <img className="img-responsive" style={{ objectFit: "contain" }} src={props.src} alt={"Package preview"} />
        );
    }

    return <img className="img-responsive" style={{ objectFit: "contain" }} src={props.src} alt={"Package preview"} />;
}
