import * as blobber from "image-blobber";
import * as React from "react";
import * as Frontend from "kmmp/frontend";
import { Dev } from "client/stores";
import { ImageUploadControls, useState } from "client/hooks";
import { BlobUtility } from "client/modules/blobs";
import { Orientation } from "kmmp";
import { v4 as uuid } from "uuid";
import { EmptyImageForm } from "client/components/image-editor/empty-image-form";
import { SelectedImageForm } from "client/components/image-editor/selected-image-form";
import { TooSmallWarning } from "client/components/image-editor/too-small-warning";
import { compute } from "@nozzlegear/railway";

type Props = {
    orientation: Orientation;
    onRequestHideInteractions: () => void;
    onRequestShowInteractions: () => void;
    controls: ImageUploadControls;
    allImages: Frontend.PreviewImage[];
    isUploading: boolean;
};

interface State {
    selectedImageId: string | null;
}

export function ImageEditor(props: Props): JSX.Element {
    const filePicker = React.useRef<HTMLInputElement | null>(null);
    const blobUtility = React.useMemo(() => new BlobUtility(), []);
    const [state, setState] = useState<State>({
        selectedImageId: null,
    });
    const selectedImage = React.useMemo(() => {
        return props.allImages.find((i) => i.uuid === state.selectedImageId) || null;
    }, [state.selectedImageId, props.allImages]);

    const getSizeDetails = (
        size: blobber.Dimensions,
        orientation: Orientation
    ): { tooSmall: boolean; sizeDetails: Frontend.PreviewImageSizeDetails } => {
        let reqHeight = 950;
        let reqWidth = 1180;

        if (/^(portrait|vertical)$/i.test(orientation)) {
            reqHeight = 1180;
            reqWidth = 950;
        }

        return {
            tooSmall: size.height < reqHeight || size.width < reqWidth,
            sizeDetails: {
                height: size.height,
                width: size.width,
                requiredHeight: reqHeight,
                requiredWidth: reqWidth,
                acceptedImageSizeWarning: false,
            },
        };
    };

    const bytesToMegabytes = (size: number) => {
        return size / 1024 / 1024;
    };

    const isTooLarge = (file: File) => {
        return bytesToMegabytes(file.size) > 30;
    };

    const cropImage = (image: Frontend.PreviewImage) => (blob: Blob) => props.controls.setCroppedData(image.uuid, blob);

    const revertCrop = (image: Frontend.PreviewImage) => () => props.controls.revertCrop(image.uuid);

    const selectImage = (image: Frontend.PreviewImage | string) =>
        setState({
            selectedImageId: typeof image === "string" ? image : image.uuid,
        });

    const deleteImage = (image: Frontend.PreviewImage) => () => {
        props.controls.removeImage(image.uuid);
        props.onRequestShowInteractions();
    };

    const setNotes = (image: Frontend.PreviewImage) => (notes: string) => props.controls.setNotes(image.uuid, notes);

    const acceptImageSizeWarning = (image: Frontend.PreviewImage) => () => {
        props.controls.acceptImageSizeWarning(image.uuid);
        props.onRequestShowInteractions();
    };

    const openFilepicker = () => {
        if (props.isUploading) {
            return;
        }

        if (!filePicker.current) {
            throw new Error("Attempted to open file picker, but file picker ref is null.");
        }

        //Click the file picker to open it
        filePicker.current.click();
    };

    /**
     * Converts the file input's image file to a blob and stores it in the `state.previewImages` array.
     */
    const blobImage = (event: React.FormEvent<HTMLInputElement>) => {
        const input = event.currentTarget;

        if (!input.files || input.files.length === 0) {
            throw new Error("Attempted to blob an image, but the HTML input has no files selected.");
        }

        const file = input.files[0];
        const img = new Image();
        const maxWidth = 400;
        const maxHeight = 400;

        img.onload = async () => {
            const imageWidth = img.width;
            const imageHeight = img.height;
            const scale = Math.min(maxWidth / imageWidth, maxHeight / imageHeight);
            const scaledWidth = imageWidth * scale;
            const scaledHeight = imageHeight * scale;
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");

            // TODO: Validate image dimensions
            const { tooSmall, sizeDetails } = getSizeDetails(
                {
                    height: imageHeight,
                    width: imageWidth,
                },
                props.orientation
            );

            canvas.width = scaledWidth;
            canvas.height = scaledHeight;

            ctx!.drawImage(img, 0, 0, scaledWidth, scaledHeight);

            const scaledBlob = await blobUtility.getBlobFromCanvas(canvas);
            const imageUuid = uuid();

            props.controls.addImage({
                uuid: imageUuid,
                fileName: file.name,
                fullImage: {
                    file: file,
                    dimensions: {
                        height: imageHeight,
                        width: imageWidth,
                    },
                    filename: file.name,
                },
                scaledImage: {
                    blob: scaledBlob,
                    dataUrl: URL.createObjectURL(scaledBlob),
                    dimensions: {
                        height: scaledHeight,
                        width: scaledWidth,
                    },
                },
                tooSmall: tooSmall,
                sizeDetails: {
                    height: sizeDetails.height,
                    width: sizeDetails.width,
                    requiredHeight: sizeDetails.requiredHeight,
                    requiredWidth: sizeDetails.requiredWidth,
                    acceptedImageSizeWarning: false,
                },
                croppedImage: null,
                instructions: null,
                uploaded: false,
            });

            selectImage(imageUuid);

            if (tooSmall === true) {
                props.onRequestHideInteractions();
            }
        };

        img.src = URL.createObjectURL(file);
        input.value = "";
    };

    const { orientation } = props;
    const body: JSX.Element = compute<JSX.Element>(() => {
        if (props.allImages.length === 0 || selectedImage === null) {
            return <EmptyImageForm onOpenFilePicker={openFilepicker} />;
        }

        const selected = selectedImage;

        if (selected.tooSmall && selected.sizeDetails && !selected.sizeDetails.acceptedImageSizeWarning) {
            const { height, width, requiredHeight, requiredWidth } = selected.sizeDetails;

            return (
                <TooSmallWarning
                    height={height}
                    width={width}
                    requiredHeight={requiredHeight}
                    requiredWidth={requiredWidth}
                    onAcceptWarning={acceptImageSizeWarning(selected)}
                    onDelete={deleteImage(selected)}
                />
            );
        }

        return (
            <SelectedImageForm
                orientation={orientation}
                allowMultipleImages={Dev.allow_multiple_images}
                isUploading={props.isUploading}
                images={props.allImages}
                selectedImage={selected}
                openFilePicker={openFilepicker}
                onSelectImage={selectImage}
                onCrop={cropImage(selected)}
                onDeleteImage={deleteImage(selected)}
                onRevertCrop={revertCrop(selected)}
                onSetNotes={setNotes(selected)}
            />
        );
    });

    return (
        <div id="upload-form">
            {body}
            <input
                type="file"
                accept={".jpg, .jpeg, .png, .gif, .tif, .tiff"}
                className="hidden"
                ref={filePicker}
                onChange={blobImage}
            />
        </div>
    );
}
