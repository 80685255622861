import * as React from "react";
import * as Frontend from "kmmp/frontend";
import { ToggleCropperControls } from "./toggle-cropper-controls";
import { Observer } from "mobx-react";
import { DeleteSelectedImageButton } from "./delete-selected-image-button";

interface Props extends React.Props<any> {
    onDeleteImage: () => void;
    onEnableCrop: () => void;
    onSetNotes: (notes: string) => void;
    image: Frontend.PreviewImage;
}

export function CropDisabledForm({ image, onSetNotes, onEnableCrop, onDeleteImage }: Props): JSX.Element {
    const setNotes = (evt: React.FormEvent<HTMLTextAreaElement>) => onSetNotes(evt.currentTarget.value);

    return (
        <Observer>
            {() => (
                <div className="cropper-disabled">
                    <DeleteSelectedImageButton onDelete={onDeleteImage} />
                    <div className="selected-image">
                        <img src={image.scaledImage.dataUrl} alt={image.fullImage.filename} />
                    </div>
                    <div className="controls">
                        <label>Special instructions for this image:</label>
                        <textarea
                            rows={6}
                            value={image.instructions || ""}
                            onChange={setNotes}
                            placeholder="Enter notes or instructions for this image."
                        />
                    </div>
                    {/*<ToggleCropperControls enabled={false} onEnable={onEnableCrop} onDisable={() => {}} />*/}
                </div>
            )}
        </Observer>
    );
}
