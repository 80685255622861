"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || Object.assign || function(t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
            t[p] = s[p];
    }
    return t;
};
var React = require("react");
var Dialog = (function (_super) {
    __extends(Dialog, _super);
    function Dialog(props, context) {
        var _this = _super.call(this, props, context) || this;
        _this.svg_style = {
            animation: 'rotate 2s linear infinite',
            height: '100%',
            transformOrigin: 'center center',
            width: '100%',
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto'
        };
        return _this;
    }
    /**
     * Creates a unique guid. Based off of https://stackoverflow.com/a/2117523.
     */
    Dialog.prototype.createGuid = function () {
        var input = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';
        return input.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0;
            var v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    };
    Dialog.prototype.getCircleStyle = function (id) {
        var style = {
            strokeDasharray: '1,200',
            strokeDashoffset: '0',
            animation: "dash 1.5s ease-in-out infinite, color-" + id + " 6s ease-in-out infinite",
            strokeLinecap: 'round'
        };
        return style;
    };
    ;
    Dialog.prototype.getStyle = function (size) {
        if (size === void 0) { size = 16; }
        var sizeValue = typeof (size) === "number" ? size + "px" : size;
        var loading_style = {
            position: 'relative',
            width: size,
            height: size,
        };
        return loading_style;
    };
    Dialog.prototype.getAnimation = function (id, color) {
        if (color === void 0) { color = "#00bcd4"; }
        var animation = "\n            @keyframes rotate {\n                100% {\n                    transform: rotate(360deg);\n                }\n            }\n            @keyframes dash {\n                0% {\n                    stroke-dasharray: 1,200;\n                    stroke-dashoffset: 0;\n                }\n                50% {\n                    stroke-dasharray: 89,200;\n                    stroke-dashoffset: -35px;\n                }\n                100% {\n                    stroke-dasharray: 89,200;\n                    stroke-dashoffset: -124px;\n                }\n            }\n            @keyframes color-" + id + " {\n                100%, 0% {\n                    stroke: " + color + ";\n                }\n                40% {\n                    stroke: " + color + ";\n                }\n                66% {\n                    stroke: " + color + ";\n                }\n                80%, 90% {\n                    stroke: " + color + ";\n                }\n            }";
        return animation;
    };
    Dialog.prototype.render = function () {
        var _a = this.props, size = _a.size, color = _a.color, margin = _a.margin, strokeWidth = _a.strokeWidth;
        var id = this.createGuid();
        var style = this.getStyle(size);
        var circleStyle = this.getCircleStyle(id);
        var animation = this.getAnimation(id, color);
        return (React.createElement("div", { className: "svg react-svg-progress", style: __assign({}, style, { display: "inline-block", margin: margin }) },
            React.createElement("style", null, animation),
            React.createElement("svg", { style: this.svg_style, viewBox: "25 25 50 50" },
                React.createElement("circle", { style: circleStyle, cx: "50", cy: "50", r: "20", fill: "none", strokeWidth: strokeWidth || 5, strokeMiterlimit: "10" }))));
    };
    return Dialog;
}(React.Component));
Dialog.propTypes = {
    key: React.PropTypes.any,
    ref: React.PropTypes.any,
    color: React.PropTypes.string,
    size: React.PropTypes.oneOfType([React.PropTypes.number, React.PropTypes.string]),
    margin: React.PropTypes.string,
    strokeWidth: React.PropTypes.number,
};
exports.Dialog = Dialog;
Object.defineProperty(exports, "__esModule", { value: true });
exports.default = Dialog;
