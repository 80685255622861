"use strict";
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) if (e.indexOf(p[i]) < 0)
            t[p[i]] = s[p[i]];
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
var classes = require("classnames");
function CartSummary(_a) {
    var totals = _a.totals, coupons = _a.coupons, props = __rest(_a, ["totals", "coupons"]);
    var widths = ["xs-col-3-24", "xs-col-16-24 xs-col-24-offset-1", "xs-col-4-24"];
    var currency = totals.currency.toUpperCase();
    var toFixed = function (value) { return (typeof value === "string" ? value : value.toFixed(2)); };
    var items = props.lineItems.map(function (item, index) { return (React.createElement("div", { className: "ms-row vc zero-margin cart-item", key: "line-item-" + index },
        React.createElement("div", { className: widths[0] },
            React.createElement("img", { className: "img-responsive", src: item.thumbnailUrl })),
        React.createElement("div", { className: classes(widths[1], "win-ellipses") }, item.quantity + " \u2014 " + item.title),
        React.createElement("div", { className: classes(widths[2], "text-right") }, "$" + item.total.toFixed(2)))); });
    var couponLines = coupons.map(function (coupon) { return (React.createElement("div", { key: coupon.id, className: "ms-row vc zero-margin subtotal" },
        React.createElement("div", { className: "xs-col-18-24" },
            !props.controls ? null : (React.createElement("a", { href: "#", title: "Remove coupon", onClick: function (e) { return props.onRemoveDiscount(e, coupon); } },
                React.createElement("i", { className: "fa fa-close fa-one-rem marRight5" }))), coupon.code + " \u2014 " + coupon.percentOff + "% off"),
        React.createElement("div", { className: "xs-col-6-24 text-right" }, "-$" + toFixed(totals.discountTotal)))); });
    return (React.createElement("section", { id: "cart-summary", className: "m-col-10-24 m-col-24-push-14" },
        React.createElement("div", { className: "ms-row" },
            React.createElement("div", { className: "m-col-22-24 m-col-24-offset-1" },
                items,
                React.createElement("hr", null),
                props.controls.defaultValue(React.createElement("span", null)),
                React.createElement("div", { className: "ms-row vc zero-margin subtotal" },
                    React.createElement("div", { className: "xs-col-6-24" }, "Subtotal"),
                    React.createElement("div", { className: "xs-col-18-24 text-right" }, "$" + toFixed(totals.subTotal))),
                coupons,
                React.createElement("div", { className: "ms-row vc zero-margin tax-total" },
                    React.createElement("div", { className: "xs-col-6-24" }, "Tax"),
                    React.createElement("div", { className: "xs-col-18-24 text-right" }, "$" + toFixed(totals.taxTotal))),
                React.createElement("div", { className: "ms-row vc zero-margin shipping-total" },
                    React.createElement("div", { className: "xs-col-6-24" }, "Shipping"),
                    React.createElement("div", { className: "xs-col-18-24 text-right" }, props.shippingTotal
                        .map(function (value) { return (value === 0 ? "Free" : currency + " " + toFixed(value)); })
                        .defaultValue("––"))),
                React.createElement("hr", null),
                React.createElement("div", { className: "ms-row vc zero-margin grand-total" },
                    React.createElement("div", { className: "xs-col-6-24" }, "Total"),
                    React.createElement("div", { className: "xs-col-18-24 text-right" }, currency + " ",
                        React.createElement("strong", null, "$" + toFixed(totals.ultimateTotal))))))));
}
exports.CartSummary = CartSummary;
