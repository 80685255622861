import { WhitelabelSettings } from "kmmp";
import { getBackgrounds, getFrames } from "shared/images";

const humanBackgrounds = getBackgrounds("sci", "human");
const frames = getFrames("sci");

export const kmPortraits: WhitelabelSettings = {
    companyName: "km-portraits",
    siteName: "Keith Merrick Portraits",
    subjectType: "human",
    domain: "kmmp.ngrok.io",
    siteType: "ecommerce",
    products: [
        {
            _id: "KM1",
            _rev: "v1",
            name: "16x20 Canvas Portrait",
            urlSlug: "16-20-canvas-portrait",
            description: "Transform your favorite memory into a stunning work of art with our 16x20 canvas portrait! Upload your photo and let our professional graphic artists touch it up, cut, crop, or handle it however you like. Choose from four frame colors (Mahogany, Vintage, Honey, or Black) or opt for a gallery-wrapped canvas without frames. Select one of our 16 backgrounds to enhance the image, or leave the background as is.",
            imageUrl: "/public/images/ecommerce/16x20ro.png",
            price: 189.0,
            priceBreaks: [],
            backgroundInfo: {
                hasBackgrounds: true,
                backgrounds: humanBackgrounds,
            },
            frameInfo: {
                hasFrames: true,
                frames: frames,
            },
            cardInfo: {
                hasCards: false,
            },
        },
        {
            _id: "KM2",
            _rev: "v1",
            name: "11x14 Canvas Portrait",
            urlSlug: "11-14-canvas-portrait",
            description: "Create a beautiful piece of art with our 11x14 canvas portrait! Simply upload your photo and let our expert artists make it look great in any frame or background you choose. Choose from four frame colors (Mahogany, Vintage, Honey, or Black) and one of our 16 backgrounds, or opt for a gallery-wrapped canvas without frames.",
            imageUrl: "/public/images/ecommerce/11x14ro.png",
            price: 169.0,
            priceBreaks: [],
            backgroundInfo: {
                hasBackgrounds: true,
                backgrounds: humanBackgrounds,
            },
            frameInfo: {
                hasFrames: true,
                frames: frames,
            },
            cardInfo: {
                hasCards: false,
            },
        },
        {
            _id: "KM3",
            _rev: "v1",
            name: "8x10 Canvas Portrait",
            urlSlug: "8-10-canvas-portrait",
            description: "Make a cherished memory even more special with our 8x10 canvas portrait! Upload your photo and let us handle the rest. Our professional graphic artists will touch it up, cut, crop, or handle it however you like. Choose from four frame colors (Mahogany, Vintage, Honey, or Black) and one of our 16 backgrounds, or opt for a gallery-wrapped canvas without frames.",
            imageUrl: "/public/images/ecommerce/8x10ro.png",
            price: 129.0,
            priceBreaks: [],
            backgroundInfo: {
                hasBackgrounds: true,
                backgrounds: humanBackgrounds,
            },
            frameInfo: {
                hasFrames: true,
                frames: frames,
            },
            cardInfo: {
                hasCards: false,
            },
        },
        {
            _id: "KM99",
            _rev: "v1",
            name: "Gallery-wrapped Canvas Portrait",
            urlSlug: "gallery-wrapped-canvas-portrait",
            description: "Elevate your favorite photo to the next level with our 11x14 gallery-wrapped canvas portrait! Upload your image and let our expert artists make it look great. Since this is a gallery-wrap, you won't need to choose a frame - the canvas will be wrapped around the edges to create a beautiful, frameless piece of art. You can still select one of our 16 backgrounds or leave the background as is.",
            imageUrl: "/public/images/ecommerce/11x14galleryro.png",
            price: 169.0,
            priceBreaks: [],
            backgroundInfo: {
                hasBackgrounds: true,
                backgrounds: humanBackgrounds,
            },
            frameInfo: {
                hasFrames: false,
            },
            cardInfo: {
                hasCards: false,
            },
        },
    ],
};
