import * as React from "react";
import { Dialog } from "@nozzlegear/react-win-dialog";
import { useLoadingState } from "client/hooks/use-loading-state";
import { MaybeError } from "shared/components/maybe-error";
import { CompanyName } from "kmmp";
import { CreateOrModifyUser, UserSummary } from "api";
import { Auth } from "client/stores/auth";
import { AdminClient } from "client/http/admin";
import { Shipping } from "client/http/shipping";
import { ApiError } from "client/http";
import { useUnauthorizedPrompt } from "client/hooks";

interface Props {
    open: boolean;
    user: UserSummary | null;
    companyName: CompanyName;
    onUserUpdated: (data: CreateOrModifyUser.Response) => void;
    onClose: () => void;
}

interface State {
    locationId: string;
    locationName: string;
    address1: string;
    address2: string;
    zip: string;
    phone: string;
    countryCode: string;
}

// Currently all locations are restricted to the US.
const DEFAULT_COUNTRY = {
    code: "US",
    name: "United States",
};

const DEFAULT_STATE: State = {
    locationId: "",
    locationName: "",
    address1: "",
    address2: "",
    zip: "",
    phone: "",
    countryCode: DEFAULT_COUNTRY.code,
};

export function EditUserDialog({ user, ...props }: Props): JSX.Element {
    const userClient = React.useMemo(() => new AdminClient(Auth.token.value), []);
    const shippingClient = React.useMemo(() => new Shipping(Auth.token.value), []);
    const handleUnauthorized = useUnauthorizedPrompt();
    const [state, setState] = React.useState<State>(DEFAULT_STATE);
    const [{ loading, error }, setLoadingState] = useLoadingState(false);

    // Use an effect to merge a user's address into state whenever the user changes
    React.useEffect(() => {
        if (user) {
            setState({
                ...DEFAULT_STATE,
                locationId: user?.location_id ?? "",
                locationName: user?.location_name ?? "",
                address1: user?.address1 ?? "",
                address2: user?.address2 ?? "",
                zip: user?.zip ?? "",
                phone: user?.phone ?? "",
            });
        } else {
            setState(DEFAULT_STATE);
        }
    }, [user]);

    // Reset the error message when the user closes the dialog
    React.useEffect(() => {
        if (!props.open && error !== null) {
            setLoadingState(false);
        }
    }, [props.open]);

    // Event handlers
    const setStateProperty = (property: keyof State) => (e: React.FormEvent<HTMLInputElement>) =>
        setState({
            ...state,
            [property]: e.currentTarget.value,
        });

    const save = async (e: React.FormEvent) => {
        e.preventDefault();

        if (loading) return;

        setLoadingState(true);

        // Validate the address with UPS
        try {
            await shippingClient.validateAddress({
                company: state.locationName,
                name: state.locationName,
                address_line_1: state.address1,
                address_line_2: state.address2,
                postal_code: state.zip,
                country_code: state.countryCode,
            });
        } catch (e: any) {
            const error = e as ApiError;

            if (error.unauthorized && handleUnauthorized()) {
                return setLoadingState(error.message);
            }

            // Allow the user to ignore the address validation error if they wish
            if (!confirm(error.message + " Would you like to skip address validation and save changes anyway?")) {
                return setLoadingState(error.message);
            }
        }

        let response: CreateOrModifyUser.Response;

        try {
            const data: CreateOrModifyUser.Request = {
                locationId: state.locationId,
                locationName: state.locationName,
                address1: state.address1,
                address2: state.address2,
                zip: state.zip,
                phone: state.phone,
            };

            response = user
                ? await userClient.modifyUser(user._id, user._rev, data)
                : await userClient.createUser(data);
        } catch (e: any) {
            const error = e as ApiError;

            setLoadingState(error.message);

            if (error.unauthorized) {
                handleUnauthorized();
            }

            return;
        }

        setLoadingState(false);
        props.onUserUpdated(response);
        props.onClose();
    };

    return (
        <Dialog
            id="edit-user-dialog"
            title={user ? "Editing location " + user.location_id : "New location"}
            open={props.open}
            loading={loading}
            loadingHidesButtons={true}
            primaryText="Save Changes"
            onPrimaryClick={save}
            secondaryText="Close"
            onSecondaryClick={props.onClose}
        >
            <form onSubmit={save}>
                <div className="control-group">
                    <label htmlFor="company">{"Location ID"}</label>
                    <input
                        type="text"
                        name="company"
                        autoComplete="off"
                        placeholder="123456"
                        value={state.locationId}
                        onChange={setStateProperty("locationId")}
                        disabled={user !== null}
                        title={user !== null ? "Location ID cannot be changed for existing users." : ""}
                    />
                </div>
                <div className="control-group">
                    <label htmlFor="company">{"Location Name"}</label>
                    <input
                        type="text"
                        name="company"
                        autoComplete="off"
                        value={state.locationName}
                        onChange={setStateProperty("locationName")}
                    />
                </div>
                <div className="control-group">
                    <label htmlFor="address-line1">{"Street Address 1"}</label>
                    <input
                        type="text"
                        name="address-line1"
                        autoComplete="off"
                        value={state.address1}
                        onChange={setStateProperty("address1")}
                    />
                </div>
                <div className="control-group">
                    <label htmlFor="address-line2">{"Street Address 2"}</label>
                    <input
                        type="text"
                        name="address-line2"
                        autoComplete="off"
                        value={state.address2}
                        onChange={setStateProperty("address2")}
                    />
                </div>
                <div className="control-group">
                    <label htmlFor="postal-code">{"ZIP/Postal Code"}</label>
                    <input
                        type="text"
                        name="postal-code"
                        autoComplete="off"
                        value={state.zip}
                        onChange={setStateProperty("zip")}
                    />
                </div>
                <div className="control-group">
                    <label htmlFor="country-code">{"Country"}</label>
                    <select name="country-code" autoComplete="off" value={state.countryCode} onChange={() => {}}>
                        <option value={DEFAULT_COUNTRY.code}>{DEFAULT_COUNTRY.name}</option>
                    </select>
                </div>
                <MaybeError error={error} />
            </form>
        </Dialog>
    );
}
