import * as React from "react";
import { Auth } from "client/stores";
import { Paths } from "shared/paths";
import { Link } from "wouter";
import PageHeader from "client/components/page-header";

type Props = React.ClassAttributes<{ [key: string]: string }>;

export function ProductListPage(props: Props): JSX.Element {
    const { products } = Auth.companySettings;

    return (
        <div id="list-products">
            <PageHeader
                title={
                    Auth.companySettings.subjectType === "human"
                        ? "Canvas Portraits and other products"
                        : "Canvas Pet Portraits and other products"
                }
            />
            <div className="content">
                <span>
                    <Link to={Paths.home.index}>{"Home"}</Link>
                    {" / Products"}
                </span>
                <h4 className="results">{`Showing all ${products.length} results`}</h4>
                <div className="list pure-g">
                    {products.map((prod) => (
                        <div key={prod._id} className="product pure-u-6-24">
                            <Link to={Paths.products.product.get(prod.urlSlug || prod._id!)}>
                                <img src={prod.imageUrl} alt={prod.name} />
                                <span className="name">{prod.name}</span>
                                <span className="price">{`$${prod.price.toFixed(2)} USD`}</span>
                            </Link>
                            <Link to={Paths.products.product.get(prod.urlSlug || prod._id!)} className="btn">
                                {"Select Options"}
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
