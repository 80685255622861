import * as React from "react";
import { WhitelabelSettings } from "kmmp";
import { ShadowBoxColor } from "kmmp/frontend";
import { ShadowBoxPreview } from "client/components/shadow_box_preview";
import { getShadowBoxImage, getShadowBoxImages } from "shared/images";
import { If, Repeater } from "shared/components";

interface Props {
    color: ShadowBoxColor;
    onSelectColor: (newSelection: ShadowBoxColor) => void;
    whitelabelSettings: WhitelabelSettings;
    displayAsPackage: boolean;
    children?: React.ReactNode;
}

interface ShadowBoxPreviewImage {
    color: ShadowBoxColor;
    src: string;
}

/**
 * Component for selecting a shadow box by clicking on an image.
 */
export function VisualBoxSelector(this: void, props: Props) {
    const [preview, setPreview] = React.useState<ShadowBoxPreviewImage>(() => {
        return {
            color: props.color,
            src: getShadowBoxImage(props.color),
        };
    });
    const previewImageData: Array<ShadowBoxPreviewImage> = React.useMemo(
        () => [
            ...getShadowBoxImages("Mahogany").map<ShadowBoxPreviewImage>((src) => ({
                color: "Mahogany",
                src: src,
            })),
            ...getShadowBoxImages("White").map<ShadowBoxPreviewImage>((src) => ({
                color: "White",
                src: src,
            })),
        ],
        []
    );

    const handleSelectImage = (image: ShadowBoxPreviewImage) => (e: React.SyntheticEvent) => {
        e.preventDefault();
        setPreview(image);

        if (image.color !== props.color) {
            props.onSelectColor(image.color);
        }
    };

    // Make sure the state preview image matches the color from props
    React.useEffect(() => {
        if (props.color !== preview.color) {
            setPreview({
                color: props.color,
                src: getShadowBoxImage(props.color),
            });
        }
    }, [props.color]);

    return (
        <div className="pure-g justify-space-between">
            <div className="pure-u-sm-9-24">
                <div className="thumbnails">
                    <Repeater source={previewImageData}>
                        {(previewImage) => (
                            <div key={previewImage.src} className="pure-u-sm-12-24 pure-padded">
                                <a href="#" onClick={handleSelectImage(previewImage)}>
                                    <img
                                        style={{ paddingTop: "10px", paddingBottom: "10px" }}
                                        src={previewImage.src}
                                        className="img-responsive"
                                    />
                                </a>
                            </div>
                        )}
                    </Repeater>
                </div>
            </div>
            <div className="pure-u-sum-1-24" />
            <div className="pure-u-sm-14-24">
                <ShadowBoxPreview
                    box={props.color}
                    src={preview.src}
                    whitelabelSettings={props.whitelabelSettings}
                    displayAsPackage={props.displayAsPackage}
                />
                <If condition={!!props.children}>
                    <hr style={{ margin: "25px 0" }} />
                    {props.children}
                </If>
            </div>
        </div>
    );
}
