import * as React from "react";
import Paths from "shared/paths";
import PageHeader from "../../components/page-header";
import { HolidayMessage } from "./holiday_message";
import { NewsMessage } from "./news_message";
import { Auth, Announcements } from "client/stores";
import { Link } from "wouter";
import { Holiday } from "kmmp";
import { observer } from "mobx-react";
import { Repeater } from "shared/components";

function CorporateHome(_: unknown): JSX.Element {
    const Holidays = observer(function () {
        const announcements = Announcements.announcements.get();
        const holidays = announcements.filter((a) => a.type === "holiday").map((a) => a as Holiday);

        return (
            <ul>
                <Repeater source={holidays}>
                    {(h) => (
                        <li key={h._id}>
                            {h.name}
                            {": "}
                            {h.displayDate}
                            <strong>{`*`}</strong>
                        </li>
                    )}
                </Repeater>
            </ul>
        );
    });

    const AnnouncementsAndHolidays = observer(function () {
        const now = Date.now();
        const items = Announcements.announcements.get();
        const messages = items.filter((item) => {
            const timeStamp = new Date(item.timestamp).getTime();
            const difference = Math.abs(timeStamp - now);
            const holidayLength = 24 * 60 * 60 * 1000 * 10; // 10 days in milliseconds
            const announceLength = 24 * 60 * 60 * 1000 * 3; // 3 days in milliseconds

            // Announcements show immediately, and expire 3 days after their timestamp unless they have an explicit expiration date
            if (item.type === "breaking") {
                if (item.expiration) {
                    const expiration = new Date(item.expiration).getTime();
                    return now <= expiration;
                }
                return difference < announceLength;
            }

            // Holidays start showing 10 days before the holiday, and expire 10 days after their timestamp unless they have an explicit expiration date
            if (difference < holidayLength) {
                if (item.expiration) {
                    const expiration = new Date(item.expiration).getTime();
                    return now <= expiration;
                }
                return true;
            }
            return false;
        });

        if (messages.length === 0) {
            return <></>;
        }

        return (
            <div className="pure-u-20-24">
                <div id="markdownOutput">
                    <Repeater source={messages}>
                        {(m) =>
                            m.type === "holiday" ? (
                                <HolidayMessage details={m} key={m._id} />
                            ) : (
                                <NewsMessage details={m} key={m._id} />
                            )
                        }
                    </Repeater>
                    <hr />
                </div>
            </div>
        );
    });

    return (
        <section id="home">
            <PageHeader title={`Latest News & Information`} />
            <div className="section white">
                <div className="pure-g center-children long-form">
                    <AnnouncementsAndHolidays />
                    <div className="pure-u-20-24">
                        <h4>{"Instructions for replacing damaged frames"}</h4>
                        <p>
                            {
                                "If you've received a frame that was damaged during shipping, please use the reorder process to replace it. To do so, follow these instructions:"
                            }
                        </p>
                        <ul>
                            <li>
                                <Link to={Paths.history.index}>{"Navigate to the Order History page."}</Link>
                            </li>
                            <li>
                                {'Find the order containing the portrait/frame and press the "Reorder Items" link.'}
                            </li>
                            <li>{"Fill out the Purchase Order ID using the ID from the original order."}</li>
                            <li>{"Change the quantity of portraits to the number of frames you need to replace."}</li>
                            <li>{'In the instructions box, write "damaged frame replacement".'}</li>
                            <li>
                                {"Press the Start Reorder button and continue through the order process as normal."}
                            </li>
                        </ul>
                        <p>
                            {"Note: you will need to send us a picture of the damaged frame to "}
                            <a href="mailto:art@keithmerrick.com">{"art@keithmerrick.com"}</a>
                            {
                                " with a reference to the order number. If you have any problems or questions, please call us at 1-800-831-8568."
                            }
                        </p>
                    </div>
                    <div className="pure-u-20-24">
                        <h4>Valid Purchase Order IDs</h4>
                        <p>
                            Please make sure you're using valid Purchase Order IDs when placing a new order or
                            reordering an old order. Your Purchase Order ID can be obtained from HMIS.{" "}
                            <strong>
                                If you do not enter a valid Purchase Order ID, your order will not be processed.
                            </strong>
                        </p>
                    </div>
                    <div className="pure-u-20-24">
                        <h4>Order and shipping cut-off times</h4>
                        <ul>
                            <li>Orders must be received by 2 PM Central Standard Time to ship that day.</li>
                            <li>
                                On Saturday, orders must be received by 9:30 AM Central Standard Time to ship that day.{" "}
                                <strong>Proofs are not available on Saturday.</strong>
                            </li>
                            <li>UPS does not deliver or pick-up on Sunday so portraits will not move that day.</li>
                        </ul>
                    </div>
                    <div className="pure-u-20-24">
                        <h4>UPS Shipping Schedule</h4>
                        <p>
                            In the United States, UPS observes the following holidays (For up-to-the-minute information,
                            go to <a href="http://www.ups.com">http://www.ups.com</a>
                            ):
                        </p>
                        <Holidays />
                        <p>
                            <strong>*UPS Holiday</strong>: No shipping available.
                        </p>
                    </div>
                    <div className="pure-u-1-1">
                        <hr />
                    </div>
                </div>
            </div>
        </section>
    );
}

function EcommerceHome(props: React.Props<any>) {
    const whitelabelSettings = Auth.companySettings;

    return (
        <div id="ecommerce-home">
            <div id="home-banner">
                <img
                    src="/public/images/ecommerce/pet-eternal-hero.png"
                    className="home-banner-image wp-post-image"
                    srcSet="/public/images/ecommerce/hero-images/pet-eternal-hero-alt-2-e1465850042111.png 1620w, /public/images/ecommerce/hero-images/pet-eternal-hero-alt-2-e1465850042111-300x125.png 300w, /public/images/ecommerce/hero-images/pet-eternal-hero-alt-2-e1465850042111-768x320.png 768w, /public/images/ecommerce/hero-images/pet-eternal-hero-alt-2-e1465850042111-1024x427.png 1024w, /public/images/ecommerce/hero-images/pet-eternal-hero-alt-2-e1465850042111-350x146.png 350w"
                    sizes="(max-width: 1620px) 100vw, 1620px"
                    alt="Hero image"
                />
                <div className="message">
                    <h1>{"Beautiful canvas pet portraits at a fair price."}</h1>
                    <Link className="btn blue" to={Paths.products.index}>
                        {"Get Started »"}
                    </Link>
                </div>
            </div>
            <div className="entry-content">
                <div className="pure-g">
                    <div className="pure-u-12-24">
                        <h4 className="page-title marBottom25">
                            Upload a picture of your pet, we'll frame and transfer it to canvas.
                        </h4>
                        <p>
                            Pick out a favorite picture of your pet and easily upload it to our professionally trained
                            graphic artists. We'll touch up your image and then transfer it to an art-grade canvas
                            print.
                        </p>
                        <p>
                            {`Of course, no canvas print is complete without a genuine wooden art frame to place it in. ${whitelabelSettings.siteName} offers four high-quality wooden frames; choose from Honey, Gray Vintage, Black and Mahogany. Each frame comes in three different sizes: 16×20, 11×14 and 8×10.`}
                        </p>
                    </div>
                    <div className="pure-u-12-24">
                        <img
                            className="img-responsive img-centered alignnone"
                            src="/public/images/ecommerce/pet-eternal-smol-cat.png"
                            alt="Custom Cat Portrait"
                            width="473"
                            height="400"
                        />
                    </div>
                </div>
                <hr />
                <div className="pure-g">
                    <div className="pure-u-12-24">
                        <img
                            className="img-responsive img-centered alignnone"
                            src="/public/images/ecommerce/pet-eternal-services.png"
                            alt="Custom Pet Portrait"
                            width="340"
                            height="400"
                        />
                    </div>
                    <div className="pure-u-12-24">
                        <h4 className="page-title marBottom25">
                            Dozens of services included with each portrait, at no extra cost, just for you.
                        </h4>
                        <p>
                            {`Most competitors will charge you extra for portrait services that require no extra effort beyond clicking a button in their editor programs. ${whitelabelSettings.siteName} includes all of these services, and a few more, at no extra cost.`}
                        </p>
                        <p>
                            Our team of graphic artists will take old, cracked, faded, blurry, torn or damaged photos
                            and repair them, enhance the color, hue and brightness of faded images, and they'll even fix
                            red-eye, defects and scratches. All of these services are included by default.
                        </p>
                    </div>
                </div>
                <hr />
                {/* <div className="pure-g">
                    <div className="pure-u-12-24">
                        <h4 className="page-title marBottom25">Free shipping to the USA, and a 30-day money-back guarantee for all.</h4>
                        <p>Live in the lower 48 of the United States? We'll ship your portraits to you for free with UPS Ground Shipping. If 5-10 days isn't fast enough for you, you can also select UPS 2nd Day Air or UPS Next Day to get your pet portrait as quickly as possible.</p>
                        <p>We're so sure you'll love your portrait, we're offering a 30-day money-back guarantee for each order. If you decide you don't like it, just ship it back to us within thirty days and we'll refund your purchase. We'll even pay for the shipping fees.</p>
                    </div>
                    <div className="pure-u-12-24">
                        <img className="img-responsive img-centered alignnone" src="https://www.peteternal.com/wp-content/uploads/2016/06/shipping.png" alt="Custom Dog Portrait" width="340" height="400" />
                    </div>
                </div>
                <hr/> */}
                <div className="text-center">
                    <Link className="btn blue" to={Paths.products.index}>
                        Start Customizing Your Pet's Portrait!
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default function Home(props: React.Props<any>) {
    if (Auth.companySettings.siteType === "corporate") {
        return <CorporateHome />;
    }

    return <EcommerceHome {...props} />;
}
