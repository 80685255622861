import { Product, PriceBreak } from "kmmp";
import { Option, compute } from "@nozzlegear/railway";

/**
 * Finds the best possible price break for a given quantity. Returns None if a suitable price break is not found.
 */
export function findPriceBreak(quantity: number, priceBreaks: PriceBreak[]): Option<PriceBreak> {
    return priceBreaks.reduce((state, pb) => {
        if (quantity < pb.minQuantity) {
            // This quantity does not meet the price break's min condition
            return state;
        }

        if (state.isNone()) {
            // If the quantity is greater than the price break's min, then the condition is met
            return quantity >= pb.minQuantity ? Option.ofSome(pb) : state;
        }

        if (pb.minQuantity > state.get().minQuantity) {
            // The quantity both meets this price break's min condition, and this price break is more "valuable" than the last one seen
            return Option.ofSome(pb);
        }

        return state;
    }, Option.ofNone<PriceBreak>());
}

function assertNever(x: never): never {
    throw new Error("Unexpected object: " + x);
}

/**
 * Applies a price break to the @param subtotal
 */
export function applyPriceBreak(
    quantity: number,
    price: number,
    priceBreakModifier: number,
    type: "percentage" | "absolute",
    applyTo: "subtotal" | "individual"
): number {
    // Ensure the discount is positive
    if (priceBreakModifier < 0) {
        priceBreakModifier = priceBreakModifier * -1;
    }

    // Ensure the value is not greater than 1 if it's a percentage, e.g. a 5% discount is represented as 0.05 modifier
    if (type === "percentage" && priceBreakModifier > 1) {
        throw new Error(
            `Percentage discount "${priceBreakModifier}" could not be applied because it is greater than 1. To use a percentage discount, it must be represented as a decimal less than 1 (e.g. 5% discount is a 0.05 modifier).`
        );
    }

    const discountedPrice = compute<number>(() => {
        const subtotal = quantity * price;

        switch (type) {
            case "percentage":
                // When type is percentage, the result will be the same whether applied to the subtotal or individual items
                return subtotal - priceBreakModifier * subtotal;

            case "absolute":
                switch (applyTo) {
                    case "individual":
                        return subtotal - priceBreakModifier * quantity;

                    case "subtotal":
                        return subtotal - priceBreakModifier;

                    default:
                        return assertNever(applyTo);
                }

            default:
                return assertNever(type);
        }
    });

    return discountedPrice < 0 ? 0 : discountedPrice;
}

/**
 * Takes the current quantity and the price break's minimum quantity and splits them into the quantity with the price break, and the quantity without the price break.
 */
export function splitQuantities(
    quantity: number,
    priceBreakMinQuantity: number
): { withoutDiscount: number; withDiscount: number } {
    if (priceBreakMinQuantity === 0 || quantity === 0) {
        return {
            withDiscount: quantity,
            withoutDiscount: 0,
        };
    }

    if (priceBreakMinQuantity > quantity) {
        return {
            withDiscount: 0,
            withoutDiscount: quantity,
        };
    }

    // minQuantity is considered to be "inclusive", i.e. if minQuantity is 2, then any quantity at and above two is discounted.
    // To calculate that, subtract the minQuantity from quantity and add 1 to make it inclusive
    return {
        withDiscount: quantity - priceBreakMinQuantity + 1,
        withoutDiscount: priceBreakMinQuantity - 1,
    };
}

/**
 * Calculates the price of a line item based on the quantity and product price breaks.
 */
export function calculate(quantity: number, product: Product): number {
    if (quantity < 1) {
        throw new Error(`Quantity must be greater than 0. Current value: ${quantity}.`);
    }

    return findPriceBreak(quantity, product.priceBreaks)
        .map((pb) => {
            if (pb.applyBelowMinQuantity) {
                // Every item gets the price break applied to it
                return applyPriceBreak(quantity, product.price, pb.modifier, pb.modifierType, pb.applyTo);
            }

            // The modifier is only applied at and above the min quantity, and the modifier is a percentage
            const { withDiscount, withoutDiscount } = splitQuantities(quantity, pb.minQuantity);
            const subtotalWithoutDiscount = withoutDiscount * product.price;
            const subtotalWithDiscount = applyPriceBreak(
                withDiscount,
                product.price,
                pb.modifier,
                pb.modifierType,
                pb.applyTo
            );

            return subtotalWithoutDiscount + subtotalWithDiscount;
        })
        .defaultWith(() => {
            // No price break found, so the price is a simple price * quantity
            return product.price * quantity;
        });
}
