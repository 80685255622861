import * as Version1 from "kmmp/orders/v1";
import * as Version2 from "kmmp/orders/v2";

class IsAmbiguousShipping {
    /**
     * Checks if the shipping value is for an ambiguous address or unambiguous address.
     */
    version1(
        input: Version1.AmbiguousShippingAndRates | Version1.ShippingAndRates
    ): input is Version1.AmbiguousShippingAndRates {
        return input.ambiguous === true;
    }

    /**
     * Checks if the shipping value is for an ambiguous address or unambiguous address.
     */
    version2(
        input: Version2.AmbiguousShippingAndRates | Version2.ShippingAndRates
    ): input is Version2.AmbiguousShippingAndRates {
        return input.ambiguous === true;
    }
}

export const isAmbiguousShipping = new IsAmbiguousShipping();

/**
 * Checks if the error's message indicates it is an ambiguous shipping error from UPS.
 */
export function isAmbiguousShippingError(error: Error): boolean {
    const messages = [
        "Address is ambiguous and could not be validated",
        "The requested service is unavailable between the selected locations",
    ];

    return messages.some((m) => error.message.indexOf(m) > -1);
}
