import * as mobx from "mobx";
import * as V1 from "kmmp/orders/v1";
import * as Frontend from "kmmp/frontend";
import { action, autorun, computed, observable } from "mobx";
import { CONSTANTS } from "client/constants";
import { Dev } from ".";
import { v4 as uuid } from "uuid";
import { Rate } from "shipping-ups";
import { mapToFrontendImage } from "shared/map_images";
import { mapStringToFrame } from "shared/map-string-to-frame";

type ShippingConfig = { ambiguous: true } | { ambiguous: false; rates: Rate[]; saturdayDeliveryAvailable: boolean };
type StoredData = V1.LineItem[] | { version: "2"; items: Frontend.LineItem[] };

export class CartStore {
    constructor() {
        // Parse settings from localstorage
        const prefersEmailReceipts = localStorage.getItem(CONSTANTS.PREFERS_EMAIL_RECEIPTS_COOKIE_NAME);

        if (prefersEmailReceipts) {
            this.prefersEmailReceipts.set(JSON.parse(prefersEmailReceipts) === true);
        }

        // Parse cart data from localstorage
        const cartData = JSON.parse(localStorage[CONSTANTS.CART_COOKIE_NAME] || "[]") as StoredData;

        if (Array.isArray(cartData)) {
            // The parsed value is an array of V1.LineItem. Map them to Frontend.LineItem.
            // V1.LineItem is always a portrait.
            this.items = cartData.map<Frontend.LineItem>((item) => ({
                type: "portrait",
                uuid: item.uuid,
                deceasedName: item.deceasedName,
                source: item.source,
                purchaseOrderId: item.purchaseOrderId || null,
                originalOrderId: item.originalOrderId || null,
                customization: {
                    orientation: item.customization.orientation,
                    package: item.customization.package,
                    background: {
                        fileName: item.customization.background.image,
                        name: item.customization.background.name,
                        notes: item.customization.background.notes,
                    },
                    frame: {
                        fileName: item.customization.frame.portraitImage,
                        name: mapStringToFrame(item.customization.frame.name),
                    },
                },
                images: item.images.map(mapToFrontendImage),
                qty: {
                    cards: item.quantities.extraCards,
                    prints: item.quantities.extraPrints,
                    packages: item.quantities.total,
                },
            }));
        } else {
            this.items = cartData.items;
        }

        autorun(() => {
            const stored: StoredData = {
                version: "2",
                items: [...this.items],
            };

            localStorage.setItem(CONSTANTS.CART_COOKIE_NAME, JSON.stringify(mobx.toJS(stored)));
            localStorage.setItem(
                CONSTANTS.PREFERS_EMAIL_RECEIPTS_COOKIE_NAME,
                JSON.stringify(this.prefersEmailReceipts)
            );
        });
    }

    @observable
    decedent_name: string = "";

    @observable
    items: ReadonlyArray<Frontend.LineItem> = [];

    @observable
    startedCheckout = false;

    @observable
    address: Frontend.ShippingAddress = {
        city: "",
        name: "",
        state: "",
        zip: "",
        country: "",
        street1: "",
        street2: "",
        street3: "",
    };

    @observable
    shipping: ShippingConfig = { ambiguous: true };

    @observable
    details: Frontend.Details = {
        email: "",
        needed_by_date: "",
        needed_by_time: "",
        phone: "",
        placed_by: "",
        send_confirmation: false,
    };

    /**
     * Indicates that the user prefers to receive email receipts and the option should be on by default.
     */
    prefersEmailReceipts = mobx.observable.box(false);

    @computed
    get containsReorders() {
        return this.items.some((i) => !!i.originalOrderId);
    }

    @action
    setPrefersEmailReceipts = (newValue: boolean) => {
        this.prefersEmailReceipts.set(newValue);
    };

    @action
    addToCart(item: Frontend.LineItem) {
        item.uuid = item.uuid || uuid();

        if (Dev.allow_cart === false) {
            this.items = [item];
        } else {
            this.items = [...this.items, item];
        }
    }

    @action
    addReordersToCart(items: Frontend.LineItem[], originalOrderId: string) {
        if (items.some((i) => !i.uuid)) {
            throw new Error(
                `An item was passed to mobx Cart store .addReordersToCart that didn't have a uuid. Reordered line items should have uuids that match the original order's line item uuid.`
            );
        }

        const newItems = items.map<Frontend.LineItem>((i) => ({
            ...i,
            originalOrderId: originalOrderId,
        }));

        if (Dev.allow_cart === false) {
            this.items = newItems;
        } else {
            this.items = [...this.items, ...newItems];
        }
    }

    @action
    setQuantity(item: Frontend.LineItem, quantityType: "packages" | "cards" | "prints", quantity: number) {
        const items = [...this.items];
        const index = items.findIndex((i) => i.uuid === item.uuid);
        const updatedItem = items[index];

        switch (updatedItem.type) {
            case undefined:
            case "portrait":
                switch (quantityType) {
                    case "packages":
                        updatedItem.qty.packages = quantity;
                        break;

                    case "cards":
                        updatedItem.qty.cards = quantity;
                        break;

                    case "prints":
                        updatedItem.qty.prints = quantity;
                        break;
                }
                break;

            case "shadow-box":
            case "signature-jersey":
            case "signature-portrait":
                updatedItem.qty.packages = quantity;
                break;

            default:
                const neverItem: never = updatedItem;
                console.error("Unhandled line item type", neverItem);
                throw new Error("Unhandled line item type.");
        }

        items.splice(index, 1, updatedItem);

        this.items = items;
    }

    @action
    removeItem(item: Frontend.LineItem) {
        const items = [...this.items];
        const index = items.findIndex((i) => i.uuid === item.uuid);

        items.splice(index, 1);

        this.items = items;
    }

    @action
    setName(name: string) {
        this.decedent_name = name;
        this.items = this.items.map((i) => ({
            ...i,
            deceasedName: name,
        }));
    }

    @action
    startCheckout() {
        this.startedCheckout = true;
    }

    @action
    setVerifiedShippingAddressAndRates(address: Frontend.ShippingAddress, shipping: Frontend.ShippingRateData) {
        this.address = address;
        this.shipping = {
            ambiguous: false,
            rates: Array.isArray(shipping.rates) ? shipping.rates : [],
            saturdayDeliveryAvailable: shipping.saturdayDeliveryAvailable || false,
        };
    }

    @action
    setAmbiguousAddress(address: Frontend.ShippingAddress) {
        this.address = address;
        this.shipping = {
            ambiguous: true,
        };
    }

    @action
    setDetails(details) {
        this.details = details;
    }

    /**
     * Resets the cart, removing all items and saving the state to localStorage.
     */
    @action
    reset() {
        this.items = [];
        this.startedCheckout = false;
        this.decedent_name = "";
        this.details = {
            email: "",
            needed_by_date: "",
            needed_by_time: "",
            phone: "",
            placed_by: "",
            send_confirmation: false,
        };
        this.address = {
            city: "",
            name: "",
            state: "",
            zip: "",
            country: "",
            street1: "",
            street2: "",
            street3: "",
        };
        this.shipping = {
            ambiguous: true,
        };
    }
}

export const Cart = new CartStore();

window["CartStore"] = Cart;
