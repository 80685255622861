import * as React from "react";

export default function PageHeader(props: { title: string }) {
    return (
        <div className="bg tops-blooby">
            <div className="pure-g">
                <div className="pure-u-2-24" />
                <div className="pure-u-12-24">
                    <h2 className="page-title">{props.title}</h2>
                </div>
            </div>
        </div>
    );
}
