﻿import { SCIPackageType, SCIPackageTypeWithoutCards, FrameSize } from "kmmp/frontend";

/**
 * Returns the correct SCI package type based on the size of the frame.
 */
export function getPackageFromFrameSize(size: FrameSize): SCIPackageTypeWithoutCards {
    switch (size) {
        case "16x20":
            return "CP1";

        case "11x14":
            return "CP2";

        case "8x10":
            return "CP3";

        default:
            const s: never = size;
            throw new Error(`Unhandled frame size ${s}.`);
    }
}

/**
 * Returns the correct frame size based on the package type.
 */
export function getFrameSizeFromPackage(pkg: Exclude<SCIPackageType, "CP4">): FrameSize {
    switch (pkg) {
        case "CP1":
        case "CP15":
            return "16x20";

        case "CP2":
        case "CP25":
            return "11x14";

        case "CP3":
        case "CP35":
            return "8x10";

        default:
            const s: never = pkg;
            throw new Error(`Unhandled package type ${s}`);
    }
}

export function isSciPackage(pkg: string): pkg is SCIPackageType {
    switch (pkg as SCIPackageType) {
        case "CP1":
        case "CP2":
        case "CP3":
        case "CP4":
        case "CP15":
        case "CP25":
        case "CP35":
            return true;

        default:
            return false;
    }
}

/**
 * Converts an SCI package type with extra cards (e.g. CP15, CP25, CP35) to a package type without cards (e.g. CP1, CP2, CP3).
 */
export function removeExtraCardsFromPackage(pkg: Exclude<SCIPackageType, "CP4">): SCIPackageTypeWithoutCards {
    switch (pkg) {
        case "CP15":
            return "CP1";

        case "CP25":
            return "CP2";

        case "CP35":
            return "CP3";

        default:
            return pkg;
    }
}

/**
 * Converts an SCI package type without cards (e.g. CP1, CP2, CP3) to a package type with cards (e.g. CP15, CP25, CP35).
 */
export function addCardsToPackage(pkg: SCIPackageTypeWithoutCards): SCIPackageType {
    switch (pkg) {
        case "CP1":
            return "CP15";

        case "CP2":
            return "CP25";

        case "CP3":
            return "CP35";

        default:
            return pkg;
    }
}
