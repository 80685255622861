import ga = require("ga");
import * as Frontend from "kmmp/frontend";
import { useLocation } from "wouter";
import { useState } from "client/hooks";
import { Paths } from "shared/paths";
import { v4 as guid } from "uuid";
import { Observer } from "mobx-react";
import * as React from "react";
import PageHeader from "client/components/page-header";
import { getFrames, getBackgrounds, getSignaturePortraitBackgrounds } from "shared/images";
import * as Stores from "client/stores";
import { Auth } from "client/stores";
import { compute, Option } from "@nozzlegear/railway";
import { VisualBackgroundSelector } from "client/pages/quick/visual_background_selector";
import { Orientation } from "kmmp";
import { VisualFrameSelector } from "client/pages/quick/visual_frame_selector";
import { QuantitySelector } from "./quantity_selector";
import { PurchaseOrderIdBox } from "./purchase_order_id_box";
import * as sciPackageConversion from "shared/package-types/sci-portraits";
import * as messengerPackageConversion from "shared/package-types/messenger-portraits";
import * as signaturePackageConversion from "shared/package-types/signature-portraits";
import { purchaseOrderIdIsValid } from "shared/purchase-order-id-is-valid";
import { FrameSelector } from "client/pages/quick/frame_size_selector";
import { OutOfStockWarning } from "client/components/out-of-stock-warning";
import { EngravingControls } from "client/pages/quick/engraving_controls";
import { ImageUploadDialog } from "client/pages/quick/image_upload_dialog";
import { If } from "shared/components";

type Props = {
    signaturePortraitsOnly?: boolean;
};

export interface State {
    backgroundVariant: Frontend.BackgroundImage;
    frameVariant: Option<Frontend.FrameImage>;
    quantity: number;
    error: Option<string>;
    customerImage: Option<string>;
    showUploadForm: boolean;
    orientation: Option<Orientation>;
    purchaseOrderId: string;
    extraPrints: number;
    extraCards: number;
    frameSize: Frontend.FrameSize;
    engravingName: string;
    engravingDate1: string;
    engravingDate2: string;
    engravingSentiment: string;
}

export function QuickPage(props: Props): JSX.Element {
    const backgrounds = compute(() => {
        if (props.signaturePortraitsOnly === true) {
            return getSignaturePortraitBackgrounds();
        } else {
            return getBackgrounds(Auth.companySettings.companyName, Auth.companySettings.subjectType);
        }
    });
    const [_, setLocation] = useLocation();
    const [state, setState] = useState<State>({
        backgroundVariant: backgrounds[0],
        frameVariant: Option.ofNone(),
        orientation: Option.ofNone(),
        quantity: 1,
        extraPrints: 0,
        extraCards: 0,
        customerImage: Option.ofNone(),
        showUploadForm: false,
        purchaseOrderId: "",
        frameSize: "16x20",
        error: Option.ofNone(),
        engravingName: "",
        engravingDate1: "",
        engravingDate2: "",
        engravingSentiment: "",
    });

    const selectFrame: (frame: Frontend.FrameImage) => void = (frame) =>
        setState({ frameVariant: Option.ofSome(frame) });

    const selectFrameSize: (size: Frontend.FrameSize) => void = (size) => setState({ frameSize: size });

    const buildPortraitFormControls = () => {
        const { frameVariant, backgroundVariant, orientation, purchaseOrderId } = state;
        const validFrames = getFrames(Auth.companySettings.companyName);
        const validFrameSizes = compute<Frontend.FrameSize[]>(() => {
            if (props.signaturePortraitsOnly) {
                return ["16x20"];
            }

            switch (Auth.companySettings.companyName) {
                case "sci":
                case "church-and-chapel":
                    return ["16x20", "11x14", "8x10"];

                // TODO: unclear if all companies should have access to all three sizes
                default:
                    return ["16x20", "11x14", "8x10"];
            }
        });

        const selectFrame = (event: React.FormEvent<any>) => {
            const val = (event.target as any as HTMLSelectElement).value;
            const frame = validFrames.find((f) => f.name === val) || validFrames[0];

            setState({ frameVariant: Option.ofSome(frame) });
        };

        const selectBg = (event: React.FormEvent<any>) => {
            const val = (event.target as any as HTMLSelectElement).value;
            const bg = backgrounds.find((bg) => bg.name === val);

            setState({ backgroundVariant: bg });
        };

        const selectOrientation = (event: React.FormEvent<any>) => {
            const val = (event.target as any as HTMLSelectElement).value as "portrait" | "landscape";

            setState({ orientation: Option.ofSome(val) });
        };

        const setPoId = (purchaseOrderId: string) => {
            setState({ purchaseOrderId: purchaseOrderId });
        };

        const bgs = backgrounds.map((bg) => (
            <option key={bg.name} value={bg.name}>
                {bg.name}
            </option>
        ));

        const frames = validFrames.map((f) => (
            <option key={f.name} value={f.name}>
                {f.name}
            </option>
        ));

        const orientations = [
            { value: "portrait", description: "Portrait (vertical)" },
            { value: "landscape", description: "Landscape (horizontal)" },
        ].map((o) => (
            <option key={o.value} value={o.value}>
                {o.description}
            </option>
        ));

        return (
            <div key={"portrait-form-controls"}>
                {Stores.Auth.requireCoupaPurchaseOrder ? (
                    <PurchaseOrderIdBox value={purchaseOrderId} onChange={setPoId} />
                ) : null}

                <FrameSelector
                    company={Stores.Auth.companySettings.companyName}
                    showForCompanies={["sci", "messenger", "church-and-chapel"]}
                    selectedSize={state.frameSize}
                    validSizes={validFrameSizes}
                    onChange={selectFrameSize}
                />

                <div className="selector-container">
                    <p>{"Frame:"}</p>
                    <select
                        autoComplete="off"
                        className="form-control selector"
                        value={frameVariant.map<string>((i) => i.name).defaultValue("")}
                        onChange={selectFrame}
                    >
                        {Option.isNone(frameVariant) ? <option value="">{`-- Select a frame --`}</option> : null}
                        {frames}
                    </select>
                </div>
                <div className="selector-container">
                    <p>{"Background:"}</p>
                    <select
                        autoComplete="off"
                        className="form-control selector"
                        value={backgroundVariant.name}
                        onChange={selectBg}
                    >
                        {bgs}
                    </select>
                </div>
                <div className="selector-container">
                    <p>{"Orientation:"}</p>
                    <select
                        autoComplete="off"
                        className="form-control selector"
                        value={orientation.map<string>((o) => o).defaultValue("")}
                        onChange={selectOrientation}
                    >
                        {Option.isNone(orientation) ? <option value="">{`-- Select an orientation --`}</option> : null}
                        {orientations}
                    </select>
                </div>
            </div>
        );
    };

    const buildGenericFormControls = () => {
        const label = compute(() => {
            if (props.signaturePortraitsOnly === true) {
                return {
                    left: "Total Portraits:",
                    right: Option.ofNone(),
                };
            }

            if (Auth.companySettings.companyName === "sci") {
                return {
                    left: "Total Packages:",
                    right: Option.ofSome("(1 Portrait and 5 Portrait Cards per package)"),
                };
            }

            return {
                left: "Total Portraits:",
                right: Option.ofNone(),
            };
        });

        return (
            <div key={"generic-form-controls"}>
                <QuantitySelector
                    min={1}
                    max={10}
                    quantity={state.quantity}
                    onChange={(i) => setState({ quantity: i })}
                    leftLabel={label.left}
                    rightLabel={label.right}
                />
                <If condition={Auth.companySettings.companyName === "sci"}>
                    <If condition={props.signaturePortraitsOnly === true}>
                        <EngravingControls
                            forSignaturePortraits={props.signaturePortraitsOnly === true}
                            name={state.engravingName}
                            date1={state.engravingDate1}
                            date2={state.engravingDate2}
                            sentiment={state.engravingSentiment}
                            onNameChanged={(n) => setState({ engravingName: n })}
                            onDate1Changed={(n) => setState({ engravingDate1: n })}
                            onDate2Changed={(n) => setState({ engravingDate2: n })}
                            onSentimentChanged={(n) => setState({ engravingSentiment: n })}
                        />
                    </If>
                    <If condition={props.signaturePortraitsOnly !== true}>
                        <QuantitySelector
                            min={0}
                            max={10}
                            quantity={state.extraPrints}
                            onChange={(i) => setState({ extraPrints: i })}
                            leftLabel={"Extra Portraits"}
                            rightLabel={Option.ofNone()}
                        />
                        <QuantitySelector
                            min={0}
                            max={50}
                            quantity={state.extraCards}
                            onChange={(i) => setState({ extraCards: i })}
                            leftLabel={"Extra Cards"}
                            rightLabel={Option.ofNone()}
                        />
                    </If>
                </If>
                <div className="selector-container">
                    {state.error
                        .map((str) => <p className="error text-center padTop0">{str}</p>)
                        .defaultWith(() => (
                            <p>{String.fromCharCode(160)}</p>
                        ))}
                    <div className="add-to-cart">
                        <button type="button" className="btn blue" onClick={(e) => openUploadForm(e)}>
                            {"Select Portrait Images"}
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    const closeUploadForm = () =>
        setState({
            showUploadForm: false,
        });

    const openUploadForm: (e: React.MouseEvent<any>) => void = (e) => {
        e.preventDefault();

        const error: Option<string> = compute(() => {
            if (Option.isNone(state.frameVariant)) {
                return Option.ofSome("You must select a frame.");
            }

            if (Option.isNone(state.orientation)) {
                return Option.ofSome("You must select an orientation.");
            }

            if (Stores.Auth.requireCoupaPurchaseOrder && !state.purchaseOrderId) {
                return Option.ofSome(
                    "Your location is part of our HMIS+ integration. Please enter a valid Purchase Order ID from HMIS+."
                );
            } else if (Stores.Auth.requireCoupaPurchaseOrder && !purchaseOrderIdIsValid(state.purchaseOrderId)) {
                return Option.ofSome("You must enter a valid Purchase Order ID.");
            }

            return Option.ofNone();
        });

        setState({
            showUploadForm: error.isNone(),
            error: error,
        });
    };

    const addToCart = async (uploadedImages: Frontend.UploadedImage[]) => {
        if (Option.isNone(state.frameVariant)) {
            setState({ error: Option.ofSome("You must select a frame.") });

            return;
        }

        if (Option.isNone(state.orientation)) {
            setState({ error: Option.ofSome("You must select an orientation.") });

            return;
        }

        setState({ error: Option.ofNone<string>() });

        if (props.signaturePortraitsOnly !== true) {
            const packageType = compute(() => {
                switch (Stores.Auth.companySettings.companyName) {
                    case "sci":
                        const packageWithoutCards = sciPackageConversion.getPackageFromFrameSize(state.frameSize);
                        return sciPackageConversion.addCardsToPackage(packageWithoutCards);
                    case "messenger":
                        return messengerPackageConversion.getPackageFromFrameSize(state.frameSize);
                    default:
                        const defaultPackageType: Frontend.SCIPackageType = "CP1";

                        return defaultPackageType;
                }
            });

            Stores.Cart.addToCart({
                uuid: guid(),
                type: "portrait",
                customization: {
                    background: state.backgroundVariant,
                    frame: Option.get(state.frameVariant),
                    orientation: Option.get(state.orientation),
                    package: packageType,
                },
                images: uploadedImages,
                qty: {
                    packages: state.quantity,
                    cards: state.extraCards,
                    prints: state.extraPrints,
                },
                source: "quick",
                purchaseOrderId: state.purchaseOrderId || null,
                deceasedName: "",
                originalOrderId: null,
            });
        } else {
            const packageType = signaturePackageConversion.getPackageFromFrameSize(state.frameSize);

            Stores.Cart.addToCart({
                uuid: guid(),
                type: "signature-portrait",
                customization: {
                    background: state.backgroundVariant,
                    frame: Option.get(state.frameVariant),
                    orientation: Option.get(state.orientation),
                    package: packageType,
                    engravingName: state.engravingName,
                    engravingDate1: state.engravingDate1,
                    engravingDate2: state.engravingDate2,
                    engravingSentiment: state.engravingSentiment,
                },
                images: uploadedImages,
                qty: {
                    packages: state.quantity,
                },
                source: "quick",
                purchaseOrderId: state.purchaseOrderId || null,
                deceasedName: "",
                originalOrderId: null,
            });
        }

        if (uploadedImages.some((i) => i.tooSmall)) {
            ga("send", "event", {
                eventCategory: "Image",
                eventAction: "ignored-too-small",
            });
        }

        setLocation(Stores.Auth.companySettings.siteType === "ecommerce" ? Paths.cart.index : Paths.cart.name);
    };

    const title =
        props.signaturePortraitsOnly === true ? "Add Prestige Portrait to Cart" : "Add Canvas Portrait to Cart";
    const productTitle = props.signaturePortraitsOnly === true ? "Prestige Portrait" : "Canvas Portrait";
    const controls = (
        <div key={"all-controls"}>
            {buildPortraitFormControls()}
            {buildGenericFormControls()}
            <hr className="marBottom25" />
        </div>
    );

    return (
        <Observer>
            {() => (
                <section id="cart">
                    <PageHeader title={title} />
                    <div className="section white pure-g center-children">
                        <div className="pure-u-22-24">
                            <div className="pure-u-sm-13-24">
                                <VisualFrameSelector
                                    whitelabelSettings={Auth.companySettings}
                                    background={state.backgroundVariant}
                                    frame={state.frameVariant}
                                    orientation={state.orientation}
                                    onSelectFrame={selectFrame}
                                    isSignaturePortrait={props.signaturePortraitsOnly === true}
                                    displayAsPackage={
                                        Auth.companySettings.companyName === "sci" ||
                                        Auth.companySettings.companyName === "messenger"
                                    }
                                />
                                {/*<OutOfStockWarning frameType="vintage" size="11x14" />*/}
                                <VisualBackgroundSelector
                                    whitelabelSettings={Auth.companySettings}
                                    background={state.backgroundVariant}
                                    onSelectBackground={(bg) => setState({ backgroundVariant: bg })}
                                    allBackgrounds={backgrounds}
                                />
                            </div>
                            <div className="pure-u-sm-visible pure-u-sm-2-24" />
                            <div className="pure-u-1-1 pure-u-sm-9-24">
                                <div className="product main" itemScope={true} itemType="http://schema.org/Product">
                                    <h1 className="heading" itemProp="name">
                                        {`${state.frameSize} ${productTitle}`}
                                    </h1>
                                    {Auth.companySettings.siteType === "corporate" ? null : (
                                        <div>
                                            <hr style={{ margin: "25px 0" }} />
                                            <p>{Auth.companySettings.products[0].description}</p>
                                        </div>
                                    )}
                                    <hr style={{ margin: "25px 0" }} />
                                    {controls}
                                </div>
                            </div>
                        </div>
                    </div>
                    <ImageUploadDialog
                        isOpen={state.showUploadForm}
                        onClose={closeUploadForm}
                        orientation={state.orientation.defaultValue("portrait")}
                        onImagesUploaded={addToCart}
                    />
                </section>
            )}
        </Observer>
    );
}
