/**
 * Returns an array of numbers from the starting number to the given number.
 */
export function range(from: number, to: number, interval = 1): number[] {
    if (from > to) {
        // Recurse the function, adding 1 to each value to make it behave like it would normally, then just call .reverse on the array.
        return range(to + 1, from + 1).reverse();
    }

    const output: number[] = [];

    for (let i = from; i < to; i += interval) {
        output.push(i);
    }

    return output;
}
