import * as React from "react";
import { Repeater } from "shared/components/repeater";

type LoadingProps = {
    loading: true;
};

type LoadedProps<T> = {
    loading: false;
    ok: true;
    items: T[];
    children: (item: T, index: number) => React.ReactNode;
};

type LoadedWithErrorProps = {
    loading: false;
    ok: false;
    message: string;
};

type Props<T> = (LoadingProps | LoadedProps<T> | LoadedWithErrorProps) & {
    page: number;
    totalPages: number;
    onSelectPage: (newPage: number) => void;
    columnLabels: string[];
};

export function LoadableList<T>(props: Props<T>): JSX.Element {
    const [page, setPage] = React.useState(props.page.toString());
    const className = "loadable-list";

    if (props.loading) {
        return (
            <div className={className}>
                <progress />
            </div>
        );
    }

    if (!props.ok) {
        return (
            <div className={className}>
                <p className="error red">{props.message}</p>
            </div>
        );
    }

    const updatePage = (event: React.FormEvent<HTMLInputElement>) => {
        setPage(event.currentTarget.value);
    };

    const navigate = (event: React.FormEvent) => {
        event.preventDefault();

        const parsedPage = parseInt(page);

        props.onSelectPage(isNaN(parsedPage) ? 1 : parsedPage);
    };

    return (
        <div className={className}>
            <table className="full-width bordered">
                <thead>
                    <tr>
                        <Repeater source={props.columnLabels}>
                            {(column, index) => <th key={column + "-" + index}>{column}</th>}
                        </Repeater>
                    </tr>
                </thead>
                <tbody>
                    <Repeater source={props.items}>
                        {(item, index) => (
                            <React.Fragment key={"loaded-item-" + index}>{props.children(item, index)}</React.Fragment>
                        )}
                    </Repeater>
                </tbody>
            </table>
            <form onSubmit={navigate} className="page-selector text-input">
                <label>{"Page "}</label>
                <input type="number" value={page} onChange={updatePage} min={1} max={props.totalPages} />
                <label>{" of " + props.totalPages}</label>
                <button type="submit" className="btn blue small" disabled={page === props.page.toString()}>
                    {"Go"}
                </button>
            </form>
        </div>
    );
}
