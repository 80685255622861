import ga = require("ga");
import * as qs from "querystring";
import * as React from "react";
import { Paths } from "shared/paths";
import Box from "../../components/box";
import { CartMain as Container } from "./main";
import Progress from "react-svg-progress/dist";
import { useState, useUnauthorizedPrompt } from "client/hooks";
import { useLocation } from "wouter";
import { ApiError, Orders } from "client/http";
import { Auth, Cart } from "client/stores";
import { AmbiguousRate } from "kmmp/orders/v2";
import { getRateValue, showSaturdayRates } from "client/modules/shipping";
import { Link } from "wouter";
import { Rate } from "shipping-ups";
import { Sentry } from "client/modules/sentry";
import { MdCheck as CheckIcon } from "react-icons/md";
import { CONSTANTS } from "client/constants";
import { Option, compute } from "@nozzlegear/railway";
import { CreateOrderRequest } from "api";
import { mapToCreateOrderReqLineItem } from "shared/map-line-items";
import { Observer } from "mobx-react";
import { countImages } from "shared/images";

type Props = {};

interface State {
    error: Option<string>;
    placingOrder: boolean;
    selectedRate: Rate | AmbiguousRate;
    email: string;
    phone: string;
    placedBy: string;
    neededByDate: string;
    neededByTime: string;
}

export function CartDetailsPage(_: Props): JSX.Element {
    const shipping = Cart.shipping;
    let secondDayRate: Option<Rate> = Option.ofNone();
    let selectedRateForState: Rate | AmbiguousRate = shipping.ambiguous ? "Second Day Air" : shipping.rates[0];

    if (shipping.ambiguous === false) {
        const rate = shipping.rates.find((rate) => rate.Service.Code === "02");

        secondDayRate = compute<Option<Rate>>(() => {
            if (rate) return Option.ofSome(rate);

            return shipping.rates.length > 0 ? Option.ofSome(shipping.rates[0]) : Option.ofNone();
        });

        if (Option.isSome(secondDayRate)) {
            selectedRateForState = Option.get(secondDayRate);
        }
    }

    const handleUnauthorized = useUnauthorizedPrompt();
    const [location, setLocation] = useLocation();
    const [state, setState] = useState<State>({
        error: Option.ofNone(),
        selectedRate: selectedRateForState,
        placingOrder: false,
        email: "",
        neededByDate: "",
        neededByTime: "",
        phone: "",
        placedBy: "",
    });

    const getFamilyCharge = () => {
        if (typeof state.selectedRate === "string") {
            return 0;
        }

        if (Option.isNone(secondDayRate)) {
            return 0;
        }

        const result = getRateValue(state.selectedRate) - Option.get(secondDayRate.map(getRateValue));

        return result < 0 ? 0 : result;
    };

    async function handleContinue(e: React.SyntheticEvent) {
        e.preventDefault();

        let validationError = Option.ofNone<string>();

        if (state.placingOrder) {
            return;
        }

        setState({ placingOrder: true, error: Option.ofNone() });

        // Validate details
        if (!state.email || !/@.*\./i.test(state.email)) {
            validationError = Option.ofSome(`You must enter a valid email address.`);
        } else if (/@.*@.*/.test(state.email) || /\w+ +\w+/.test(state.email.trim())) {
            validationError = Option.ofSome(
                "It looks like you entered more than one email address. This is not currently supported, please use only one email address."
            );
        } else if (!state.placedBy) {
            validationError = Option.ofSome(`You must enter your full name.`);
        } else if (!state.phone) {
            validationError = Option.ofSome(`You must enter your phone number.`);
        }

        if (Option.isSome(validationError)) {
            setState({ placingOrder: false, error: validationError });

            return;
        }

        const shipping = compute<CreateOrderRequest.Shipping>(() => {
            const {
                address: { ...cartAddr },
                shipping,
            } = Cart;
            const addr: CreateOrderRequest.ShippingAddress = {
                city: cartAddr.city,
                country: cartAddr.country,
                name: cartAddr.name,
                state: cartAddr.state,
                street1: cartAddr.street1,
                street2: cartAddr.street2,
                street3: cartAddr.street3,
                zip: cartAddr.zip,
            };

            if (shipping.ambiguous === true) {
                const ambig: CreateOrderRequest.Shipping = {
                    ambiguous: true,
                    rate_service: state.selectedRate as AmbiguousRate,
                    ...addr,
                };

                return ambig;
            }

            const selectedRate = state.selectedRate as Rate;
            const unambig: CreateOrderRequest.Shipping = {
                ambiguous: false,
                // Only SCI has a family charge
                family_charge: Auth.companySettings.companyName === "sci" ? getFamilyCharge() : 0,
                rate_service: selectedRate.Service.Name,
                rate_service_code: selectedRate.Service.Code,
                rate_value: getRateValue(selectedRate),
                rate_carrier: "UPS",
                saturday_delivery_available: shipping.saturdayDeliveryAvailable,
                ...addr,
            };

            return unambig;
        });

        const sendConfirmation = Cart.prefersEmailReceipts.get() === true;
        const req: CreateOrderRequest = {
            clientVersion: CONSTANTS.VERSION,
            clientBuild: CONSTANTS.BUILD,
            line_items: Cart.items.map(mapToCreateOrderReqLineItem),
            details: {
                email: state.email.trim(),
                needed_by_date: state.neededByDate || null,
                needed_by_time: state.neededByTime || null,
                phone: state.phone,
                placed_by: state.placedBy,
                send_confirmation: sendConfirmation,
            },
            shipping: shipping,
        };
        const api = new Orders(Auth.token.value);

        ga("send", "event", {
            eventCategory: "Checkout",
            eventAction: "finished",
        });

        await api.V2.create(req)
            .iter((result) => {
                const totalImages = req.line_items.reduce((total, li) => countImages(li) + total, 0);

                // Capture a Sentry event if the user has somehow placed an order with more than one image per line item.
                if (totalImages > 1) {
                    Sentry.captureEvent({
                        message: "User placing order with extra images.",
                        extra: {
                            totalImages: totalImages,
                            totalLineItems: req.line_items.length,
                        },
                        user: {
                            id: Auth.session?._id,
                            username: Auth.session?.location_id,
                            location_name: Auth.session?.location_name,
                        },
                    });
                }

                // User's order has been placed. Send them to the confirmation page and reset the cart.
                const query = qs.stringify({
                    display_id: result.display_id,
                    email: result.email,
                    requestedEmail: sendConfirmation,
                });

                setLocation(`${Paths.confirmed.index}?${query}`);
                Cart.reset();
            })
            .iterError((error) => {
                const returnPath = Auth.companySettings.siteType === "ecommerce" ? Paths.cart.index : Paths.cart.name;

                if (error instanceof ApiError && error.unauthorized && handleUnauthorized(returnPath)) {
                    return;
                }

                const message =
                    error instanceof Error
                        ? error.message
                        : error instanceof ApiError
                        ? error.message
                        : "Failed to place order, please try again.";
                const sourceId = "a000e55a-a550-4d9c-80a9-25d5c11e43df";

                Sentry.captureException(error as any, {
                    extra: {
                        sourceId: sourceId,
                    },
                });
                setState({ placingOrder: false, error: Option.ofSome(`${message} (id: ${sourceId})`) });
            })
            .get();
    }

    const handleChangeRate = (e: React.FormEvent<HTMLInputElement>) => {
        const elValue = e.target["value"];
        let rate: AmbiguousRate | Rate;

        if (Cart.shipping.ambiguous === true) {
            rate = elValue;
        } else {
            const matchingRate = Cart.shipping.rates.find((rate) => rate.Service.Code === elValue);

            if (!matchingRate) {
                console.error("Failed to find matching rate with code " + elValue, Cart.shipping.rates);
                return;
            }

            rate = matchingRate;
        }

        setState({ selectedRate: rate });
    };

    const setSendConfirmation: (event: React.FormEvent<HTMLInputElement>) => void = (e) =>
        Cart.setPrefersEmailReceipts(e.currentTarget.checked);

    const setPlacedBy = (e: React.FormEvent<HTMLInputElement>) => setState({ placedBy: e.currentTarget.value });

    const setEmail = (e: React.FormEvent<HTMLInputElement>) => setState({ email: e.currentTarget.value });

    const setPhone = (e: React.FormEvent<HTMLInputElement>) => setState({ phone: e.currentTarget.value });

    const setNeededByDate = (e: React.FormEvent<HTMLInputElement>) => setState({ neededByDate: e.currentTarget.value });

    const setNeededByTime = (e: React.FormEvent<HTMLInputElement>) => setState({ neededByTime: e.currentTarget.value });

    const description = (
        <p>
            {`Enter your details here and then place your order. `}
            <strong>{`You will only receive an email confirmation if you check the consent box below.`}</strong>
        </p>
    );
    const { placingOrder, error, selectedRate } = state;
    const { placedBy, email, phone, neededByDate, neededByTime } = state;
    let rates: JSX.Element[];

    if (Cart.shipping.ambiguous === true) {
        let availableRates: string[] = CONSTANTS.AMBIGUOUS_RATES;

        if (showSaturdayRates()) {
            availableRates = [...availableRates, ...CONSTANTS.AMBIGUOUS_SATURDAY_RATES];
        }

        rates = availableRates.map((rate) => (
            <div key={rate} className="radio">
                <label htmlFor={`${rate}-radio`} className="cursor">
                    <input
                        type="radio"
                        id={`${rate}-radio`}
                        className="cursor"
                        style={{ marginRight: ".5rem" }}
                        name="shippingMethod"
                        value={rate}
                        checked={rate === selectedRate}
                        onChange={(e) => handleChangeRate(e)}
                    />
                    {`${rate} (UNKNOWN PRICE)`}
                </label>
            </div>
        ));
    } else {
        const selectedRate = state.selectedRate as Rate;

        rates = Cart.shipping.rates.map((rate) => (
            <div key={rate.Service.Code} className="radio">
                <label htmlFor={`${rate.Service.Code}-radio`} className="cursor">
                    <input
                        type="radio"
                        id={`${rate.Service.Code}-radio`}
                        className="cursor"
                        style={{ marginRight: ".5rem" }}
                        name="shippingMethod"
                        value={rate.Service.Code}
                        checked={rate.Service.Code === selectedRate.Service.Code}
                        onChange={(e) => handleChangeRate(e)}
                    />
                    {`$${getRateValue(rate).toFixed(2)} - ${rate.Service.Name}`}
                </label>
            </div>
        ));
    }

    let buttonText: JSX.Element;

    if (placingOrder) {
        buttonText = (
            <span>
                <Progress color={`#fff`} margin={`0 5px 0 0`} />
                {"Placing Order"}
            </span>
        );
    } else {
        buttonText = (
            <span>
                <CheckIcon className={`valign-sub left`} size={18} color={`#fff`} />
                {"Place Order"}
            </span>
        );
    }

    const footer = (
        <div className="align-children justify-space-between">
            {placingOrder ? (
                <span />
            ) : (
                <Link to={Paths.cart.shipping} className="btn">
                    Back
                </Link>
            )}
            <button type="button" className="btn blue" onClick={(e) => handleContinue(e)}>
                {buttonText}
            </button>
        </div>
    );

    const familyCharge =
        Auth.companySettings.companyName === "sci" ? Option.ofSome(getFamilyCharge().toFixed(2)) : Option.ofNone();

    return (
        <Container>
            <Box title={`Order Details.`} description={description} error={error.defaultValue("")} footer={footer}>
                <form id="detailsForm" onSubmit={(e) => handleContinue(e)}>
                    <div className="control-group">
                        <label htmlFor="name">{"Your Name"}</label>
                        <input
                            type="text"
                            name="name"
                            autoComplete="name"
                            value={placedBy || ""}
                            onChange={setPlacedBy}
                        />
                    </div>
                    <div className="control-group">
                        <label htmlFor="email">{"Your Email Address"}</label>
                        <input
                            type="text"
                            name="email"
                            autoComplete="work email"
                            value={email || ""}
                            onChange={setEmail}
                        />
                    </div>
                    <div className="control-group">
                        <label htmlFor="phone">{"Your Phone Number"}</label>
                        <input
                            type="tel"
                            name="phone"
                            autoComplete="work tel"
                            value={phone || ""}
                            onChange={setPhone}
                        />
                    </div>
                    <div className="pure-g">
                        <div className="pure-u-11-24">
                            <div className="control-group">
                                <label htmlFor="neededByDate">{"Date Needed By"}</label>
                                <input
                                    name="neededByDate"
                                    type="date"
                                    autoComplete="off"
                                    value={neededByDate || ""}
                                    onChange={setNeededByDate}
                                />
                            </div>
                        </div>
                        <div className="pure-u-2-24" />
                        <div className="pure-u-11-24">
                            <div className="control-group">
                                <label htmlFor="neededByTime">{"Time Needed By"}</label>
                                <input
                                    name="neededByTime"
                                    type="time"
                                    autoComplete="off"
                                    value={neededByTime || ""}
                                    onChange={setNeededByTime}
                                />
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="pure-g">
                        <div className="pure-u-16-24">
                            <div className="control-group">
                                <label>{"Shipping Method"}</label>
                            </div>
                            {rates}
                        </div>

                        {familyCharge
                            .map((charge) => (
                                <div className="pure-u-8-24">
                                    <div className="control-group text-right">
                                        <label>{"Additional Shipping to Charge Family:"}</label>
                                        <h4 style={{ textAlign: "right" }}>{`$${charge}`}</h4>
                                    </div>
                                </div>
                            ))
                            .defaultValue(null as any)}
                    </div>
                    <label className="cursor" style={{ marginTop: "1.5rem" }}>
                        <Observer>
                            {() => (
                                <input
                                    type={`checkbox`}
                                    style={{ marginRight: ".5rem" }}
                                    checked={Cart.prefersEmailReceipts.get() === true}
                                    onChange={setSendConfirmation}
                                />
                            )}
                        </Observer>
                        {`Please send me an order confirmation message to the email address I've entered above.`}
                    </label>
                </form>
            </Box>
        </Container>
    );
}
