import * as React from "react";

type FunctionalChildren = () => React.ReactNode | React.ReactNode[];

interface Props extends React.Props<any> {
    condition: boolean;
    children: React.ReactNode | React.ReactNode[] | FunctionalChildren;
}

/**
 * A component that always renders its children unless the condition is true.
 */
export function Unless(props: Props): JSX.Element | null {
    if (props.condition || !props.children) {
        return null;
    }

    return (typeof props.children === "function" ? props.children() : props.children) as any;
}
