import * as React from "react";
import { Auth } from "client/stores";

export const useWhitelabel = () => {
    const whitelabel = React.useMemo(() => {
        // TODO: replace the AuthStore's whitelabel with this hook
        return Auth.companySettings;
    }, []);

    return whitelabel;
};
