/**
 * Formats a date to a long date string, e.g. "October 20, 2021".
 */
export function formatDateString(date: Date | number): string {
    if (typeof date === "number") {
        date = new Date(date);
    }

    return date.toLocaleDateString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
    });
}

/**
 * Formats a date to 24-hour time string with timezone, e.g. "19:00 CDT"
 */
export function formatTimeString(date: Date | number): string {
    if (typeof date === "number") {
        date = new Date(date);
    }

    return date.toLocaleTimeString("en-US", {
        hour12: false,
        hour: "2-digit",
        minute: "2-digit",
        timeZoneName: "short",
    });
}

/**
 * Converts a string or number value to a Date object. If the value is false, null or undefined, this function will return null.
 */
export function toDateOrNull(date: Date | number | string | false | null | undefined): Date | null {
    if (date instanceof Date) return date;

    if (typeof date === "string") {
        date = Date.parse(date);
    }

    if (!date || isNaN(date)) return null;

    return new Date(date);
}

/**
 * Converts a string or Date value to a unix timestamp with milliseconds
 */
export function toTimestamp(date: Date | string): number {
    if (typeof date === "string") {
        return Date.parse(date);
    }

    return date.getTime();
}

/**
 * Converts a Date value to an ISO date string. Can be used as the value in type=date inputs.
 * @example 2022-06-17
 */
export function toISODateString(date: Date | string | number): string {
    if (!(date instanceof Date)) {
        date = new Date(date);
    }

    // Source: https://stackoverflow.com/a/35393125
    return date.toISOString().substring(0, 10);
}
