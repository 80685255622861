import * as React from "react";
import { Cart, Auth, CartStore } from "client/stores";
import {
    CheckoutPage as ReactCheckout,
    ShippingRate,
    LineItem as CheckoutLineItem,
    Card,
    Coupon,
    Address,
} from "@nozzlegear/react-checkout";
import { AsyncResult, Option, Result, compute } from "@nozzlegear/railway";
import { Paths } from "shared/paths";
import { CONSTANTS } from "client/constants";
import * as Pricing from "shared/price_calculator";
import { getShadowBoxImage, getSignatureJerseyImage } from "shared/images";
import { getLineItemDescription } from "shared/get-line-item-description";

require("@nozzlegear/react-checkout/dist/styles/react-checkout.css");

interface Props extends React.Props<any> {}

interface State {}

export class CheckoutPage extends React.Component<Props, State> {
    render() {
        const items: CheckoutLineItem[] = Cart.items.map((i) => {
            let thumbnailUrl: string;

            switch (i.type) {
                case undefined:
                case "portrait":
                case "signature-portrait":
                    const image = i.images[0];
                    thumbnailUrl = image.croppedImage ? image.croppedImage.thumbnailUrl : image.fullImage.srcUrl;
                    break;

                case "shadow-box":
                    thumbnailUrl = getShadowBoxImage(i.customization.color);
                    break;

                case "signature-jersey":
                    const packageType = i.customization.package;
                    const frame = i.customization.frame;
                    thumbnailUrl = getSignatureJerseyImage(packageType, frame);
                    break;

                default:
                    const neverItem: never = i;
                    console.error("Unhandled line item type", neverItem);
                    throw new Error("Unhandled line item type.");
            }

            return {
                title: getLineItemDescription(i),
                // TODO: Select proper product according to cart line item
                total: Pricing.calculate(i.qty.packages, Auth.companySettings.products[0]),
                quantity: i.qty.packages,
                thumbnailUrl: thumbnailUrl,
            };
        });
        const freeShippingRate: ShippingRate = {
            default: true,
            id: "free-ground-shiping",
            name: "Free Ground Shipping",
            value: 0,
        };
        const taxRate = 0.07;
        const subtotal = items.reduce((total, item) => total + item.total, 0);
        const taxTotal = subtotal * taxRate;

        return (
            <ReactCheckout
                allowCoupons={false}
                onCalculateShipping={(address) => AsyncResult.wrap<ShippingRate[]>([freeShippingRate])}
                onConfirmPayment={confirmPayment}
                items={items}
                siteName={Auth.companySettings.siteName}
                supportEmail={"support@" + Auth.companySettings.domain}
                backToCart={{
                    url: Paths.cart.index,
                    onClick: () => {
                        console.log("TODO: Navigate back to cart using router");
                    },
                }}
                totals={{
                    currency: "USD",
                    subTotal: subtotal,
                    taxTotal: taxTotal,
                    ultimateTotal: subtotal + taxTotal,
                    discountTotal: 0,
                }}
            />
        );
    }
}

type ConfirmPayment = (
    ...args: [Card, Option<ShippingRate>, Coupon[], Option<Address>]
) => AsyncResult<{ url: string }>;
const confirmPayment: ConfirmPayment = (...args) => {
    const [card, rate, _, address] = args;
    const [cardMonth, cardYear] = card.expiry.replace(" ", "").split("/");

    Stripe.setPublishableKey(CONSTANTS.STRIPE_PUBLIC_KEY);

    const validationError = compute<Option<string>>(() => {
        if (!Stripe.card.validateCardNumber(card.number)) {
            return Option.ofSome("Invalid card number.");
        }

        if (!Stripe.card.validateCVC(card.cvv)) {
            return Option.ofSome("Invalid card cvv/security code.");
        }

        if (!Stripe.validateExpiry(cardMonth, cardYear)) {
            return Option.ofSome("Invalid card expiration date.");
        }

        return Option.ofNone();
    });

    if (validationError.isSome()) {
        return AsyncResult.wrap(Result.ofError(validationError.get()));
    }

    const prom = new Promise<{ token: string }>((res, rej) => {
        Stripe.card.createToken(
            {
                number: card.number,
                name: card.name,
                exp: card.expiry,
                cvc: card.cvv,
            },
            (status, response) => {
                if (response.error) {
                    console.error("Stripe Response contained error:", { status, response });

                    return rej(
                        `Stripe Error ${response.error.type} [${response.error.code}]: ${response.error.message}`
                    );
                }

                return res({ token: response.id });
            }
        );
    });

    return AsyncResult.wrap(prom)
        .bind(({ token }) =>
            AsyncResult.wrap<{ url: string }>(Result.ofError(`Not yet implemented! Your card token is ${token}.`))
        )
        .iterError((error) => {
            const data =
                error instanceof Error
                    ? {
                          ...error,
                          message: error.message,
                          name: error.name,
                          stack: error.stack,
                      }
                    : { error };

            console.error("Failed to confirm payment: ", data);
        });
};
