import * as V1 from "kmmp/orders/v1";
import * as V2 from "kmmp/orders/v2";
import { CreateOrderRequest } from "api";

export type AnyLineItem = V1.LineItem | V2.LineItem | CreateOrderRequest.LineItem;
type ExclusiveCreateOrderKeys = Exclude<keyof CreateOrderRequest.LineItem, keyof V2.LineItem | keyof V1.LineItem>;
type ExclusiveV1Keys = Exclude<keyof V1.LineItem, keyof V2.LineItem | keyof CreateOrderRequest.LineItem>;
// The V2.LineItem does not currently contain any keys that are exclusive to it.
type RequiredV2Keys = keyof NonNullable<V2.LineItem>;

export function isLineItemVersion1(item: AnyLineItem): item is V1.LineItem {
    if (item === null || typeof item !== "object") return false;

    // TODO: these keys aren't actually unique. V2.PortraitLineItem, V2.SignaturePortraitLineItem and V2.ShadowBoxLineItem all have the "images" and "resubmitted" keys
    const uniqueKeys: ExclusiveV1Keys[] = ["images", "resubmitted"];
    const itemKeys = Object.getOwnPropertyNames(item);
    // Version 1 line items did not have a .type property
    const lineItemType = item["type"] as string | undefined;

    return lineItemType === undefined && uniqueKeys.every((key) => itemKeys.indexOf(key) >= 0);
}

export function isLineItemVersion2(item: AnyLineItem): item is V2.LineItem {
    if (item === null || typeof item !== "object") return false;

    // Because the V2 item does not contain any keys that are exclusive to it, we check that it is not either of the other two items,
    // and that it has all of its required (non nullable/undefined) keys.
    const requiredKeys: RequiredV2Keys[] = [
        "type",
        "customization",
        "deceasedName",
        "originalOrderDate",
        "originalOrderId",
        "purchaseOrderId",
        "quantities",
        "source",
        "uuid",
    ];
    const itemKeys = Object.getOwnPropertyNames(item);

    return (
        !isLineItemVersion1(item) &&
        !isCreateOrderRequestLineItem(item) &&
        requiredKeys.every((key) => itemKeys.indexOf(key) >= 0)
    );
}

export function isCreateOrderRequestLineItem(item: AnyLineItem): item is CreateOrderRequest.LineItem {
    if (item === null || typeof item !== "object") return false;

    const uniqueKeys: ExclusiveCreateOrderKeys[] = [
        "original_order_id",
        "purchase_order_id",
        "special_instructions",
        "subject_name",
    ];
    const itemKeys = Object.getOwnPropertyNames(item);

    return uniqueKeys.every((key) => itemKeys.indexOf(key) >= 0);
}
