import { BrowserConstants, PageConfig } from "kmmp";
import { compute } from "@nozzlegear/railway";

declare const CLIENT_VERSION: string;
declare const CLIENT_BUILD: string;

/**
 * Gets the page config object, which is injected by the server into the DOM during page load.
 */
export const PAGE_CONFIG: PageConfig = compute<PageConfig>(() => {
    const el = document.getElementById("page-config");

    if (!el) {
        throw new Error("Could not find #page-config element, unable to parse page config.");
    }

    return JSON.parse(el.innerHTML);
});

/**
 * Gets the browser constants object, which is injected by the server into the DOM during page load.
 */
export const CONSTANTS: BrowserConstants = {
    ...PAGE_CONFIG.constants,
    AUTH_STORAGE_NAME: "KMMP_JWT",
    BLUE_GREY: "526273",
    CART_COOKIE_NAME: "KMMP_Cart_V1",
    CURRENT_LINE_ITEM_NAME: "KMMP_LineItem_V1",
    PREFERS_EMAIL_RECEIPTS_COOKIE_NAME: "KMMP_Prefers_Email_Receipts_V1",
    // Use the CLIENT_VERSION and CLIENT_BUILD constants which are injected by the build process.
    VERSION: CLIENT_VERSION,
    BUILD: CLIENT_BUILD,
};
