import * as React from "react";

type Props = React.PropsWithChildren<{
    title: string;
    message?: string;
    extraClasses?: string;
}>;

export function Warning(props: Props): JSX.Element {
    if (props.message && props.children) {
        console.warn("Warning component given both a message prop and children. Children will not be rendered.");
    }

    const children =
        typeof props.message === "string" ? (
            <p>{props.message}</p>
        ) : typeof props.children === "string" ? (
            <p>{props.children}</p>
        ) : (
            props.children
        );
    const extraClasses = props.extraClasses || "";

    return (
        <div className={"warning" + extraClasses}>
            <h4>{props.title}</h4>
            {children}
        </div>
    );
}
