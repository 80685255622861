import * as V1 from "kmmp/orders/v1";
import * as V2 from "kmmp/orders/v2";
import * as Frontend from "kmmp/frontend";
import { Auth } from "client/stores/auth";
import { Result } from "@nozzlegear/railway";
import { mapToFrontendLineItem } from "shared/map-line-items";
import { getShadowBoxImage, getSignatureJerseyImage } from "shared/images";
import * as messenger from "shared/package-types/messenger-portraits";
import * as sci from "shared/package-types/sci-portraits";
import * as signaturePortraits from "shared/package-types/signature-portraits";

interface LineItemChanges {
    cards: number;
    prints: number;
    frameSize: Frontend.FrameSize;
    specialInstructions: string | null;
    purchaseOrderId: string | null;
}

export class LineItemUtils {
    constructor(lineItem: V1.LineItem | V2.LineItem) {
        this.lineItem = mapToFrontendLineItem(lineItem);
    }

    public readonly lineItem: Frontend.LineItem;

    get previewImageSrc(): string {
        switch (this.lineItem.type) {
            case undefined:
            case "portrait":
            case "signature-portrait":
                const img = this.lineItem.images[0];

                return !!img.croppedImage ? img.croppedImage.thumbnailUrl : img.fullImage.thumbnailUrl;

            case "shadow-box":
                return getShadowBoxImage(this.lineItem.customization.color);

            case "signature-jersey":
                const packageType = this.lineItem.customization.package;
                const frame = this.lineItem.customization.frame;

                return getSignatureJerseyImage(packageType, frame);

            default:
                const neverItem: never = this.lineItem;
                console.error("Unhandled line item type", neverItem);
                throw new Error("Unhandled line item type.");
        }
    }

    get supportsPortraitCards(): boolean {
        // Only SCI users can reorder portrait cards
        if (Auth.companySettings.companyName !== "sci") {
            return false;
        }

        switch (this.lineItem.type) {
            case undefined:
            case "portrait":
                switch (this.lineItem.customization.package) {
                    case "CP1":
                    case "CP15":
                    case "CP2":
                    case "CP25":
                    case "CP3":
                    case "CP35":
                        return true;

                    default:
                        return false;
                }

            case "shadow-box":
            case "signature-jersey":
            case "signature-portrait":
                return false;

            default:
                const neverItem: never = this.lineItem;
                console.error("Unhandled line item type", neverItem);
                throw new Error("Unhandled line item type.");
        }
    }

    get supportsSpecialInstructions(): boolean {
        // Only portrait items can have special instructions
        switch (this.lineItem.type) {
            case undefined:
            case "portrait":
            case "signature-portrait":
            case "shadow-box":
                return true;

            case "signature-jersey":
                return false;

            default:
                const neverItem: never = this.lineItem;
                console.error("Unhandled line item type", neverItem);
                throw new Error("Unhandled line item type.");
        }
    }

    get supportsChangingFrameSize(): boolean {
        switch (this.lineItem.type) {
            case undefined:
            case "shadow-box":
            case "signature-jersey":
                return false;

            case "portrait":
            case "signature-portrait":
                return true;

            default:
                const neverItem: never = this.lineItem;
                console.error("Unhandled line item type", neverItem);
                throw new Error("Unhandled line item type.");
        }
    }

    mapUploadedImageInstructions(
        image: Frontend.UploadedImage,
        newInstructions: string | null
    ): Frontend.UploadedImage {
        return {
            ...image,
            instructions: newInstructions ? "Updated instructions: " + newInstructions : image.instructions,
        };
    }

    private tryMapNewPortraitLineItem: (
        changes: LineItemChanges,
        item: Frontend.PortraitLineItem
    ) => Result<Frontend.LineItem> = (changes, li) => {
        if (changes.cards === 0 && changes.prints === 0) {
            return Result.ofError(`You must select at least one item.`);
        }

        // Don't set the package to CP15, CP25 or CP35 even if there are both cards and prints
        // A CP15/CP25/CP35 is specifically 1 print and 5 cards.
        let packageType: Exclude<Frontend.PortraitCustomization["package"], "CP15" | "CP25" | "CP35">;

        if (changes.prints === 0 || (li.customization.package === "CP4" && !this.supportsChangingFrameSize)) {
            // Either no prints have been selected, or the original order was strictly cards and the size wasn't changed.
            packageType = "CP4";
        } else if (li.customization.package === "CP4" || this.supportsChangingFrameSize) {
            // Either the user is changing the size of the package, or the original order was just cards and now they're ordering prints
            packageType = messenger.isMessengerPackage(li.customization.package)
                ? messenger.getPackageFromFrameSize(changes.frameSize)
                : sci.getPackageFromFrameSize(changes.frameSize);
        } else {
            packageType = messenger.isMessengerPackage(li.customization.package)
                ? li.customization.package
                : sci.removeExtraCardsFromPackage(li.customization.package);
        }

        // TODO: Rework this to work with multiple line items again.
        // Really just depends on the PDF displaying multiple line items instead of one single item.
        return Result.ofValue<Frontend.LineItem>({
            type: "portrait",
            source: "reorder",
            customization: {
                ...li.customization,
                package: packageType,
            },
            qty: {
                packages: 0,
                cards: changes.cards,
                prints: changes.prints,
            },
            purchaseOrderId: Auth.requireCoupaPurchaseOrder ? changes.purchaseOrderId : null,
            originalOrderId: li.originalOrderId || null,
            deceasedName: li.deceasedName,
            uuid: li.uuid,
            images: li.images.map((i) => this.mapUploadedImageInstructions(i, changes.specialInstructions)),
        });
    };

    private tryMapNewSignaturePortraitLineItem: (
        changes: LineItemChanges,
        li: Frontend.SignaturePortraitLineItem
    ) => Result<Frontend.LineItem> = (changes, li) => {
        if (changes.prints === 0) {
            return Result.ofError("You must select at least one item.");
        }

        const packageType = this.supportsChangingFrameSize
            ? signaturePortraits.getPackageFromFrameSize(changes.frameSize)
            : li.customization.package;

        return Result.ofValue<Frontend.LineItem>({
            type: "signature-portrait",
            source: "reorder",
            customization: {
                package: packageType,
                frame: li.customization.frame,
                orientation: li.customization.orientation,
                background: li.customization.background,
                engravingName: li.customization.engravingName,
                engravingDate1: li.customization.engravingDate1,
                engravingDate2: li.customization.engravingDate2,
                engravingSentiment: li.customization.engravingSentiment,
            },
            qty: {
                packages: changes.prints,
            },
            purchaseOrderId: Auth.requireCoupaPurchaseOrder ? changes.purchaseOrderId : null,
            originalOrderId: li.originalOrderId || null,
            deceasedName: li.deceasedName,
            uuid: li.uuid,
            images: li.images.map((i) => this.mapUploadedImageInstructions(i, changes.specialInstructions)),
        });
    };

    private tryMapNewSignatureJerseyLineItem: (
        changes: LineItemChanges,
        li: Frontend.SignatureJerseyLineItem
    ) => Result<Frontend.LineItem> = (changes, li) => {
        if (changes.prints === 0) {
            return Result.ofError("You must select at least one item.");
        }

        return Result.ofValue<Frontend.LineItem>({
            type: "signature-jersey",
            source: "reorder",
            customization: {
                package: li.customization.package,
                frame: li.customization.frame,
                engravingName: li.customization.engravingName,
                engravingDate1: li.customization.engravingDate1,
                engravingDate2: li.customization.engravingDate2,
                engravingSentiment: li.customization.engravingSentiment,
            },
            qty: {
                packages: changes.prints,
            },
            purchaseOrderId: Auth.requireCoupaPurchaseOrder ? changes.purchaseOrderId : null,
            originalOrderId: li.originalOrderId || null,
            deceasedName: li.deceasedName,
            uuid: li.uuid,
        });
    };

    private tryMapNewShadowBoxLineItem: (
        changes: LineItemChanges,
        li: Frontend.ShadowBoxLineItem
    ) => Result<Frontend.LineItem> = (changes, li) => {
        if (changes.prints === 0) {
            return Result.ofError("You must select at least one item.");
        }

        return Result.ofValue<Frontend.LineItem>({
            type: "shadow-box",
            source: "reorder",
            customization: {
                package: li.customization.package,
                color: li.customization.color,
                engravingName: li.customization.engravingName,
                engravingDate1: li.customization.engravingDate1,
                engravingDate2: li.customization.engravingDate2,
                engravingSentiment: li.customization.engravingSentiment,
            },
            qty: {
                packages: changes.prints,
            },
            purchaseOrderId: Auth.requireCoupaPurchaseOrder ? changes.purchaseOrderId : null,
            originalOrderId: li.originalOrderId || null,
            deceasedName: li.deceasedName,
            uuid: li.uuid,
            images: li.images.map((i) => this.mapUploadedImageInstructions(i, changes.specialInstructions)),
        });
    };

    tryMapNewLineItem: (changes: LineItemChanges) => Result<Frontend.LineItem> = (changes) => {
        const li = this.lineItem;

        switch (li.type) {
            case undefined:
            case "portrait":
                return this.tryMapNewPortraitLineItem(changes, li);

            case "signature-portrait":
                return this.tryMapNewSignaturePortraitLineItem(changes, li);

            case "signature-jersey":
                return this.tryMapNewSignatureJerseyLineItem(changes, li);

            case "shadow-box":
                return this.tryMapNewShadowBoxLineItem(changes, li);

            default:
                const neverItem: never = li;
                const message = `Unhandled line item type`;

                console.error(message, neverItem);

                throw new Error(`Unhandled line item type`);
        }
    };
}
