import * as React from "react";

type FunctionalChildren = () => React.ReactNode | React.ReactNodeArray;

interface Props extends React.ClassAttributes<{}> {
    condition: boolean;
    children: React.ReactNode | React.ReactNodeArray | FunctionalChildren;
}

/**
 * A component that only renders its children if the condition is true.
 */
export function If(props: Props): JSX.Element | null {
    if (!props.condition || !props.children) {
        return null;
    }

    return (typeof props.children === "function" ? props.children() : props.children) as any;
}
