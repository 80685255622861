import * as React from "react";
import { Dialog } from "@nozzlegear/react-win-dialog";

export interface ClosedProps {
    open: false;
}

export interface OpenProps {
    open: true;
    locationId: string;
    userPassword?: string;
}

export type Props = (ClosedProps | OpenProps) & {
    onClose: () => void;
};

export function UserCreatedDialog(props: Props): JSX.Element {
    const Content = () => {
        if (!props.open) {
            return <React.Fragment />;
        }

        const userCreatedMessage = `Location ${props.locationId} has been created, they may now sign in and place orders.`;

        if (!props.userPassword) {
            return <p>{userCreatedMessage}</p>;
        }

        return (
            <div>
                <p>
                    {userCreatedMessage +
                        " Copy their password below -- punctuation and casing (upper-case and lower-case) are important and must match exactly."}
                </p>
                <p>
                    {"This password will only be displayed once, it cannot be viewed or recovered by an administrator."}
                </p>
                <input type="text" className="user-password" value={props.userPassword} onChange={() => {}} readOnly />
            </div>
        );
    };

    return (
        <Dialog
            id="user-created-dialog"
            title="User created"
            open={props.open}
            secondaryText="Close"
            onSecondaryClick={props.onClose}
        >
            <Content />
        </Dialog>
    );
}
