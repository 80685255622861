import * as V1 from "kmmp/orders/v1";
import * as V2 from "kmmp/orders/v2";
import * as Frontend from "kmmp/frontend";
import { v4 as guid } from "uuid";
import { CreateOrderRequest } from "api";

/**
 * Maps database-style images (camelCased) to request-style images (snake_cased).
 */
export function mapToRequestImage(input: V1.Image | V2.Image): CreateOrderRequest.Image {
    return {
        cropped_image: input.croppedImage
            ? {
                  file_name: input.croppedImage.filename,
                  full_azure_url: input.croppedImage.fullAzureUrl,
                  thumbnail_azure_url: input.croppedImage.thumbnailAzureUrl,
              }
            : null,
        full_image: {
            file_name: input.fullImage.filename,
            full_azure_url: input.fullImage.fullAzureUrl,
            thumbnail_azure_url: input.fullImage.thumbnailAzureUrl,
        },
        special_instructions: input.specialInstructions,
        too_small: input.tooSmall,
        uuid: input.uuid || "",
    };
}

/**
 * Maps database images to the image objects used by the frontend.
 */
export function mapToFrontendImage(input: V1.Image | V2.Image): Frontend.UploadedImage {
    return {
        croppedImage: input.croppedImage
            ? {
                  fileName: input.croppedImage.filename,
                  srcUrl: input.croppedImage.fullAzureUrl,
                  thumbnailUrl: input.croppedImage.thumbnailAzureUrl,
              }
            : null,
        fullImage: {
            fileName: input.fullImage.filename,
            srcUrl: input.fullImage.fullAzureUrl,
            thumbnailUrl: input.fullImage.thumbnailAzureUrl,
        },
        instructions: input.specialInstructions || "",
        tooSmall: input.tooSmall,
        uuid: input.uuid || guid(),
    };
}
