import * as qs from "qs";
import { Auth } from "../stores/auth";
import { Paths } from "shared/paths";
import { useLocation } from "wouter";

interface RedirectOptions {
    returnToCurrentPath: boolean;
    replace: boolean;
}

export function useAuth() {
    const [location, setLocation] = useLocation();
    const defaults: RedirectOptions = {
        returnToCurrentPath: true,
        replace: true,
    };
    const redirectToLogin = (options = defaults) => {
        let url = Paths.auth.login;

        if (options.returnToCurrentPath) {
            const query = qs.stringify({
                redirect: location,
                qs: window.location.search.replace(/^\?/i, ""),
            });
            url = url + "?" + query;
        }

        setLocation(url, { replace: options.replace });
    };

    return {
        authenticated: !Auth.sessionIsInvalid,
        isAdmin: Auth.isAdmin(),
        redirectToLogin: redirectToLogin,
        canPurchasePilotProducts: Auth.canPurchasePilotProducts,
    };
}
