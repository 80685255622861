import { Address } from "shipping-ups";
import { hasUnicode } from "./has-unicode";

interface NormalizationResult {
    stillHasUnicode: boolean;
    normalizedString: string;
}

interface AddressNormalizationResult {
    stillHasUnicode: boolean;
    normalizedAddress: Address;
}

const unicodeMap = new Map<string, string>([
    ["É", "E"],
    ["È", "E"],
    ["Î", "I"],
    ["Ô", "O"],
    ["–", "-"],
    ["’", "'"],
]);

/**
 * Takes an input string and attempts to normalize unicode characters into ascii characters. Only normalizes a subset of unicode
 * characters that have been hardcoded into the function.
 */
export function tryNormalizeUnicode(str: string): NormalizationResult {
    let result: string = "";

    for (let i = 0; i < str.length; i++) {
        const char = str[i];
        const replacementChar = unicodeMap.get(char);

        if (replacementChar) {
            result += replacementChar;
        } else {
            result += char;
        }
    }

    return {
        normalizedString: result,
        stillHasUnicode: hasUnicode(result),
    };
}

/**
 * Takes an address object and attempts to normalize unicode characters in its properties. Only normalizes a subset of unicode
 * characters that have been hardcoded into the function.
 */
export function tryNormalizeUnicodeInAddress(address: Address): AddressNormalizationResult {
    const maybeNormalize = (value?: string) => (value ? tryNormalizeUnicode(value) : undefined);

    const line1 = tryNormalizeUnicode(address.address_line_1);
    const line2 = maybeNormalize(address.address_line_2);
    const line3 = maybeNormalize(address.address_line_3);
    const name = tryNormalizeUnicode(address.name);
    const company = maybeNormalize(address.company);

    return {
        normalizedAddress: {
            ...address,
            address_line_1: line1.normalizedString,
            address_line_2: line2?.normalizedString,
            address_line_3: line3?.normalizedString,
            name: name.normalizedString,
            company: company?.normalizedString,
        },
        stillHasUnicode: [
            line1.stillHasUnicode === true,
            line2?.stillHasUnicode === true,
            line3?.stillHasUnicode === true,
            name.stillHasUnicode === true,
            company?.stillHasUnicode === true,
        ].some((v) => v === true),
    };
}
