import ga = require("ga");
import * as qs from "qs";
import * as React from "react";
import Box from "../../components/box";
import Progress from "react-svg-progress/dist";
import { Paths } from "shared/paths";
import { ApiError, AuthHttpClient } from "client/http";
import { Auth as AuthStore } from "../../stores";
import { Option } from "@nozzlegear/railway";
import { Maybe } from "@nozzlegear/railway-react";
import useLocation from "wouter/use-location";
import { If, Unless } from "shared/components";

export interface IProps extends React.Props<any> {}

type Props = { [key: string]: any };

export interface State {
    error: Option<string>;
    loading: boolean;
    username: string;
    password: string;
}

export function AuthPage(props: Props): JSX.Element {
    const [state, setState] = React.useState<State>({
        error: Option.ofNone(),
        loading: false,
        username: "",
        password: "",
    });
    const [location, setLocation] = useLocation();

    const setUsername = (event: React.FormEvent<HTMLInputElement>) => {
        setState({
            ...state,
            username: event.currentTarget.value,
        });
    };
    const setPassword = (event: React.FormEvent<HTMLInputElement>) => {
        setState({
            ...state,
            password: event.currentTarget.value,
        });
    };
    async function handleSignIn(e: React.SyntheticEvent) {
        e.preventDefault();

        if (state.loading) {
            return;
        }

        const { username, password } = state;
        let token: string;

        if (!username) {
            setState({
                ...state,
                error: Option.ofSome("You must enter your username."),
                loading: false,
            });
            return;
        }

        if (!password) {
            setState({
                ...state,
                error: Option.ofSome("You must enter your password."),
                loading: false,
            });

            return;
        }

        setState({
            ...state,
            error: Option.ofNone(),
            loading: true,
        });

        try {
            const result = await new AuthHttpClient().login({ username, password });

            token = result.token;
        } catch (_e) {
            const e: ApiError = _e;

            setState({
                ...state,
                error: Option.ofSome(e.message),
                loading: false,
            });

            if (e.unauthorized) {
                ga("send", "event", {
                    eventCategory: "Auth",
                    eventAction: "unauthorized",
                });
            }

            return;
        }

        AuthStore.login(token);
        ga("send", "userId", AuthStore.session!.location_id);
        ga("send", "event", {
            eventCategory: "Auth",
            eventAction: "success",
        });

        const query = qs.parse(window.location.search.replace(/^\?/i, "")) as {
            redirect?: string;
            qs?: string;
        };

        if (query.redirect) {
            const path = query.redirect;
            const qs = query.qs || "";

            setLocation(`${path}?${qs}`);
        } else {
            setLocation(Paths.home.index);
        }
    }

    const footer = (
        <div>
            <Maybe value={state.error}>{(err) => <p className="red error">{err}</p>}</Maybe>
            <button className="btn blue" onClick={handleSignIn}>
                <If condition={state.loading}>
                    <Progress key={`loading`} color={"#fff"} margin={`0 5px 0 0`} />
                    {" Signing In"}
                </If>
                <Unless condition={state.loading}>{"Sign In"}</Unless>
            </button>
        </div>
    );

    return (
        <section id="login">
            <div className="pure-g center-children">
                <div className="pure-u-1-1 pure-u-lg-12-24">
                    <Box
                        title="Sign in to your location account."
                        description={`Type the username and password associated with your location's account.`}
                        footer={<div />}
                    >
                        <form onSubmit={handleSignIn}>
                            <div className="control-group">
                                <label>{"Location #"}</label>
                                <input type="text" value={state.username} onChange={setUsername} />
                            </div>
                            <div className="control-group">
                                <label>{"Password"}</label>
                                <input type="password" value={state.password} onChange={setPassword} />
                            </div>
                            {footer}
                        </form>
                    </Box>
                </div>
            </div>
        </section>
    );
}
