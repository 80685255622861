import * as React from "react";
import * as Frontend from "kmmp/frontend";
import { If } from "shared/components/if";
import { getLineItemDescription } from "shared/get-line-item-description";
import { range } from "shared/range";

interface CanChangeFrameSizeProps {
    frameSize: Frontend.FrameSize;
    canChangeFrameSize: true;
    onChangeFrameSize: (newSize: Frontend.FrameSize) => void;
}

interface CannotChangeFrameSizeProps {
    canChangeFrameSize: false;
}

type Props = (CannotChangeFrameSizeProps | CanChangeFrameSizeProps) & {
    item: Frontend.LineItem | "CP4";
    quantity: number;
    previewImageSrc: string;
    isLastItem: boolean;
    onChangeQuantity: (qty: number) => void;
};

export function LineItem(this: void, props: Props): JSX.Element {
    const description = React.useMemo(() => {
        if (props.item === "CP4") return "CP4 Portrait Cards";

        switch (props.item.type) {
            case undefined:
            case "portrait":
                const customization = props.item.customization;
                // Cards need to be explicitly reordered, they cannot be reordered as a package.
                // Therefore, convert the CP15, CP25 and CP35 package options to their cardless counterparts.
                let packageType: Exclude<typeof customization.package, "CP15" | "CP25" | "CP35">;

                switch (customization.package) {
                    case "CP15":
                        packageType = "CP1";
                        break;

                    case "CP25":
                        packageType = "CP2";
                        break;

                    case "CP35":
                        packageType = "CP3";
                        break;

                    default:
                        packageType = customization.package;
                        break;
                }

                switch (packageType) {
                    case "CP1":
                    case "CP2":
                    case "CP3":
                        return `${packageType} ${customization.frame.name} ${customization.orientation} with ${customization.background.name} background, no cards.`;

                    case "CP4":
                        return "CP4 Portrait Cards";

                    case "211":
                    case "212":
                    case "213":
                        return `${customization.frame.name} ${customization.orientation} with ${customization.background.name} background.`;

                    default:
                        const t: never = packageType;
                        throw new Error(`Unrecognized type ${t}.`);
                }

            case "shadow-box":
            case "signature-jersey":
            case "signature-portrait":
                return getLineItemDescription(props.item);

            default:
                const neverItem: never = props.item;
                console.error("Unhandled line item type", neverItem);
                throw new Error("Unhandled line item type.");
        }
    }, [props.item]);

    // Event handlers
    const setQty = (e: React.FormEvent<HTMLSelectElement>) => {
        props.onChangeQuantity(parseInt(e.currentTarget.value));
    };
    const setFrameSize = (e: React.FormEvent<HTMLSelectElement>) => {
        if (props.canChangeFrameSize) {
            props.onChangeFrameSize(e.currentTarget.value as any);
        }
    };

    const maxRange = props.item === "CP4" ? 51 : 10;

    return (
        <div
            className={`reorder-line-item align-children`}
            style={{
                borderBottom: props.isLastItem ? undefined : `1px solid #ccc`,
                padding: `10px 0`,
            }}
        >
            <div style={{ padding: `0 10px 0 0` }}>
                <img src={props.previewImageSrc} alt={"Line item preview"} style={{ maxHeight: 75, maxWidth: 75 }} />
            </div>
            <div className="description">{description}</div>
            <div>
                <If condition={props.canChangeFrameSize}>
                    <select
                        className="package-selector"
                        value={props.canChangeFrameSize ? props.frameSize : "16x20"}
                        onChange={setFrameSize}
                    >
                        <option value="16x20">{"16x20"}</option>
                        <option value="11x14">{"11x14"}</option>
                        <option value="8x10">{"8x10"}</option>
                    </select>
                </If>
                <select className="quantity-selector" onChange={setQty} value={props.quantity}>
                    {range(0, maxRange).map((i) => (
                        <option key={i} value={i}>
                            {i}
                        </option>
                    ))}
                </select>
                {/* <input
                        id={item.uuid}
                        name={item.uuid}
                        type={`checkbox`}
                        checked={this.state.checked_item_uuids.some(checkedId => checkedId === item.uuid)}
                        onChange={e => this.checkItem(e, item.uuid)} /> */}
            </div>
        </div>
    );
}
