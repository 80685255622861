import * as React from "react";
import * as Frontend from "kmmp/frontend";
import { Observer } from "mobx-react";
import { ImageSidebarPreview } from "./image-sidebar-preview";
import { Orientation } from "kmmp";
import { UploadInProgressForm } from "./upload-in-progress-form";
import { CropImageForm } from "./crop-image-form";
import { MdAdd as PlusIcon } from "react-icons/md";
import { CropDisabledForm } from "./crop-disabled-form";

interface Props extends React.Props<any> {
    orientation: Orientation;
    isUploading: boolean;
    selectedImage: Frontend.PreviewImage;
    images: Frontend.PreviewImage[];
    onSelectImage: (image: Frontend.PreviewImage) => void;
    onCrop: (data: Blob) => void;
    onSetNotes: (notes: string) => void;
    onRevertCrop: () => void;
    onDeleteImage: () => void;
    allowMultipleImages: boolean;
    openFilePicker: () => void;
}

interface State {
    showCropper: boolean;
}

export class SelectedImageForm extends React.Component<Props, State> {
    state: State = {
        showCropper: false,
    };

    selectImage: (image: Frontend.PreviewImage) => () => void = (image) => () => {
        this.props.onSelectImage(image);
    };

    openFilePicker: (evt: React.FormEvent<any>) => void = (evt) => {
        evt.preventDefault();
        this.props.openFilePicker();
    };

    enableCrop: () => void = () => {
        // At the request of the art department, the cropper is now disabled permanently
        // this.setState({ showCropper: true });
    };

    disableCrop: () => void = () => {
        // Reset the crop so it doesn't get uploaded accidentally
        this.props.onRevertCrop();
        this.setState({ showCropper: false });
    };

    render(): JSX.Element {
        const { images, selectedImage, isUploading, orientation, allowMultipleImages, ...props } = this.props;
        // const { showCropper } = this.state;
        // At the request of the art department, the cropper is now disabled permanently
        const showCropper = false;
        const previews = images.map((i) => {
            return <ImageSidebarPreview key={i.uuid} image={i} onSelected={this.selectImage(i)} />;
        });
        let cropper: JSX.Element;

        if (selectedImage && isUploading) {
            const totalUploaded = images.reduce((result, image) => (result = result + (image.uploaded ? 1 : 0)), 0);

            cropper = <UploadInProgressForm totalImages={images.length} totalUploaded={totalUploaded} />;
        } else if (selectedImage && showCropper) {
            cropper = (
                <CropImageForm
                    image={selectedImage}
                    orientation={orientation}
                    onCrop={props.onCrop}
                    onSetNotes={props.onSetNotes}
                    onRevertCrop={props.onRevertCrop}
                    onDeleteImage={props.onDeleteImage}
                    onDisableCrop={this.disableCrop}
                />
            );
        } else if (selectedImage) {
            cropper = (
                <CropDisabledForm
                    image={selectedImage}
                    onSetNotes={props.onSetNotes}
                    onDeleteImage={props.onDeleteImage}
                    onEnableCrop={this.enableCrop}
                />
            );
        } else {
            cropper = <div className="cropper" />;
        }

        return (
            <Observer>
                {() => (
                    <form id="image-editor" className="image-editor">
                        <div className="preview">
                            <div className="images">{previews}</div>
                            {!allowMultipleImages ? null : (
                                <div className="button">
                                    <button
                                        className="btn btn-default"
                                        type="button"
                                        onClick={this.openFilePicker}
                                        disabled={isUploading}
                                    >
                                        <PlusIcon size={16} />
                                        {" Add Image"}
                                    </button>
                                </div>
                            )}
                        </div>
                        {cropper}
                    </form>
                )}
            </Observer>
        );
    }
}
