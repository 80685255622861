import { BaseClass } from "client/http/base";
import { ListAnnouncements, CreateOrModifyAnnouncement } from "api";

export class AnnouncementsClient extends BaseClass {
    constructor(authToken: string) {
        super("/api/v1/announcements", authToken);
    }

    public listAnnouncements = (options: ListAnnouncements.Request) => {
        const query = new URLSearchParams();
        query.append("page", options.page.toString());

        if (options.limit) {
            query.append("limit", options.limit.toString());
        }

        return this.sendRequest<ListAnnouncements.Response>("", "GET", {
            qs: query,
        });
    };

    public createAnnouncement = (data: CreateOrModifyAnnouncement.Request) => {
        return this.sendRequest<CreateOrModifyAnnouncement.Response>("", "POST", {
            body: data,
        });
    };

    public modifyAnnouncement = (id: string, rev: string, data: CreateOrModifyAnnouncement.Request) => {
        return this.sendRequest<CreateOrModifyAnnouncement.Response>(`/${id}`, "PUT", {
            body: data,
            qs: {
                rev,
            },
        });
    };

    public deleteAnnouncement = (id: string, rev: string) => {
        return this.sendRequest(`/${id}`, "DELETE", {
            qs: {
                rev,
            },
        });
    };
}
