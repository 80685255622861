/**
 * A list of location IDs that are part of an SCI pilot program for new features/products.
 */
export const pilotLocations: ReadonlyArray<string> = [
    "1068",
    "1109",
    "1143",
    "1144",
    "1434",
    "1457",
    "1543",
    "1545",
    "1895",
    "1900",
    "1959",
    "2214",
    "2216",
    "2290",
    "2309",
    "2312",
    "2326",
    "2342",
    "2378",
    "2440",
    "2465",
    "2548",
    "2623",
    "2679",
    "2699",
    "2705",
    "2737",
    "4007",
    "4053",
    "4128",
    "4129",
    "4130",
    "4200",
    "4222",
    "4224",
    "4228",
    "4310",
    "4319",
    "4325",
    "4326",
    "4338",
    "4344",
    "4345",
    "4346",
    "4347",
    "4365",
    "4366",
    "4386",
    "4388",
    "4391",
    "4392",
    "4394",
    "4396",
    "4397",
    "4405",
    "4406",
    "4416",
    "4417",
    "4465",
    "4466",
    "4467",
    "4468",
    "4474",
    "4476",
    "4477",
    "4478",
    "4481",
    "4588",
    "4590",
    "4627",
    "4630",
    "4670",
    "4681",
    "4698",
    "4705",
    "4815",
    "4816",
    "4817",
    "4818",
    "4819",
    "4821",
    "4823",
    "4832",
    "4854",
    "4855",
    "4925",
    "4927",
    "4931",
    "4976",
    "5248",
    "5492",
    "6890",
    "6891",
    "7008",
    "7080",
    "7081",
    "7082",
    "7083",
    "7084",
    "7089",
    "7090",
    "7092",
    "7093",
    "7094",
    "7095",
    "7097",
    "7105",
    "7108",
    "7110",
    "7220",
    "7221",
    "7245",
    "7253",
    "7526",
    "7534",
    "7538",
    "7901",
    "8069",
    "8092",
    "8167",
    "8205",
    "8221",
    "8376",
    "8389",
    "8390",
    "8398",
    "8401",
    "8527",
    "8548",
    "8586",
    "8683",
    "9716",
    "9737",
    "9738",
    "9739",
    "9741",
    "9746",
    "9753",
    "9754",
    "9755",
    "9756",
    "9758",
    "9759",
    "9761",
    "9768",
    "9769",
    "9770",
    "9773",
    "9774",
    "9794",
    "9802",
    "9804",
    "9825",
    "9826",
    "9827",
    "9829",
    "9830",
    "9831",
    "9832",
    "9834",
    "9854",
    "9856",
    // Dev locations
    "123456",
    "123123",
    "321321",
];

export function isPilotLocation(locationId: string): boolean {
    return pilotLocations.indexOf(locationId) >= 0;
}
