import * as React from "react";
import * as Frontend from "kmmp/frontend";
import * as V1 from "kmmp/orders/v1";
import * as V2 from "kmmp/orders/v2";
import { Auth, Dev } from "client/stores";
import { Paths } from "shared/paths";
import { MdWarning as Warning } from "react-icons/md";
import { Order } from "kmmp";
import { ImageEditor } from "client/components/image-editor";
import PageHeader from "client/components/page-header";
import { ApiError, Orders } from "client/http";
import { Sentry } from "client/modules/sentry";
import { CONSTANTS } from "client/constants";
import { Option } from "@nozzlegear/railway";
import { isLineItemVersion1 } from "shared/is-line-item";
import { useLocation } from "wouter";
import { useUnauthorizedPrompt, useEffectAsync, useImageUploader } from "client/hooks";

export interface Props {
    id: string;
}

export interface State {
    error: Option<string>;
    uploading: boolean;
    previousOrder: Option<Order>;
    failedToFindPreviousOrder: boolean;
    hideControls: boolean;
}

export function ResubmissionPage(props: Props): JSX.Element {
    const handleUnauthorized = useUnauthorizedPrompt();
    const [isUploadingImages, allImages, imageControls] = useImageUploader();
    const [_, setLocation] = useLocation();
    const [state, setFullState] = React.useState<State>({
        error: Option.ofNone(),
        previousOrder: Option.ofNone(),
        uploading: false,
        failedToFindPreviousOrder: false,
        hideControls: false,
    });
    const setState = (newState: Partial<State>) => setFullState({ ...state, ...newState });
    const orderIdDigits = parseInt(props.id.replace(/\/D/g, "")) || 0;
    const notFoundMessage = `Could not find an order with id KMMP-${orderIdDigits}.`;

    useEffectAsync(async () => {
        const api = new Orders(Auth.token.value);

        try {
            const order = await api.V1.getByDisplayId(orderIdDigits);

            setState({ previousOrder: Option.ofSome(order) });
        } catch (_e) {
            const e: ApiError = _e;

            if (e.unauthorized && handleUnauthorized(Paths.resub.index)) {
                return;
            }

            let error: string;

            if (e.status === 404) {
                error = notFoundMessage;
            } else {
                const sourceId = "f728ee7d-564a-4bd6-89a4-7be5fa41a1c3";
                error = `Encountered an error while trying to retrieve order KMMP-${orderIdDigits}. ${e.message} (id: ${sourceId})`;

                console.error(error, e);

                Sentry.captureException(e, {
                    extra: { sourceId },
                });
            }

            setState({ error: Option.ofSome(error), failedToFindPreviousOrder: true });
        }
    }, []);

    const hideControls = () =>
        setState({
            hideControls: true,
        });

    const showControls = () =>
        setState({
            hideControls: false,
        });

    const upload = async (e: React.SyntheticEvent) => {
        if (state.uploading) {
            return;
        }

        if (state.failedToFindPreviousOrder) {
            alert(notFoundMessage);

            return;
        }

        const order = Option.get(state.previousOrder);

        if (allImages.length === 0) {
            setState({ error: Option.ofSome("You must select an image first.") });

            return;
        }

        setState({ error: Option.ofNone(), uploading: true });

        let uploadedImages: Frontend.UploadedImage[];

        try {
            // Upload the images. The image hook will handle setting the isUploading state.
            uploadedImages = await imageControls.uploadImages();
        } catch (_e) {
            const e: ApiError = _e;
            const sourceId = "4eb13893-49b0-4f42-8199-da5b2165315f";

            if (e.unauthorized && handleUnauthorized(Paths.quick.index)) {
                return;
            }

            console.error(e);

            Sentry.captureException(e, {
                extra: {
                    sourceId: sourceId,
                },
            });

            setState({ error: Option.ofSome(`${e.message} (id: ${sourceId})`) });

            // Do not continue further if an error occurred
            return;
        }

        const api = new Orders(Auth.token.value);

        try {
            // TODO: If we ever enable multiple line items per order we'll need to actually map these images to the proper line item
            const lineItemId = order.line_items[0].uuid;
            const result = await api.V2.resubmitImages(orderIdDigits, [
                { line_item_id: lineItemId, images: uploadedImages },
            ]);

            setLocation(Paths.history.index);
        } catch (_e) {
            const e: ApiError = _e;

            if (e.unauthorized && handleUnauthorized(window.location.pathname)) {
                return;
            }

            setState({ error: Option.ofSome(e.message), uploading: false });
        }
    };

    const getImagePreview = (order: Order) => {
        const getThumbnailSrc = (image: V1.Image | V2.Image) => {
            return image.croppedImage ? image.croppedImage.thumbnailAzureUrl : image.fullImage.thumbnailAzureUrl;
        };
        const lineItem = order.line_items[0];

        if (isLineItemVersion1(lineItem)) {
            return getThumbnailSrc(lineItem.images[0]);
        }

        switch (lineItem.type) {
            case undefined:
            case "portrait":
            case "signature-portrait":
            case "shadow-box":
                return getThumbnailSrc(lineItem.images[0]);

            case "signature-jersey":
                console.error(
                    `Received unexpected line item type ${lineItem.type} when generating image preview.`,
                    lineItem
                );
                return "";

            default:
                const neverItem: never = lineItem;
                console.error("Unhandled line item type", neverItem);
                throw new Error("Unhandled line item type.");
        }
    };

    const controls = (
        <div key={"all-controls"}>
            <hr className="marBottom25" />
        </div>
    );

    const pageTitle = `Resubmit photo for KMMP-${props.id}`;

    return (
        <section id="cart">
            <PageHeader title={pageTitle} />
            <div className="section white">
                <div className="pure-g">
                    <div className="pure-u-1-1 pure-u-md-1-24" />
                    <div className="pure-u-1-1 pure-u-md-16-24">
                        <ImageEditor
                            allImages={[...allImages]}
                            isUploading={isUploadingImages}
                            controls={imageControls}
                            onRequestHideInteractions={hideControls}
                            onRequestShowInteractions={showControls}
                            orientation={"landscape"}
                        />
                        {state.error
                            .map<JSX.Element | null>((e) => <p className="error red text-center">{e}</p>)
                            .defaultValue(null)}
                        <div className="justify-space-between" style={{ marginTop: 10 }}>
                            <span />
                            <button type="button" className="btn blue" onClick={upload}>
                                {state.uploading ? "Resubmitting Photo" : "Resubmit Photo"}
                            </button>
                        </div>
                    </div>
                    <div className="pure-u-1-1 pure-u-md-2-24" />
                    <div className="pure-u-1-1 pure-u-md-4-24">
                        {state.previousOrder
                            .map((order) => (
                                <img
                                    alt="Your previous image."
                                    title="Your previous image."
                                    src={getImagePreview(order)}
                                    className="img-responsive de-emphasize"
                                />
                            ))
                            .defaultWith(() =>
                                state.failedToFindPreviousOrder ? (
                                    <div className="text-center">
                                        <Warning size={100} color={CONSTANTS.BLUE_GREY} />
                                        <p className="error red">{notFoundMessage}</p>
                                    </div>
                                ) : (
                                    <div className="text-center de-emphasize">
                                        <progress />
                                        <p>{`Loading KMMP-${props.id}, please wait.`}</p>
                                    </div>
                                )
                            )}
                    </div>
                </div>
            </div>
        </section>
    );
}
