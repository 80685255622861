import * as React from "react";

interface IProps extends React.Props<any> {
    value: string;
    onChange: (purchaseOrderId: string) => void;
}

export function PurchaseOrderIdBox(props: IProps): JSX.Element {
    const setPoId = (event: React.FormEvent<any>) => {
        const val = (event.target as any as HTMLSelectElement).value;

        // Replace any non-digit character
        props.onChange(val.replace(/\D/g, ""));
    };

    return (
        <div className="selector-container">
            <p>
                {`Purchase Order ID:`}
                <small className="pull-right" style={{ float: "right" }}>
                    Max 7 digits
                </small>
            </p>
            <input type="text" className="form-control selector" value={props.value} onChange={setPoId} maxLength={7} />
        </div>
    );
}
