"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var React = require("react");
function AddressLine(_a) {
    var address = _a.address, children = _a.children;
    var cityAndState = address.stateCode.map(function (stateCode) { return address.city + ", " + stateCode; }).defaultValue(address.city);
    return (React.createElement("div", { className: "address-line" },
        React.createElement("span", null, address.name),
        React.createElement("span", null, address.line1 + " " + address.line2),
        React.createElement("span", null, cityAndState + " " + address.zip.defaultValue("")),
        React.createElement("span", null, address.countryCode),
        children));
}
exports.AddressLine = AddressLine;
