import * as React from "react";
import { WhitelabelSettings } from "kmmp";
import { Paths } from "shared/paths";
import { Link } from "wouter";
import { useWhitelabel } from "client/hooks";

type Props = React.PropsWithChildren<{
    whitelabelSettings: WhitelabelSettings;
}>;

export function MinimalLayout(props: Props): JSX.Element {
    const whitelabel = useWhitelabel();
    const logoUrl = whitelabel.logoUrl || "/public/images/kmlogonamewide.png";

    return (
        <main id="app" className="minimal">
            <div id="body">
                <div className="page-header">
                    <Link to={Paths.home.index}>
                        <img src={logoUrl} alt={whitelabel.siteName} />
                    </Link>
                </div>
                {props.children}
            </div>
        </main>
    );
}
