import { BaseClass } from "client/http/base";
import { ListAdminOrders, ListAdminUsers, CreateOrModifyUser } from "api";

export class AdminClient extends BaseClass {
    constructor(authToken: string) {
        super("/api/v1/admin", authToken);
    }

    public listOrders = (options: ListAdminOrders.Request) => {
        const query = new URLSearchParams();
        query.append("page", options.page.toString());

        if (options.limit) {
            query.append("limit", options.limit.toString());
        }

        return this.sendRequest<ListAdminOrders.Response>("/orders?" + query.toString(), "GET");
    };

    public listUsers = (options: ListAdminUsers.Request) => {
        const query = new URLSearchParams();
        query.append("page", options.page.toString());

        if (options.limit) {
            query.append("limit", options.limit.toString());
        }

        return this.sendRequest<ListAdminUsers.Response>("/users?" + query.toString(), "GET");
    };

    public createUser = (data: CreateOrModifyUser.Request) => {
        return this.sendRequest<CreateOrModifyUser.Response>("/users", "POST", {
            body: data,
        });
    };

    public modifyUser = (id: string, rev: string, data: CreateOrModifyUser.Request) => {
        return this.sendRequest<CreateOrModifyUser.Response>(`/users/${id}`, "PUT", {
            body: data,
            qs: {
                rev,
            },
        });
    };
}
