import * as React from "react";

export interface Props extends React.Props<any> {
    totalImages: number;
    totalUploaded: number;
}

export function UploadInProgressForm({ totalImages, totalUploaded }: Props): JSX.Element {
    const progress = (totalUploaded / totalImages) * 100;

    return (
        <div className="uploading">
            <div>
                <p>
                    {`Uploading ${
                        totalUploaded === totalImages ? totalUploaded : totalUploaded + 1
                    } of ${totalImages} images.`}
                </p>
                <div style={{ textAlign: "center", padding: "50px" }}>
                    <progress />
                </div>
                <div className="progress">
                    <div
                        className="progress-bar progress-bar-striped active"
                        role="progressbar"
                        style={{
                            width: totalUploaded === 0 ? "10%" : `${progress <= 100 ? progress : 100}%`,
                        }}
                    />
                </div>
                <p>
                    {
                        "Uploading your images can sometimes take several minutes or more, depending on your internet connection speed and the size or quality of each image."
                    }
                </p>
            </div>
        </div>
    );
}
