import * as React from "react";
import { If } from "./if";

interface Props {
    error: string | null | undefined;
}

export function MaybeError({ error }: Props): JSX.Element {
    return (
        <If condition={typeof error === "string"}>
            <p className="red error">{error}</p>
        </If>
    );
}
