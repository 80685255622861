import { action, computed, observable } from "mobx";
import { CONSTANTS } from "client/constants";
import jwtDecode from "jwt-decode";
import { Sentry } from "client/modules/sentry";
import { SessionToken, WhitelabelSettings } from "kmmp";
import { isPilotLocation } from "shared/pilot_locations";

class AuthStoreFactory {
    constructor() {
        const token = localStorage.getItem(CONSTANTS.AUTH_STORAGE_NAME) || "";

        if (token) {
            this.login(token);
        }
    }

    setUserContext(user: SessionToken) {
        Sentry.setUser({
            id: user._id,
            username: user.location_id,
        });
    }

    clearUserContext() {
        Sentry.setUser(null);
    }

    token: { value: string } = observable({
        value: "",
    });

    @observable
    session: SessionToken | null = null;

    @computed
    get sessionIsInvalid() {
        return !this.token || !this.session || this.session.exp * 1000 < Date.now();
    }

    @computed
    get requireCoupaPurchaseOrder() {
        return this.companySettings.companyName === "sci";
    }

    @computed
    get canPurchasePilotProducts(): boolean {
        const session = this.session;

        if (this.sessionIsInvalid || !session) {
            return false;
        }

        return session.company === "sci" && isPilotLocation(session.location_id);
    }

    @computed isAdmin(): boolean {
        const session = this.session;

        if (this.sessionIsInvalid || !session) {
            return false;
        }

        return session.is_admin === true;
    }

    @action
    login(token: string) {
        const session = jwtDecode(token);

        if (!session) {
            console.error("Unable to decode jwt token", { token, decoded: session });
            throw new Error("Unable to decode jwt token");
        }

        this.session = session as any;
        this.token.value = token;

        this.setUserContext(session as any);
        // Persist the auth changes to localstorage
        localStorage.setItem(CONSTANTS.AUTH_STORAGE_NAME, this.token.value);
    }

    @action
    logout() {
        this.session = {} as any;
        this.token.value = "";
        this.clearUserContext();
        // Persist the auth changes to localstorage
        localStorage.removeItem(CONSTANTS.AUTH_STORAGE_NAME);
    }

    // This is injected into the dom by the server, and is based off of the host header for each request
    readonly companySettings: WhitelabelSettings = JSON.parse(document.getElementById("client-config")!.innerHTML);
}

export const Auth = new AuthStoreFactory();
window["AuthStore"] = Auth;
