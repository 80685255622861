import * as React from "react";
import * as Frontend from "kmmp/frontend";
import { useState } from "./use-state";
import { BlobUtility } from "client/modules/blobs";

interface State {
    isUploading: boolean;
    allImages: ReadonlyArray<Frontend.PreviewImage>;
}

export interface ImageUploadControls {
    addImage: (image: Frontend.PreviewImage) => void;
    removeImage: (uuid: string) => void;
    reset: () => void;
    setCroppedData: (uuid: string, blob: Blob) => void;
    revertCrop: (uuid: string) => void;
    setNotes: (uuid: string, notes: string) => void;
    setImageUploadStatus: (uuid: string, newStatus: boolean) => void;
    acceptImageSizeWarning: (uuid: string) => void;
    uploadImages: () => Promise<Frontend.UploadedImage[]>;
}

export type ReturnType = [
    isUploading: boolean,
    allImages: ReadonlyArray<Frontend.PreviewImage>,
    controls: ImageUploadControls
];

/**
 * A React hook which manages the entire image uploading process.
 */
export function useImageUploader(): ReturnType {
    const blobUtility = React.useMemo(() => new BlobUtility(), []);
    const [state, setState] = useState<State>({
        isUploading: false,
        allImages: [],
    });

    const reset = () =>
        setState({
            isUploading: false,
            allImages: [],
        });

    const addImage = (image: Frontend.PreviewImage) =>
        setState({
            allImages: [...state.allImages, image],
        });

    const removeImage = (uuid: string) =>
        setState({
            allImages: state.allImages.filter((i) => i.uuid !== uuid),
        });

    // Clones the allImages state and modifies an image, returning the updated image array.
    const modifyImage = (uuid: string, fn: (image: Frontend.PreviewImage) => Frontend.PreviewImage) => {
        const allImages = [...state.allImages];
        const imageIndex = allImages.findIndex((i) => i.uuid === uuid);
        const original = allImages[imageIndex];

        if (!original) {
            console.error(`Attempted to modify an image with uuid ${uuid} but it was not found in the images array.`, {
                uuid,
                images: allImages,
            });
            return allImages;
        }

        const updatedImage = fn(original);

        if (!updatedImage) {
            console.error(
                `Attempted to modify an image with uuid ${uuid}, but the update function did not return an image.`,
                { uuid, images: allImages, original }
            );
            return allImages;
        }

        // Replace the original image
        allImages.splice(imageIndex, 1, updatedImage);

        return allImages;
    };

    const releaseCroppedBlob = (croppedData: Frontend.PreviewImageBlob) => {
        URL.revokeObjectURL(croppedData.dataUrl);
    };

    const setCroppedData = (uuid: string, blob: Blob) => {
        const updatedImages = modifyImage(uuid, (original) => {
            if (original.croppedImage) {
                releaseCroppedBlob(original.croppedImage);
            }

            return {
                ...original,
                croppedImage: {
                    blob: blob,
                    dataUrl: URL.createObjectURL(blob),
                    dimensions: null,
                },
            };
        });

        setState({
            allImages: updatedImages,
        });
    };

    const revertCrop = (uuid: string) => {
        const updatedImages = modifyImage(uuid, (original) => {
            if (original.croppedImage) {
                releaseCroppedBlob(original.croppedImage);
            }

            return {
                ...original,
                croppedImage: null,
            };
        });

        setState({
            allImages: updatedImages,
        });
    };

    const setNotes = (uuid: string, notes: string) => {
        const updatedImages = modifyImage(uuid, (original) => {
            return {
                ...original,
                instructions: notes,
            };
        });

        setState({
            allImages: updatedImages,
        });
    };

    const setImageUploadStatus = (uuid: string, newStatus: boolean) => {
        const updatedImages = modifyImage(uuid, (original) => {
            return {
                ...original,
                uploaded: newStatus,
            };
        });

        setState({
            allImages: updatedImages,
        });
    };

    const acceptImageSizeWarning = (uuid: string) => {
        const updatedImages = modifyImage(uuid, (original) => {
            return {
                ...original,
                sizeDetails: {
                    ...original.sizeDetails!,
                    acceptedImageSizeWarning: true,
                },
            };
        });

        setState({
            allImages: updatedImages,
        });
    };

    const uploadImages: () => Promise<Frontend.UploadedImage[]> = async () => {
        if (state.isUploading) {
            throw new Error("Image upload is already in progress.");
        }

        if (state.allImages.length === 0) {
            throw new Error("Images error is empty, no images to upload.");
        }

        setState({
            isUploading: true,
        });

        try {
            const allImages = [...state.allImages];
            const uploadedImages = await blobUtility.uploadToAzure(allImages, (uploaded) => {
                // An image finished uploading, set its uploaded status to true
                setImageUploadStatus(uploaded.uuid, true);
            });

            if (uploadedImages.length === 0) {
                throw new Error(
                    `Attempted to upload images, but server returned a 0-length array. Please try to upload your images again.`
                );
            }

            return uploadedImages;
        } finally {
            setState({
                isUploading: false,
            });
        }
    };

    return [
        state.isUploading,
        state.allImages,
        {
            acceptImageSizeWarning,
            addImage,
            removeImage,
            reset,
            revertCrop,
            setCroppedData,
            setImageUploadStatus,
            setNotes,
            uploadImages,
        },
    ];
}
