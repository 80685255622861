import { WhitelabelSettings } from "kmmp";
import { getBackgrounds, getFrames } from "shared/images";

const humanBackgrounds = getBackgrounds("messenger", "human");
const frames = getFrames("messenger");

export const churchAndChapel: WhitelabelSettings = {
    companyName: "church-and-chapel",
    siteName: "Church & Chapel",
    subjectType: "human",
    domain: "cc-mp.com",
    siteType: "corporate",
    locationIdPrefix: "cc",
    logoUrl: "/public/whitelabel/ccma-logo.png",
    products: [
        {
            _id: "cp1",
            _rev: "v1",
            name: "16x20 Canvas Portrait",
            description: "",
            imageUrl: "/public/fancy-frames/messenger/16x20/black-asis.png",
            // New orders are $67.00 for the first package, then $62.00 for the next packages.
            price: 67,
            priceBreaks: [
                {
                    // The first package must remain 67.00
                    applyBelowMinQuantity: false,
                    // Only apply the discount when there's two or more of the product
                    minQuantity: 2,
                    // Apply the discount to each quantity
                    applyTo: "individual",
                    // $5.00 discount
                    modifier: -5.0,
                    modifierType: "absolute",
                },
            ],
            backgroundInfo: {
                hasBackgrounds: true,
                backgrounds: humanBackgrounds,
            },
            frameInfo: {
                hasFrames: true,
                frames: frames,
            },
            cardInfo: {
                hasCards: true,
                amountPerItem: 5,
            },
        },
        {
            _id: "cp2",
            _rev: "v1",
            name: "11x14 Canvas Portrait",
            description: "",
            imageUrl: "/public/fancy-frames/messenger/11x14/black-asis.png",
            // New orders are $67.00 for the first package, then $62.00 for the next packages.
            price: 67,
            priceBreaks: [
                {
                    // The first package must remain 67.00
                    applyBelowMinQuantity: false,
                    // Only apply the discount when there's two or more of the product
                    minQuantity: 2,
                    // Apply the discount to each quantity
                    applyTo: "individual",
                    // $5.00 discount
                    modifier: -5.0,
                    modifierType: "absolute",
                },
            ],
            backgroundInfo: {
                hasBackgrounds: true,
                backgrounds: humanBackgrounds,
            },
            frameInfo: {
                hasFrames: true,
                frames: frames,
            },
            cardInfo: {
                hasCards: true,
                amountPerItem: 5,
            },
        },
        {
            _id: "cp3",
            _rev: "v1",
            name: "8x10 Canvas Portrait",
            description: "",
            imageUrl: "/public/fancy-frames/messenger/8x10/black-asis.png",
            // New orders are $67.00 for the first package, then $62.00 for the next packages.
            price: 67,
            priceBreaks: [
                {
                    // The first package must remain 67.00
                    applyBelowMinQuantity: false,
                    // Only apply the discount when there's two or more of the product
                    minQuantity: 2,
                    // Apply the discount to each quantity
                    applyTo: "individual",
                    // $5.00 discount
                    modifier: -5.0,
                    modifierType: "absolute",
                },
            ],
            backgroundInfo: {
                hasBackgrounds: true,
                backgrounds: humanBackgrounds,
            },
            frameInfo: {
                hasFrames: true,
                frames: frames,
            },
            cardInfo: {
                hasCards: true,
                amountPerItem: 5,
            },
        },
    ],
};
