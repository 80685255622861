import * as React from "react";
import * as Images from "shared/images";
import * as Frontend from "kmmp/frontend";
import { Orientation, WhitelabelSettings } from "kmmp";
import { AsyncResult } from "@nozzlegear/railway";

export interface Props extends React.Props<any> {
    background: Frontend.BackgroundImage;
    frame: Frontend.FrameImage;
    size: Frontend.Size;
    orientation: Orientation;
    whitelabelSettings: WhitelabelSettings;
    displayAsPackage: boolean;
    isSignaturePortrait: boolean;
}

function loadImage(src: string, width: number, height: number): AsyncResult<HTMLImageElement> {
    const prom = new Promise<HTMLImageElement>((res, rej) => {
        const img = new Image(width, height);
        img.onload = (_) => res(img);
        img.onerror = (err) => rej(err);
        img.src = src;
    });

    return AsyncResult.wrap(prom);
}

export function PortraitPreview(props: Props) {
    const {
        background,
        frame,
        size,
        orientation,
        displayAsPackage,
        isSignaturePortrait,
        whitelabelSettings: { companyName, subjectType },
    } = props;

    if (displayAsPackage) {
        const src = isSignaturePortrait
            ? Images.findSignaturePortraitFancyFrame(frame, background, orientation)
            : Images.findFancyFrame(companyName, subjectType, frame, background, orientation);

        return <img className="img-responsive" style={{ objectFit: "contain" }} src={src} alt={"Package preview"} />;
    }

    const canvasWidth = 523;
    const canvasHeight = 443;

    const loadCanvas = async (ref: HTMLCanvasElement | null) => {
        if (!ref) return;

        const ctx = ref.getContext("2d");
        const bgImage = loadImage(
            Images.getBackgroundUrl(companyName, subjectType, background, orientation, size),
            canvasWidth,
            canvasHeight
        );
        const frameImage = loadImage(
            Images.getFrameUrl(companyName, subjectType, frame, orientation, size),
            canvasWidth,
            canvasHeight
        );

        // Clear whatever image may be written on the canvas already
        ctx!.clearRect(0, 0, ref.width, ref.height);

        // The frame must be drawn after the background, or else the background will appear above the frame.
        // Load them both at the same time, but only draw after both are finished loading.
        await bgImage
            .bind((bg) => frameImage.map((frame) => ({ bg: bg, frame: frame })))
            .iter(({ bg, frame }) => {
                const x = 0;
                const y = 0;
                const bgRatio = calculateAspectRatioFit(bg.width, bg.height, ref.width, ref.height);
                const frameRatio = calculateAspectRatioFit(frame.width, frame.height, ref.width, ref.height);

                ctx!.drawImage(bg, x, y, bgRatio.width, bgRatio.height);
                ctx!.drawImage(frame, x, y, frameRatio.width, frameRatio.height);
            })
            .iterError((err) => {
                console.error("Failed to load preview image:", { error: err });
            })
            .get();
    };

    // One confusing thing about canvas is that anything drawn in it is scaled to the canvas.height and canvas.width properties, **NOT** the
    // CSS width/height or even the display width/height in the browser. If the canvas.width and canvas.height props aren't set, the canvas
    // will be set to its default size of 300x150, and everything inside will be drawn with those maximum dimensions in mind. Setting the
    // CSS properties will only stretch/scale what's drawn inside.
    //
    // In practice, this means that a non-css width and height MUST be set, but css can still be used to make the canvas element itself
    // responsive as long as everything inside is drawn with the correct proportions (again using that non-css width and height).
    return <canvas ref={loadCanvas} width={canvasWidth} height={canvasHeight} />;
}

/**
 * Calculates the aspect ratio of an Image object.
 * @author http://newapputil.blogspot.com/2016/09/show-image-on-canvas-html5.html
 */
function calculateAspectRatioFit(imageWidth: number, imageHeight: number, canvasWidth: number, canvasHeight: number) {
    const ratio = Math.min(canvasWidth / imageWidth, canvasHeight / imageHeight);
    const rtnWidth = imageWidth * ratio;
    const rtnHeight = imageHeight * ratio;

    return {
        width: rtnWidth,
        height: rtnHeight,
    };
}
