import { BaseClass } from "client/http/base";

export class AuthHttpClient extends BaseClass {
    constructor() {
        super("/api/v1/sessions", undefined);
    }

    public login(data: { username: string; password: string }) {
        return this.sendRequest<{ token: string }>("", "POST", { body: data });
    }
}
