import * as React from "react";
import * as dates from "shared/dates";

interface Props {
    name: string;
    start: Date | null;
    expiration: Date | null;
    displayDate: string | null;
    partingPhrase: string;
    message: string;

    onNameChange: (newValue: string) => void;
    onStartChange: (newValue: Date | null) => void;
    onExpirationChange: (newValue: Date | null) => void;
    onDisplayDateChange: (newValue: string) => void;
    onPartingPhraseChange: (newValue: string) => void;
    onMessageChange: (newValue: string) => void;
}

type ChangeType = "name" | "start" | "expiration" | "displayDate" | "partingPhrase" | "message";

export function HolidayControls(props: Props): JSX.Element {
    const change = (type: ChangeType) => (e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        switch (type) {
            case "name":
                props.onNameChange(e.currentTarget.value);
                break;

            case "start":
                const start = (e.currentTarget as HTMLInputElement).valueAsDate;
                props.onStartChange(start);
                break;

            case "expiration":
                const exp = (e.currentTarget as HTMLInputElement).valueAsDate;
                props.onExpirationChange(exp);
                break;

            case "displayDate":
                props.onDisplayDateChange(e.currentTarget.value);
                break;

            case "partingPhrase":
                props.onPartingPhraseChange(e.currentTarget.value);
                break;

            case "message":
                props.onMessageChange(e.currentTarget.value);
                break;

            default:
                const neverType: never = type;
                throw new Error("Unhandled type " + neverType);
        }
    };

    return (
        <React.Fragment>
            <div className="form-group">
                <label>{"Holiday name"}</label>
                <input type="text" className="form-control" onChange={change("name")} value={props.name} />
            </div>
            <div className="form-group">
                <label>{"Start date"}</label>
                <input
                    type="date"
                    className="form-control"
                    onChange={change("start")}
                    value={props.start ? dates.toISODateString(props.start) : ""}
                />
            </div>
            <div className="form-group">
                <label>{"Display date"}</label>
                <input
                    type="text"
                    className="form-control"
                    onChange={change("displayDate")}
                    value={props.displayDate ?? ""}
                    placeholder="June 25th and 26th"
                />
            </div>
            <div className="form-group">
                <label>{"Expiration Date"}</label>
                <input
                    type="date"
                    className="form-control"
                    onChange={change("expiration")}
                    value={props.expiration ? dates.toISODateString(props.expiration) : ""}
                />
            </div>
            <div className="form-group">
                <label>{"Message"}</label>
                <textarea rows={10} className="form-control" onChange={change("message")} value={props.message} />
            </div>
            <div className="form-group">
                <label>{"Parting phrase"}</label>
                <input
                    type="text"
                    className="form-control"
                    onChange={change("partingPhrase")}
                    value={props.partingPhrase ?? ""}
                    placeholder="Happy Example Day!"
                />
            </div>
        </React.Fragment>
    );
}
