import * as React from "react";
import * as PriceCalculator from "shared/price_calculator";
import * as Frontend from "kmmp/frontend";
import Paths from "shared/paths";
import { useLocation } from "wouter";
import { Cart, Auth, CartStore } from "client/stores";
import { Link } from "wouter";
import { Observer } from "mobx-react";
import PageHeader from "client/components/page-header";
import { PortraitPreview } from "client/components/portrait_preview";
import { getShadowBoxImage, getSignatureJerseyImage } from "shared/images";
import { getLineItemDescription } from "shared/get-line-item-description";

type Props = {};

export function CartSummaryPage(_: Props): JSX.Element {
    const [location, setLocation] = useLocation();

    React.useEffect(() => {
        if (Auth.companySettings.siteType === "corporate") {
            setLocation(Paths.cart.name, { replace: true });
        }
    }, [Auth.companySettings.siteType]);

    if (Auth.companySettings.siteType === "corporate") {
        // Somehow people are still rendering this cart summary component despite being redirected away from it!
        return <div />;
    }

    return <EcommerceSummary store={Cart} />;
}

const findImageSrc = (li: Frontend.LineItem) => {
    switch (li.type) {
        case undefined:
        case "portrait":
        case "signature-portrait":
            const i = li.images[0];
            return i.croppedImage ? i.croppedImage.thumbnailUrl : i.fullImage.thumbnailUrl;

        case "shadow-box":
            return getShadowBoxImage(li.customization.color);

        case "signature-jersey":
            const packageType = li.customization.package;
            const { frame } = li.customization;
            return getSignatureJerseyImage(packageType, frame);

        default:
            const neverItem: never = li;
            console.error("Unhandled line item type", neverItem);
            throw new Error("Unhandled line item type.");
    }
};

const getSubtotal = (li: Frontend.LineItem[]) =>
    li.reduce((value, li) => PriceCalculator.calculate(li.qty.packages, Auth.companySettings.products[0]) + value, 0);

const updateQuantity = (store: CartStore, li: Frontend.LineItem) => (event: React.FormEvent<HTMLInputElement>) =>
    store.setQuantity(li, "packages", event.currentTarget.valueAsNumber < 1 ? 1 : event.currentTarget.valueAsNumber);

interface EcommerceProps extends React.Props<any> {
    store: CartStore;
}

const EcommerceSummary = ({ store }: EcommerceProps) => (
    <Observer>
        {() => (
            <div>
                <PageHeader title="Your Shopping Cart" />
                <section id="cart-summary" className="section white">
                    <div className="cart-table">
                        <div className="pure-g table-header">
                            <div className="pure-u-md-12-24 details ">{"Product"}</div>
                            <div className="pure-u-md-4-24 price">{"Price"}</div>
                            <div className="pure-u-md-4-24 quantity">{"Quantity"}</div>
                            <div className="pure-u-md-4-24 total">{"Total"}</div>
                        </div>
                        {store.items.map((li) => (
                            <div key={li.uuid} className="pure-g table-row">
                                <LineItemDetails item={li} onRemoveFromCart={store.removeItem} />
                                <div className="pure-u-md-4-24 price">
                                    {"$" + Auth.companySettings.products[0].price.toFixed(2)}
                                </div>
                                <div className="pure-u-md-4-24 quantity">
                                    <input
                                        type={"number"}
                                        value={li.qty.packages}
                                        onChange={updateQuantity(store, li)}
                                    />
                                </div>
                                <div className="pure-u-md-4-24 total">
                                    {"$" +
                                        PriceCalculator.calculate(
                                            li.qty.packages,
                                            Auth.companySettings.products[0]
                                        ).toFixed(2)}
                                </div>
                            </div>
                        ))}
                        {store.items.length > 0 ? null : (
                            <div className="table-row text-center">{"Your cart is empty!"}</div>
                        )}
                    </div>
                    <div className="pricing-info">
                        <div className="pure-g">
                            <div className="pure-u-md-16-24 spacer" />
                            <div className="pure-u-md-4-24 subtotal-key">{"Subtotal:"}</div>
                            <div className="pure-u-md-4-24 subtotal-value">
                                {`$${getSubtotal(store.items.slice()).toFixed(2)}`}
                            </div>
                        </div>
                        <span className="subtotal-info">{"Shipping and taxes will be calculated at checkout."}</span>
                    </div>
                    <div className="buttons">
                        <Link className="btn" to={Paths.products.index}>
                            {"Continue Shopping"}
                        </Link>
                        <Link className="btn blue" to={Paths.checkout.index}>
                            {"Check Out"}
                        </Link>
                    </div>
                </section>
            </div>
        )}
    </Observer>
);

interface LineItemDetailsProps extends React.Props<any> {
    item: Frontend.LineItem;
    onRemoveFromCart: (item: Frontend.LineItem) => void;
}

function LineItemDetails(props: LineItemDetailsProps): JSX.Element {
    const item = props.item;
    const imageSrc = findImageSrc(item);
    const onRemove = (e: React.MouseEvent<any>) => {
        e.preventDefault();
        props.onRemoveFromCart(item);
    };

    switch (item.type) {
        case undefined:
        case "portrait":
            return (
                <div className="pure-u-md-12-24 details">
                    <img src={imageSrc} alt={item.deceasedName} className="img-responsive" />
                    <PortraitPreview
                        whitelabelSettings={Auth.companySettings}
                        background={item.customization.background}
                        frame={item.customization.frame}
                        orientation={item.customization.orientation}
                        size={"16x20"}
                        isSignaturePortrait={false}
                        displayAsPackage={
                            Auth.companySettings.companyName === "sci" ||
                            Auth.companySettings.companyName === "messenger"
                        }
                    />
                    <div>
                        <div>{getLineItemDescription(item)}</div>
                        {item.deceasedName ? <div>{item.deceasedName}</div> : null}
                        <a href="#" className="remove-button" onClick={onRemove}>
                            {"Remove"}
                        </a>
                    </div>
                </div>
            );

        case "signature-portrait":
            return (
                <div className="pure-u-md-12-24 details">
                    <img src={imageSrc} alt={item.deceasedName} className="img-responsive" />
                    <div>
                        <div>{getLineItemDescription(item)}</div>
                        {item.deceasedName ? <div>{item.deceasedName}</div> : null}
                        <a href="#" className="remove-button" onClick={onRemove}>
                            {"Remove"}
                        </a>
                    </div>
                </div>
            );

        case "shadow-box":
            return (
                <div className="pure-u-md-12-24 details">
                    <img src={imageSrc} alt={item.deceasedName} className="img-responsive" />
                    <div>
                        <div>{getLineItemDescription(item)}</div>
                        {item.deceasedName ? <div>{item.deceasedName}</div> : null}
                        <a href="#" className="remove-button" onClick={onRemove}>
                            {"Remove"}
                        </a>
                    </div>
                </div>
            );

        case "signature-jersey":
            return (
                <div className="pure-u-md-12-24 details">
                    <img src={imageSrc} alt={item.deceasedName} className="img-responsive" />
                    <div>
                        <div>{getLineItemDescription(item)}</div>
                        {item.deceasedName ? <div>{item.deceasedName}</div> : null}
                        <a href="#" className="remove-button" onClick={onRemove}>
                            {"Remove"}
                        </a>
                    </div>
                </div>
            );

        default:
            const neverItem: never = item;
            console.error("Unhandled line item type", neverItem);
            throw new Error("Unhandled line item type.");
    }
}
