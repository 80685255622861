import ga = require("ga");
import * as React from "react";
import Box from "../../components/box";
import { CartMain as Container } from "./main";
import { Paths } from "shared/paths";
import { useLocation } from "wouter";
import { useState } from "client/hooks";
import { Cart, Dev } from "../../stores";
import { Link } from "wouter";
import { MdArrowRight as RightArrow } from "react-icons/md";
import { Option } from "@nozzlegear/railway";

type Props = {};

interface State {
    error: Option<string>;
    name: string;
}

export function CartNamePage(_: Props): JSX.Element {
    const [location, setLocation] = useLocation();
    const [state, setState] = useState<State>({
        error: Option.ofNone(),
        name: Cart.decedent_name || "",
    });

    React.useEffect(() => {
        ga("send", "event", {
            eventCategory: "Checkout",
            eventAction: "started",
        });
    }, []);

    const handleContinue = (e: React.MouseEvent<any> | React.FormEvent<any>) => {
        e.preventDefault();

        if (!state.name) {
            setState({ error: Option.ofSome("You must enter the name of the decedent.") });

            return;
        }

        Cart.setName(state.name);
        setLocation(Paths.cart.shipping);
    };

    const setName = (e: React.ChangeEvent<HTMLInputElement>) => setState({ name: e.currentTarget.value });

    const { error, name } = state;
    const description = `Your order is ready for checkout. Enter the name of the deceased below, and remember that all items in an order must be for the same decedent.`;
    const footer = (
        <div className="align-children justify-space-between">
            {Dev.allow_cart ? (
                <Link to={Paths.cart.index} className="btn">
                    Back
                </Link>
            ) : (
                <span />
            )}
            <button type="button" className="btn blue" onClick={(e) => handleContinue(e)}>
                {"Continue"}
                <RightArrow color={`#fff`} size={18} className={`right`} />
            </button>
        </div>
    );

    return (
        <Container>
            <Box title="Name of Deceased" description={description} error={error.defaultValue("")} footer={footer}>
                <div className="control-group">
                    <label>Name of Deceased</label>
                    <input type="text" autoComplete="off" value={name || ""} onChange={setName} />
                </div>
            </Box>
        </Container>
    );
}
